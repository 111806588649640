import * as generatedApi from './generatedApi';

import axiosInstance, { cancelToken } from './http';

import { appConfigs } from 'shared/constants/configs';

const apiInfoClient = new generatedApi.ApiInfoClient(appConfigs.apiUrl, axiosInstance);

const exampleClient = new generatedApi.ExampleClient(appConfigs.apiUrl, axiosInstance);

const applicationEEODataSheetsClient = new generatedApi.ApplicationEEODataSheetsClient(
  appConfigs.apiUrl,
  axiosInstance
);

const applicationReferralSourcesClient = new generatedApi.ApplicationReferralSourcesClient(
  appConfigs.apiUrl,
  axiosInstance
);

const employeeInjuryNoticeClient = new generatedApi.EmployeeInjuryNoticeClient(appConfigs.apiUrl, axiosInstance);
const accidentAnalysisClient = new generatedApi.AccidentAnalysisClient(appConfigs.apiUrl, axiosInstance);
const accidentAttachmentsClient = new generatedApi.AccidentAttachmentsClient(appConfigs.apiUrl, axiosInstance);

const applicationEthnicCodesClient = new generatedApi.ApplicationEthnicCodesClient(appConfigs.apiUrl, axiosInstance);

const incidentReportClient = new generatedApi.IncidentReportClient(appConfigs.apiUrl, axiosInstance);
const incidentLocationClient = new generatedApi.IncidentLocationsClient(appConfigs.apiUrl, axiosInstance);
const incidentPicturesClient = new generatedApi.IncidentPicturesClient(appConfigs.apiUrl, axiosInstance);

const activityLogClient = new generatedApi.ActivityLogsClient(appConfigs.apiUrl, axiosInstance);

const employeeClient = new generatedApi.EmployeesClient(appConfigs.apiUrl, axiosInstance);

const activityCategoryClient = new generatedApi.ActivityLogCategoriesClient(appConfigs.apiUrl, axiosInstance);
const applicationFlowLogClient = new generatedApi.ApplicationFlowLogClient(appConfigs.apiUrl, axiosInstance);
const careClient = new generatedApi.CareClient(appConfigs.apiUrl, axiosInstance);
const storeClient = new generatedApi.StoresClient(appConfigs.apiUrl, axiosInstance);
const cashCountClient = new generatedApi.CashCountsClient(appConfigs.apiUrl, axiosInstance);
const cashVarianceClient = new generatedApi.CashierVariancesClient(appConfigs.apiUrl, axiosInstance);
const locationClient = new generatedApi.LocationClient(appConfigs.apiUrl, axiosInstance);
const jobListClient = new generatedApi.JobListClient(appConfigs.apiUrl, axiosInstance);
const reserveFundLogClient = new generatedApi.ReserveFundLogClient(appConfigs.apiUrl, axiosInstance);
const depositRecordClient = new generatedApi.DepositRecordsClient(appConfigs.apiUrl, axiosInstance);
const communicationLogClient = new generatedApi.CommunicationLogsClient(appConfigs.apiUrl, axiosInstance);
const storeMessageClient = new generatedApi.StoreMessageClient(appConfigs.apiUrl, axiosInstance);
const donorCountClient = new generatedApi.DonorCountDailyClient(appConfigs.apiUrl, axiosInstance);
const siteClient = new generatedApi.SitesClient(appConfigs.apiUrl, axiosInstance);
const bodyPartsClient = new generatedApi.BodyPartsClient(appConfigs.apiUrl, axiosInstance);
const lossControlReviewsClient = new generatedApi.LossControlReviewsClient(appConfigs.apiUrl, axiosInstance);
const safetyAuditReportClient = new generatedApi.SafetyAuditReportClient(appConfigs.apiUrl, axiosInstance);
const dmAuditClient = new generatedApi.DMAuditClient(appConfigs.apiUrl, axiosInstance);
const kaizenSuggestionClient = new generatedApi.KaizenSuggestionClient(appConfigs.apiUrl, axiosInstance);
const donationBudgetClient = new generatedApi.DonationBudgetsClient(appConfigs.apiUrl, axiosInstance);
const eslReferralSourceClient = new generatedApi.ESLReferralSourceClient(appConfigs.apiUrl, axiosInstance);
const eslGoalsClient = new generatedApi.ESLGoalsClient(appConfigs.apiUrl, axiosInstance);
const eslInstructorsClient = new generatedApi.ESLInstructorsClient(appConfigs.apiUrl, axiosInstance);
const trainingSummaryClient = new generatedApi.TrainingSummaryClient(appConfigs.apiUrl, axiosInstance);
const tblClassNameClient = new generatedApi.TblClassNameClient(appConfigs.apiUrl, axiosInstance);
const eceAncillaryServicesClient = new generatedApi.ECEAncillaryServicesClient(appConfigs.apiUrl, axiosInstance);
const traniningClient = new generatedApi.TrainingSummaryClient(appConfigs.apiUrl, axiosInstance);
const teacherClient = new generatedApi.TeacherClient(appConfigs.apiUrl, axiosInstance);
const eslClassTypeClient = new generatedApi.ESLClassTypeClient(appConfigs.apiUrl, axiosInstance);
const eslParticipantClient = new generatedApi.ESLParticipantClient(appConfigs.apiUrl, axiosInstance);
const eslClassClient = new generatedApi.ESLClassClient(appConfigs.apiUrl, axiosInstance);
const staffClient = new generatedApi.StaffClient(appConfigs.apiUrl, axiosInstance);
const caseNoteClient = new generatedApi.CaseManagementClient(appConfigs.apiUrl, axiosInstance);
const certificationClient = new generatedApi.JccertificationClient(appConfigs.apiUrl, axiosInstance);
const educationClient = new generatedApi.JcdegreeClient(appConfigs.apiUrl, axiosInstance);
const jcentityLocationClient = new generatedApi.JcentityLocationClient(appConfigs.apiUrl, axiosInstance);
const jcentityContactClient = new generatedApi.JcentityContactClient(appConfigs.apiUrl, axiosInstance);
const jccontactLogClient = new generatedApi.JccontactLogClient(appConfigs.apiUrl, axiosInstance);
const jcentityInviteClient = new generatedApi.JCEntityEventDetailsClient(appConfigs.apiUrl, axiosInstance);
const disabilsClient = new generatedApi.DisabilsClient(appConfigs.apiUrl, axiosInstance);
const idsClient = new generatedApi.IDsClient(appConfigs.apiUrl, axiosInstance);
const equipmentInventoryClient = new generatedApi.EquipmentInventoryClient(appConfigs.apiUrl, axiosInstance);
const statusClient = new generatedApi.StatusClient(appConfigs.apiUrl, axiosInstance);
const stateClient = new generatedApi.StateClient(appConfigs.apiUrl, axiosInstance);
const serviceDescriptionClient = new generatedApi.ServiceDescriptionClient(appConfigs.apiUrl, axiosInstance);
const auditRecordClient = new generatedApi.AuditRecordClient(appConfigs.apiUrl, axiosInstance);
const eceEmployeeClient = new generatedApi.ECEEmployeeClient(appConfigs.apiUrl, axiosInstance);
const placeClient = new generatedApi.PlaceClient(appConfigs.apiUrl, axiosInstance);
const eslTrainingSummariesClient = new generatedApi.ESLTrainingSummariesClient(appConfigs.apiUrl, axiosInstance);
const employmentHistoryClient = new generatedApi.EmploymentHistoryClient(appConfigs.apiUrl, axiosInstance);
const serviceProvidedClient = new generatedApi.ServiceProvidedClient(appConfigs.apiUrl, axiosInstance);
const ecollectibleListingsQuickEntryClient = new generatedApi.EcollectibleListingsQuickEntryClient(
  appConfigs.apiUrl,
  axiosInstance
);
const laborHourBudgetClient = new generatedApi.LaborHourBudgetClient(appConfigs.apiUrl, axiosInstance);
const targetInvoiceClient = new generatedApi.TargetInvoiceClient(appConfigs.apiUrl, axiosInstance);
const targetReceivedClient = new generatedApi.TargetReceivedClient(appConfigs.apiUrl, axiosInstance);
const eslCitizenshipsClient = new generatedApi.ESLCitizenshipsClient(appConfigs.apiUrl, axiosInstance);
const christmasBudgetsClient = new generatedApi.ChristmasBudgetsClient(appConfigs.apiUrl, axiosInstance);
const productionBudgetClient = new generatedApi.ProductionBudgetClient(appConfigs.apiUrl, axiosInstance);
const jcentityClient = new generatedApi.JcentityClient(appConfigs.apiUrl, axiosInstance);
const feedbackLogClient = new generatedApi.FeedbackLogClient(appConfigs.apiUrl, axiosInstance);
const targetRotationClient = new generatedApi.TargetRotationClient(appConfigs.apiUrl, axiosInstance);
const halloweenBudgetsClient = new generatedApi.HalloweenBudgetsClient(appConfigs.apiUrl, axiosInstance);
const jcParticipantsClient = new generatedApi.JCParticipantsClient(appConfigs.apiUrl, axiosInstance);
const servicePeriodClient = new generatedApi.ServicePeriodClient(appConfigs.apiUrl, axiosInstance);
const containerAuditClient = new generatedApi.ContainerAuditClient(appConfigs.apiUrl, axiosInstance);
const dailyActivityLogClient = new generatedApi.DailyActivityLogClient(appConfigs.apiUrl, axiosInstance);
const costCenterClient = new generatedApi.CostCentersClient(appConfigs.apiUrl, axiosInstance);
const containerClient = new generatedApi.ContainerClient(appConfigs.apiUrl, axiosInstance);
const productClient = new generatedApi.ProductClient(appConfigs.apiUrl, axiosInstance);
const containerIssueClient = new generatedApi.ContainerIssueClient(appConfigs.apiUrl, axiosInstance);
const auditLogClient = new generatedApi.AuditLogClient(appConfigs.apiUrl, axiosInstance);
const assetTypesClient = new generatedApi.AssetTypesClient(appConfigs.apiUrl, axiosInstance);
const fixedAssetsClient = new generatedApi.FixedAssetsClient(appConfigs.apiUrl, axiosInstance);
const storesClient = new generatedApi.StoresClient(appConfigs.apiUrl, axiosInstance);
const referralClient = new generatedApi.ReferralClient(appConfigs.apiUrl, axiosInstance);
const carDonationLocationClient = new generatedApi.CarDonationLocationClient(appConfigs.apiUrl, axiosInstance);
const carDonationsDonorVehicleClient = new generatedApi.CarDonationsDonorVehicleClient(
  appConfigs.apiUrl,
  axiosInstance
);
const donationInErrorClient = new generatedApi.DonationInErrorClient(appConfigs.apiUrl, axiosInstance);
const generalInventoryItemsClient = new generatedApi.GeneralInventoryItemsClient(appConfigs.apiUrl, axiosInstance);
const generalInventorySummaryClient = new generatedApi.GeneralInventorySummaryClient(appConfigs.apiUrl, axiosInstance);
const generalInventoryDetailClient = new generatedApi.GeneralInventoryDetailClient(appConfigs.apiUrl, axiosInstance);
const historicalPreservationBoxClient = new generatedApi.HistoricalPreservationBoxClient(
  appConfigs.apiUrl,
  axiosInstance
);
const traineeNameClient = new generatedApi.TraineesClient(appConfigs.apiUrl, axiosInstance);
const donationInErrorDetailClient = new generatedApi.DonationInErrorDetailClient(appConfigs.apiUrl, axiosInstance);
const donationInErrorPictureClient = new generatedApi.DonationInErrorPictureClient(appConfigs.apiUrl, axiosInstance);
const carDonationImageClient = new generatedApi.CarDonationImageClient(appConfigs.apiUrl, axiosInstance);
const historicalPreservationItemClient = new generatedApi.HistoricalPreservationItemClient(
  appConfigs.apiUrl,
  axiosInstance
);
const jobConnectionAMPHistoryClient = new generatedApi.JobConnectionAMPHistoryClient(appConfigs.apiUrl, axiosInstance);
const jobConnectionAMPJobLeadClient = new generatedApi.JobConnectionAMPJobLeadClient(appConfigs.apiUrl, axiosInstance);
const jCEntityEventClient = new generatedApi.JCEntityEventClient(appConfigs.apiUrl, axiosInstance);
const shoplifterClient = new generatedApi.ShoplifterClient(appConfigs.apiUrl, axiosInstance);
const shoplifterDetailClient = new generatedApi.ShoplifterDetailClient(appConfigs.apiUrl, axiosInstance);
const shoplifterItemTakenClient = new generatedApi.ShoplifterItemTakenClient(appConfigs.apiUrl, axiosInstance);
const staffGoalsClient = new generatedApi.StaffGoalsClient(appConfigs.apiUrl, axiosInstance);
const photoClient = new generatedApi.PhotosClient(appConfigs.apiUrl, axiosInstance);
const newAuditDetailClient = new generatedApi.NewAuditDetailClient(appConfigs.apiUrl, axiosInstance);

export {
  cancelToken,
  apiInfoClient,
  exampleClient,
  employeeInjuryNoticeClient,
  applicationEEODataSheetsClient,
  applicationEthnicCodesClient,
  applicationReferralSourcesClient,
  accidentAnalysisClient,
  incidentReportClient,
  activityLogClient,
  employeeClient,
  activityCategoryClient,
  applicationFlowLogClient,
  careClient,
  storeClient,
  cashCountClient,
  cashVarianceClient,
  locationClient,
  jobListClient,
  reserveFundLogClient,
  depositRecordClient,
  communicationLogClient,
  storeMessageClient,
  donorCountClient,
  siteClient,
  bodyPartsClient,
  lossControlReviewsClient,
  safetyAuditReportClient,
  dmAuditClient,
  kaizenSuggestionClient,
  donationBudgetClient,
  eslReferralSourceClient,
  eslGoalsClient,
  eslInstructorsClient,
  trainingSummaryClient,
  tblClassNameClient,
  eceAncillaryServicesClient,
  traniningClient,
  teacherClient,
  eslClassTypeClient,
  eslParticipantClient,
  staffClient,
  caseNoteClient,
  educationClient,
  certificationClient,
  jcentityLocationClient,
  disabilsClient,
  idsClient,
  equipmentInventoryClient,
  statusClient,
  stateClient,
  serviceDescriptionClient,
  auditRecordClient,
  eceEmployeeClient,
  placeClient,
  eslTrainingSummariesClient,
  employmentHistoryClient,
  serviceProvidedClient,
  ecollectibleListingsQuickEntryClient,
  laborHourBudgetClient,
  targetInvoiceClient,
  eslCitizenshipsClient,
  productionBudgetClient,
  christmasBudgetsClient,
  jcentityClient,
  feedbackLogClient,
  targetRotationClient,
  halloweenBudgetsClient,
  jcParticipantsClient,
  servicePeriodClient,
  targetReceivedClient,
  containerAuditClient,
  dailyActivityLogClient,
  costCenterClient,
  containerClient,
  productClient,
  containerIssueClient,
  auditLogClient,
  assetTypesClient,
  fixedAssetsClient,
  storesClient,
  referralClient,
  carDonationLocationClient,
  carDonationsDonorVehicleClient,
  donationInErrorClient,
  generalInventoryItemsClient,
  generalInventorySummaryClient,
  generalInventoryDetailClient,
  historicalPreservationBoxClient,
  eslClassClient,
  traineeNameClient,
  donationInErrorDetailClient,
  donationInErrorPictureClient,
  carDonationImageClient,
  historicalPreservationItemClient,
  jobConnectionAMPHistoryClient,
  jcentityContactClient,
  jobConnectionAMPJobLeadClient,
  jCEntityEventClient,
  shoplifterDetailClient,
  shoplifterItemTakenClient,
  shoplifterClient,
  jcentityInviteClient,
  staffGoalsClient,
  jccontactLogClient,
  photoClient,
  newAuditDetailClient,
  incidentLocationClient,
  incidentPicturesClient,
  accidentAttachmentsClient
};
