import { tokenRequest } from 'auth/authConfig';
import axios from 'axios';
import authInstance from 'auth/authInstance';
import { InteractionRequiredAuthError } from '@azure/msal-common/dist/error/InteractionRequiredAuthError';
// MSAL configuration

const axiosInstance = axios.create({
  timeout: 180000,
  headers: {
    'content-type': 'application/json',
  },
});

export const initAxios = (setErrors: any) => {
  axiosInstance.interceptors.request.use(
    async (request) => {
      const activeAccount = authInstance.getActiveAccount() || undefined;
      if (!activeAccount) {
        return request;
      }

      try {
        //accquire access token to calls api
        const tokens = await authInstance.acquireTokenSilent({
          ...tokenRequest,
          account: activeAccount,
        });
        if (request.headers) {
          request.headers.Authorization = `Bearer ${tokens.accessToken}`;
        }
      } catch (err) {
        console.error(err);
        if (
          err instanceof InteractionRequiredAuthError &&
          InteractionRequiredAuthError.isInteractionRequiredError(err.errorCode)
        ) {
          console.log('required login ...');
        }
      }
      return request;
    },
    (err) => Promise.reject(err)
  );

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (axios.isCancel(error)) {
        return new Promise(() => {});
      }

      if (
        error.request &&
        error.request.responseType === 'blob' &&
        error.response.data instanceof Blob &&
        error.response.data.type &&
        error.response.data.type.toLowerCase().indexOf('json') !== -1
      ) {
        return new Promise((resolve, reject) => {
          let reader = new FileReader();

          reader.onload = () => {
            error.response.data = JSON.parse(reader.result as string);
            resolve(Promise.reject(error));
          };

          reader.onerror = () => {
            reject(error);
          };

          reader.readAsText(error.response.data);
        });
      }

      if (error.response.data && error.response.data.errors && error.response.data.errors.length) {
        const msg = [];
        for (var key in error.response.data.errors) {
          if (error.response.data.errors.hasOwnProperty(key)) {
            msg.push(error.response.data.errors[key]);
          }
        }

        setErrors(msg);
      } else if (error.response.data.title) {
        setErrors([error.response.data.title]);
      }

      return Promise.reject(error);
    }
  );
};

export const cancelToken = axios.CancelToken;

export default axiosInstance;
