import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useState } from 'react';

const SaveCancelDialog = ({
  children,
  title,
  show,
  onCancel,
  onDialogClose,
  onSave,
  size = 'lg',
  fullWidth = false,
  ...ortherProps
}: any) => {
  const [isDisable, setDisable] = useState(false);
  const handleClose = () => {
    if (onCancel) {
      onCancel();
    } else {
      onDialogClose({
        success: false,
      });
    }
  };

  const handleSave = async () => {
    setDisable(true);
    await onSave();
    setDisable(false);
  };

  return (
    <Dialog open={show} onClose={handleClose} fullWidth={fullWidth} {...ortherProps}>
      <DialogTitle sx={{ fontSize: '1.325rem' }}>{title}</DialogTitle>
      <DialogContent>{children ?? <DialogContentText>{children}</DialogContentText>}</DialogContent>
      <DialogActions>
        <Button onClick={handleSave} disabled={isDisable}>
          Save
        </Button>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SaveCancelDialog;
