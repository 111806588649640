import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const ConfirmDialog = ({ message, title, onDialogClose, btnLabel, size = 'lg' }: IProps) => {
  const handleOk = () => onDialogClose({ success: true });
  const handleCancel = () => onDialogClose({ success: false });

  return (
    <Dialog open={true} onClose={handleCancel}>
      <DialogTitle sx={{ fontSize: '1.325rem' }}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOk}>{btnLabel || 'Yes'}</Button>
        <Button onClick={handleCancel}>No</Button>
      </DialogActions>
    </Dialog>
  );
};

interface IProps {
  message: string;
  title?: string;
  btnLabel?: string;
  onDialogClose: any;
  size?: 'sm' | 'lg' | 'xl';
}

export default ConfirmDialog;
