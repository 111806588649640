import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import Switch from '@mui/material/Switch';
import Checkbox from '@mui/material/Checkbox';
import { useState, useEffect } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import { DatePicker } from '@mui/x-date-pickers';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';

export const FormInputSelector = (props: any) => {
  const { field, formikFieldProps, error } = props;
  const [Component, setComponent] = useState<any | null>(null);
  const [componentProps, setComponentProps] = useState<any | null>(null);

  useEffect(() => {
    const { id, name, type, label, data, disableFuture, required, setFieldValue, ...otherProps } = field;
    const commonProps = { id: id || name, name, label, variant: 'outlined', type, disableFuture, required };

    if (disableFuture === undefined) delete commonProps.disableFuture;

    let inputProps: any;
    let FormInput: any;

    switch (type) {
      case 'select':
        FormInput = TextField;
        inputProps = {
          select: true,
          className: 'labelBorder selectInput',
          children: (data ?? []).map((x: any) => {
            return (
              <MenuItem key={x.id} value={x.id}>
                {x.name}
              </MenuItem>
            );
          }),
          ...commonProps,
          ...otherProps,
        };
        break;
      case 'checkbox':
        FormInput = FormControlLabel;
        inputProps = {
          label,
          control: <Checkbox checked={formikFieldProps.value} {...commonProps} {...otherProps} />,
        };
        break;
      case 'switch':
        FormInput = FormControlLabel;
        inputProps = {
          label,
          control: <Switch checked={formikFieldProps.value} {...commonProps} {...otherProps} />,
        };
        break;
      case 'radio':
        FormInput = RadioGroup;
        inputProps = {
          ...commonProps,
          value: formikFieldProps.value,
          onChange: formikFieldProps.onChange,
          children: (data ?? []).map((x: any) => {
            return <FormControlLabel key={x.value} value={x.value} control={<Radio />} label={x.label} />;
          }),
          ...otherProps,
        };
        break;
      case 'date':
        FormInput = DatePicker;
        inputProps = {
          label,
          value: formikFieldProps.value,
          disableFuture,
          required,
        };
        break;
      case 'number':
        FormInput = TextField;
        inputProps = { ...commonProps, ...otherProps };
        break;
      default:
        FormInput = TextField;
        inputProps = { ...commonProps, ...otherProps, className: 'labelBorder textFieldInput' };
        break;
    }

    setComponent(FormInput);
    setComponentProps(inputProps);
  }, [Component, field, formikFieldProps]);

  if (!Component) {
    return null;
  }

  if (field.type === 'date') {
    return (
      <Component
        {...componentProps}
        onChange={(date: any) => {
          console.log({ date });
          props.setFieldValue(field.name, date, true);
        }}
        renderInput={(params: any) => (
          <TextField
            size="small"
            variant="outlined"
            className="labelBorder"
            required={componentProps.required}
            {...formikFieldProps}
            {...params}
          />
        )}
      />
    );
  }

  if (field.type === 'number') {
    return (
      <Component
        {...componentProps}
        type="text"
        fullWidth
        onChange={(event: any) => {
          const {
            target: { value },
          } = event;
          if (/^[0-9]+$/.test(value) || value === '') {
            props.setFieldValue(formikFieldProps.name, value, true);
          }
        }}
        error={error}
        value={formikFieldProps.value}
        onBlur={formikFieldProps.handleBlur}
      />
    );
  }
  return (
    <>
      {field.type === 'radio' && <FormLabel id={field.id || field.name}>{field.label}</FormLabel>}
      <Component error={error} {...componentProps} {...formikFieldProps} />
    </>
  );
};

export default FormInputSelector;
