import { msalConfig, loginRequest } from 'auth/authConfig';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
const msalInstance = new PublicClientApplication(msalConfig);

//enable to emit an event when the user removed account from storage
msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  } else if (event.eventType === EventType.ACCOUNT_REMOVED) {
    // Update UI with account logged out => require login
    msalInstance.loginRedirect({ ...loginRequest });
  }
});

export default msalInstance;
