export const TARGET_SHIPMENT_EDIT = 'GWFORMSTARGETSHIPMENTSEDIT';
export const TARGET_SHIPMENT_SUBMIT = 'GWFORMSTARGETSHIPMENTSSUBMIT';
export const TARGET_SHIPMENT_VIEW = 'GWFORMSTARGETSHIPMENTSVIEW';

export const TARGET_RECEIPTS_EDIT = 'GWFORMSTARGETRECEIPTSEDIT';
export const TARGET_RECEIPTS_SUBMIT = 'GWFORMSTARGETRECEIPTSSUBMIT';
export const TARGET_RECEIPTS_VIEW = 'GWFORMSTARGETRECEIPTSVIEW';

export const TARGET_INVOICES_EDIT = 'GWFORMSTARGETINVOICESEDIT';
export const TARGET_INVOICES_SUBMIT = 'GWFORMSTARGETINVOICESSUBMIT';
export const TARGET_INVOICES_VIEW = 'GWFORMSTARGETINVOICESVIEW';

export const STORE_MESSAGES_EDIT = 'GWFORMSSTOREMESSAGESEDIT';
export const STORE_MESSAGES_SUBMIT = 'GWFORMSSTOREMESSAGESSUBMIT';
export const STORE_MESSAGES_VIEW = 'GWFORMSSTOREMESSAGESVIEW';

export const PRODUCTION_BUDGET_EDIT = 'GWFORMSPRODUCTIONBUDGETEDIT';
export const PRODUCTION_BUDGET_SUBMIT = 'GWFORMSPRODUCTIONBUDGETSUBMIT';
export const PRODUCTION_BUDGET_VIEW = 'GWFORMSPRODUCTIONBUDGETVIEW';

export const HALLOWEEN_BUDGET_EDIT = 'GWFORMSNEWHALLOWEENBUDGETENTRY/HALLOWEENBUDGETEDIT';
export const HALLOWEEN_BUDGET_SUBMIT = 'GWFORMSNEWHALLOWEENBUDGETENTRY/HALLOWEENBUDGETSUBMIT';
export const HALLOWEEN_BUDGET_VIEW = 'GWFORMSNEWHALLOWEENBUDGETENTRY/HALLOWEENBUDGETVIEW';

export const DONATION_BUDGET_EDIT = 'GWFORMSNEWDONATIONBUDGETENTRY/DONATIONBUDGETEDIT';
export const DONATION_BUDGET_SUBMIT = 'GWFORMSNEWDONATIONBUDGETENTRY/DONATIONBUDGETSUBMIT';
export const DONATION_BUDGET_VIEW = 'GWFORMSNEWDONATIONBUDGETENTRY/DONATIONBUDGETVIEW';

export const CHRISTMAS_BUDGET_EDIT = 'GWFORMSNEWCHRISTMASBUDGETENTRY/CHRISTMASBUDGETEDIT';
export const CHRISTMAS_BUDGET_SUBMIT = 'GWFORMSNEWCHRISTMASBUDGETENTRY/CHRISTMASBUDGETSUBMIT';
export const CHRISTMAS_BUDGET_VIEW = 'GWFORMSNEWCHRISTMASBUDGETENTRY/CHRISTMASBUDGETVIEW';

export const LABOR_HOUR_BUDGET_EDIT = 'GWFORMSLABORHOURBUDGETEDIT';
export const LABOR_HOUR_BUDGET_SUBMIT = 'GWFORMSLABORHOURBUDGETSUBMIT';
export const LABOR_HOUR_BUDGET_VIEW = 'GWFORMSLABORHOURBUDGETVIEW';

export const GOODWILL_CARES_EDIT = 'GWFORMSGOODWILLCARESEDIT';
export const GOODWILL_CARES_SUBMIT = 'GWFORMSGOODWILLCARESSUBMIT';
export const GOODWILL_CARES_VIEW = 'GWFORMSGOODWILLCARESVIEW';

export const COMMUNICATION_ENTRY_SUBMIT = 'GWFORMSCOMMUNICATIONENTRYSUBMIT';
export const COMMUNICATION_ENTRY_VIEW = 'GWFORMSCOMMUNICATIONENTRYVIEW';

export const ACTIVITY_LOG_SUBMIT = 'GWFORMSACTIVITYLOGSUBMIT';
export const ACTIVITY_LOG_VIEW = 'GWFORMSACTIVITYLOGVIEW';

export const ECOMMERCE_CONTAINER_VIEW = 'GWFORMSE-COMMERCECONTAINERVIEW';
export const ECOMMERCE_CONTAINER_EDIT = 'GWFORMSE-COMMERCECONTAINEREDIT';
export const ECOMMERCE_CONTAINER_SUBMIT = 'GWFORMSE-COMMERCECONTAINERSUBMIT';

export const SITE_VIEW = 'GWFORMSSITESVIEW';
export const SITE_EDIT = 'GWFORMSSITESEDIT';
export const SITE_SUBMIT = 'GWFORMSSITESSUBMIT';
export const E_COMMERCE_CONTAINER_VIEW = 'GWFORMSE-COMMERCECONTAINERVIEW';
export const E_COMMERCE_CONTAINER_EDIT = 'GWFORMSE-COMMERCECONTAINEREDIT';
export const E_COMMERCE_CONTAINER_SUBMIT = 'GWFORMSE-COMMERCECONTAINERSUBMIT';

export const SITES_VIEW = 'GWFORMSSITESVIEW';
export const SITES_EDIT = 'GWFORMSSITESEDIT';
export const SITES_SUBMIT = 'GWFORMSSITESSUBMIT';

export const MEDIA_CONTAINER_AUDIT_VIEW = 'GWFORMSMEDIACONTAINERAUDITVIEW';
export const MEDIA_CONTAINER_AUDIT_EDIT = 'GWFORMSMEDIACONTAINERAUDITEDIT';
export const MEDIA_CONTAINER_AUDIT_SUBMIT = 'GWFORMSMEDIACONTAINERAUDITSUBMIT';

export const BOOK_CONTAINER_AUDIT_VIEW = 'GWFORMSBOOKCONTAINERAUDITVIEW';
export const BOOK_CONTAINER_AUDIT_EDIT = 'GWFORMSBOOKCONTAINERAUDITEDIT';
export const BOOK_CONTAINER_AUDIT_SUBMIT = 'GWFORMSBOOKCONTAINERAUDITSUBMIT';

export const ECOLLECTABLE_LISTINGS_VIEW = 'GWFORMSECOLLECTABLELISTINGSVIEW';
export const ECOLLECTABLE_LISTINGS_EDIT = 'GWFORMSECOLLECTABLELISTINGSEDIT';
export const ECOLLECTABLE_LISTINGS_SUBMIT = 'GWFORMSECOLLECTABLELISTINGSSUBMIT';

export const FEEDBACK_LOG_VIEW = 'GWFORMSFEEDBACKLOGVIEW';
export const FEEDBACK_LOG_EDIT = 'GWFORMSFEEDBACKLOGEDIT';
export const FEEDBACK_LOG_SUBMIT = 'GWFORMSFEEDBACKLOGSUBMIT';

export const CAR_DONATIONS_VIEW = 'GWFORMSCARDONATIONSVIEW';
export const CAR_DONATIONS_EDIT = 'GWFORMSCARDONATIONSEDIT';
export const CAR_DONATIONS_SUBMIT = 'GWFORMSCARDONATIONSSUBMIT';

export const FIXED_ASSETS_VIEW = 'GWFORMSFIXEDASSETSVIEW';
export const FIXED_ASSETS_EDIT = 'GWFORMSFIXEDASSETSEDIT';
export const FIXED_ASSETS_SUBMIT = 'GWFORMSFIXEDASSETSSUBMIT';

export const ASSET_TYPES_VIEW = 'GWFORMSASSETTYPES(FIXEDASSETS)VIEW';
export const ASSET_TYPES_EDIT = 'GWFORMSASSETTYPES(FIXEDASSETS)EDIT';
export const ASSET_TYPES_SUBMIT = 'GWFORMSASSETTYPES(FIXEDASSETS)SUBMIT';

export const COST_CENTERS_VIEW = 'GWFORMSCOSTCENTERS(FIXEDASSETS)VIEW';
export const COST_CENTERS_EDIT = 'GWFORMSCOSTCENTERS(FIXEDASSETS)EDIT';
export const COST_CENTERS_SUBMIT = 'GWFORMSCOSTCENTERS(FIXEDASSETS)SUBMIT';

export const SHOPLIFTER_VIEW = 'GWFORMSLOSSCONTROLSHOPLIFTERSUMMARIESVIEW';
export const SHOPLIFTER_EDIT = 'GWFORMSLOSSCONTROLSHOPLIFTERSUMMARIESEDIT';
export const SHOPLIFTER_SUBMIT = 'GWFORMSLOSSCONTROLSHOPLIFTERSUMMARIESSUBMIT';

export const DONATION_IN_ERROR_VIEW = 'GWFORMSDONATIONINERRORVIEW';
export const DONATION_IN_ERROR_EDIT = 'GWFORMSDONATIONINERROREDIT';
export const DONATION_IN_ERROR_SUBMIT = 'GWFORMSDONATIONINERRORSUBMIT';

export const GENERAL_INVENTORY_VIEW = 'GWFORMSGENERALINVENTORYVIEW';
export const GENERAL_INVENTORY_EDIT = 'GWFORMSGENERALINVENTORYEDIT';
export const GENERAL_INVENTORY_SUBMIT = 'GWFORMSGENERALINVENTORYSUBMIT';

export const GENERAL_INVENTORY_ITEMS_LIST_VIEW = 'GWFORMSGENERALINVENTORYITEMSLISTVIEW';
export const GENERAL_INVENTORY_ITEMS_LIST_EDIT = 'GWFORMSGENERALINVENTORYITEMSLISTEDIT';
export const GENERAL_INVENTORY_ITEMS_LIST_SUBMIT = 'GWFORMSGENERALINVENTORYITEMSLISTSUBMIT';

export const ECETRAINING_VIEW = 'GWFORMSECETRAININGVIEW';
export const ECETRAINING_EDIT = 'GWFORMSECETRAININGEDIT';
export const ECETRAINING_SUBMIT = 'GWFORMSECETRAININGSUBMIT';

export const ECECOMMUNITY_PARTICIPANT_VIEW = 'GWFORMSECECOMMUNITYPARTICIPANTVIEW';
export const ECECOMMUNITY_PARTICIPANT_EDIT = 'GWFORMSECECOMMUNITYPARTICIPANTEDIT';
export const ECECOMMUNITY_PARTICIPANT_SUBMIT = 'GWFORMSECECOMMUNITYPARTICIPANTSUBMIT';

export const ECEEMPLOYEE_VIEW = 'GWFORMSECEEMPLOYEEVIEW';
export const ECEEMPLOYEE_EDIT = 'GWFORMSECEEMPLOYEEEDIT';
export const ECEEMPLOYEE_SUBMIT = 'GWFORMSECEEMPLOYEESUBMIT';

export const ECEANCILLARY_SERVICES_VIEW = 'GWFORMSECEANCILLARYSERVICESVIEW';
export const ECEANCILLARY_SERVICES_EDIT = 'GWFORMSECEANCILLARYSERVICESEDIT';
export const ECEANCILLARY_SERVICES_SUBMIT = 'GWFORMSECEANCILLARYSERVICESSUBMIT';

export const ECECLASSES_VIEW = 'GWFORMSECECLASSESVIEW';
export const ECECLASSES_EDIT = 'GWFORMSECECLASSESEDIT';
export const ECECLASSES_SUBMIT = 'GWFORMSECECLASSESSUBMIT';

export const ECELOCATIONS_VIEW = 'GWFORMSECELOCATIONSVIEW';
export const ECELOCATIONS_EDIT = 'GWFORMSECELOCATIONSEDIT';
export const ECELOCATIONS_SUBMIT = 'GWFORMSECELOCATIONSSUBMIT';

export const ECETEACHERS_VIEW = 'GWFORMSECETEACHERSVIEW';
export const ECETEACHERS_EDIT = 'GWFORMSECETEACHERSEDIT';
export const ECETEACHERS_SUBMIT = 'GWFORMSECETEACHERSSUBMIT';

export const ESLPARTICIPANTS_VIEW = 'GWFORMSESLPARTICIPANTSVIEW';
export const ESLPARTICIPANTS_EDIT = 'GWFORMSESLPARTICIPANTSEDIT';
export const ESLPARTICIPANTS_SUBMIT = 'GWFORMSESLPARTICIPANTSSUBMIT';

export const ESLTRAINING_VIEW = 'GWFORMSESLTRAININGVIEW';
export const ESLTRAINING_EDIT = 'GWFORMSESLTRAININGEDIT';
export const ESLTRAINING_SUBMIT = 'GWFORMSESLTRAININGSUBMIT';

export const ESLCITIZENSHIP_VIEW = 'GWFORMSESLCITIZENSHIPVIEW';
export const ESLCITIZENSHIP_EDIT = 'GWFORMSESLCITIZENSHIPEDIT';
export const ESLCITIZENSHIP_SUBMIT = 'GWFORMSESLCITIZENSHIPSUBMIT';

export const ESLCLASSES_VIEW = 'GWFORMSESLCLASSESVIEW';
export const ESLCLASSES_EDIT = 'GWFORMSESLCLASSESEDIT';
export const ESLCLASSES_SUBMIT = 'GWFORMSESLCLASSESSUBMIT';

export const ESLINSTRUCTORS_VIEW = 'GWFORMSESLINSTRUCTORSVIEW';
export const ESLINSTRUCTORS_EDIT = 'GWFORMSESLINSTRUCTORSEDIT';
export const ESLINSTRUCTORS_SUBMIT = 'GWFORMSESLINSTRUCTORSSUBMIT';

export const ESLLOCATIONS_VIEW = 'GWFORMSESLLOCATIONSVIEW';
export const ESLLOCATIONS_EDIT = 'GWFORMSESLLOCATIONSEDIT';
export const ESLLOCATIONS_SUBMIT = 'GWFORMSESLLOCATIONSSUBMIT';

export const ESLREFERRAL_SOURCES_VIEW = 'GWFORMSESLREFERRALSOURCESVIEW';
export const ESLREFERRAL_SOURCES_EDIT = 'GWFORMSESLREFERRALSOURCESEDIT';
export const ESLREFERRAL_SOURCES_SUBMIT = 'GWFORMSESLREFERRALSOURCESSUBMIT';

export const DONORENTRY_VIEW = 'GWFORMSDONORENTRYVIEW';
export const DONORENTRY_EDIT = 'GWFORMSDONORENTRYEDIT';
export const DONORENTRY_SUBMIT = 'GWFORMSDONORENTRYSUBMIT';

export const APPLICATIONENTRY_VIEW = 'GWFORMSAPPLICATIONENTRYVIEW';
export const APPLICATIONENTRY_EDIT = 'GWFORMSAPPLICATIONENTRYEDIT';
export const APPLICATIONENTRY_SUBMIT = 'GWFORMSAPPLICATIONENTRYSUBMIT';

export const APPLICATIONLOG_VIEW = 'GWFORMSAPPLICATIONLOGVIEW';
export const APPLICATIONLOG_EDIT = 'GWFORMSAPPLICATIONLOGEDIT';
export const APPLICATIONLOG_SUBMIT = 'GWFORMSAPPLICATIONLOGSUBMIT';

export const EEODATA_VIEW = 'GWFORMSEEODATAVIEW';
export const EEODATA_EDIT = 'GWFORMSEEODATAEDIT';
export const EEODATA_SUBMIT = 'GWFORMSEEODATASUBMIT';

export const EQUIPMENTINVENTORY_VIEW = 'GWFORMSEQUIPMENTINVENTORYVIEW';
export const EQUIPMENTINVENTORY_EDIT = 'GWFORMSEQUIPMENTINVENTORYEDIT';
export const EQUIPMENTINVENTORY_SUBMIT = 'GWFORMSEQUIPMENTINVENTORYSUBMIT';

export const EMPLOYEEINJURYNOTICE_VIEW = 'GWFORMSEMPLOYEEINJURYNOTICEVIEW';
export const EMPLOYEEINJURYNOTICE_EDIT = 'GWFORMSEMPLOYEEINJURYNOTICEEDIT';
export const EMPLOYEEINJURYNOTICE_SUBMIT = 'GWFORMSEMPLOYEEINJURYNOTICESUBMIT';

export const ACCIDENTANALYSIS_VIEW = 'GWFORMSACCIDENTANALYSISVIEW';
export const ACCIDENTANALYSIS_EDIT = 'GWFORMSACCIDENTANALYSISEDIT';
export const ACCIDENTANALYSIS_SUBMIT = 'GWFORMSACCIDENTANALYSISSUBMIT';

export const LOSSCONTROLREVIEW_VIEW = 'GWFORMSLOSSCONTROLREVIEWVIEW';
export const LOSSCONTROLREVIEW_EDIT = 'GWFORMSLOSSCONTROLREVIEWEDIT';
export const LOSSCONTROLREVIEW_SUBMIT = 'GWFORMSLOSSCONTROLREVIEWSUBMIT';
export const LOSSCONTROLREVIEW_APPROVER = 'GWFORMSLOSSCONTROLREVIEWAPPROVE';

export const SAFETY_AUDITREPORT_VIEW = 'GWFORMSSAFETYAUDITREPORTVIEW';
export const SAFETY_AUDITREPORT_EDIT = 'GWFORMSSAFETYAUDITREPORTEDIT';
export const SAFETY_AUDITREPORT_SUBMIT = 'GWFORMSSAFETYAUDITREPORTSUBMIT';

export const KAIZEN_SCOREENTRY_VIEW = 'GWFORMSKAIZENSCOREENTRYVIEW';
export const KAIZEN_SCOREENTRY_EDIT = 'GWFORMSKAIZENSCOREENTRYEDIT';
export const KAIZEN_SCOREENTRY_SUBMIT = 'GWFORMSKAIZENSCOREENTRYSUBMIT';
export const KAIZEN_SCOREENTRY_SUBMIT_WITH_EDIT = 'GWFORMSKAIZENSCOREENTRYSUBMITWITHEDIT';

export const KAIZEN_SUGGESTION_VIEW = 'GWFORMSKAIZENSUGGESTIONVIEW';
export const KAIZEN_SUGGESTION_EDIT = 'GWFORMSKAIZENSUGGESTIONEDIT';
export const KAIZEN_SUGGESTION_SUBMIT = 'GWFORMSKAIZENSUGGESTIONSUBMIT';

export const DISTRICTMANAGER_VIEW = 'GWFORMSDISTRICTMANAGERAUDITVIEW';
export const DISTRICTMANAGER_EDIT = 'GWFORMSDISTRICTMANAGERAUDITEDIT';
export const DISTRICTMANAGER_SUBMIT = 'GWFORMSDISTRICTMANAGERAUDITSUBMIT';

export const CASHCOUNT_VIEW = 'GWFORMSCASHCOUNTVIEW';
export const CASHCOUNT_EDIT = 'GWFORMSCASHCOUNTEDIT';
export const CASHCOUNT_SUBMIT = 'GWFORMSCASHCOUNTSUBMIT';

export const RESERVEFUND_VIEW = 'GWFORMSRESERVEFUNDVIEW';
export const RESERVEFUND_EDIT = 'GWFORMSRESERVEFUNDEDIT';
export const RESERVEFUND_SUBMIT = 'GWFORMSRESERVEFUNDSUBMIT';

export const DEPOSITENTRY_VIEW = 'GWFORMSDEPOSITENTRYVIEW';
export const DEPOSITENTRY_EDIT = 'GWFORMSDEPOSITENTRYEDIT';
export const DEPOSITENTRY_SUBMIT = 'GWFORMSDEPOSITENTRYSUBMIT';
export const DEPOSITENTRY_OVERRIDE = 'GWFORMSDEPOSITENTRYOVERRIDE';

export const CASHIERVARIANCE_VIEW = 'GWFORMSCASHIERVARIANCEVIEW';
export const CASHIERVARIANCE_EDIT = 'GWFORMSCASHIERVARIANCEEDIT';
export const CASHIERVARIANCE_SUBMIT = 'GWFORMSCASHIERVARIANCESUBMIT';

export const INCIDENTREPORT_VIEW = 'GWFORMSINCIDENTREPORTVIEW';
export const INCIDENTREPORT_EDIT = 'GWFORMSINCIDENTREPORTEDIT';
export const INCIDENTREPORT_SUBMIT = 'GWFORMSINCIDENTREPORTSUBMIT';

export const LOSSCONTROLDAILY_ACTIVITYLOG_VIEW = 'GWFORMSLOSSCONTROLDAILYACTIVITYLOGVIEW';
export const LOSSCONTROLDAILY_ACTIVITYLOG_EDIT = 'GWFORMSLOSSCONTROLDAILYACTIVITYLOGEDIT';
export const LOSSCONTROLDAILY_ACTIVITYLOG_SUBMIT = 'GWFORMSLOSSCONTROLDAILYACTIVITYLOGSUBMIT';

export const LOSSCONTROLSHOPLIFTER_SUMMARIES_VIEW = 'GWFORMSLOSSCONTROLSHOPLIFTERSUMMARIESVIEW';
export const LOSSCONTROLSHOPLIFTER_SUMMARIES_EDIT = 'GWFORMSLOSSCONTROLSHOPLIFTERSUMMARIESEDIT';
export const LOSSCONTROLSHOPLIFTER_SUMMARIES_SUBMIT = 'GWFORMSLOSSCONTROLSHOPLIFTERSUMMARIESSUBMIT';

export const LOSSCONTROLSHOPLIFTERSUMMARYDETAIL_VIEW = 'GWFORMSLOSSCONTROLSHOPLIFTERSUMMARYDETAILVIEW';
export const LOSSCONTROLSHOPLIFTERSUMMARYDETAIL_EDIT = 'GWFORMSLOSSCONTROLSHOPLIFTERSUMMARYDETAILEDIT';
export const LOSSCONTROLSHOPLIFTERSUMMARYDETAIL_SUBMIT = 'GWFORMSLOSSCONTROLSHOPLIFTERSUMMARYDETAILSUBMIT';

export const HISTORICALPRESERVATION_VIEW = 'GWFORMSHISTORICALPRESERVATIONVIEW';
export const HISTORICALPRESERVATION_EDIT = 'GWFORMSHISTORICALPRESERVATIONEDIT';
export const HISTORICALPRESERVATION_SUBMIT = 'GWFORMSHISTORICALPRESERVATIONSUBMIT';

export const JCPARTICIPANTSEARCH_VIEW = 'GWFORMSJCPARTICIPANTSEARCHVIEW';
export const JCPARTICIPANTSEARCH_EDIT = 'GWFORMSJCPARTICIPANTSEARCHEDIT';
export const JCPARTICIPANTSEARCH_SUBMIT = 'GWFORMSJCPARTICIPANTSEARCHSUBMIT';
export const JCPARTICIPANTSEARCH_EDITWITHSSN = 'GWFORMSJCPARTICIPANTSEARCHEDITWITHSSN';

export const JCLOCATIONS_VIEW = 'GWFORMSJCLOCATIONSVIEW';
export const JCLOCATIONS_EDIT = 'GWFORMSJCLOCATIONSEDIT';
export const JCLOCATIONS_SUBMIT = 'GWFORMSJCLOCATIONSSUBMIT';

export const JCSTAFF_VIEW = 'GWFORMSJCSTAFFVIEW';
export const JCSTAFF_EDIT = 'GWFORMSJCSTAFFEDIT';
export const JCSTAFF_SUBMIT = 'GWFORMSJCSTAFFSUBMIT';

export const JCAMP_VIEW = 'GWFORMSJCAMPVIEW';
export const JCAMP_EDIT = 'GWFORMSJCAMPEDIT';
export const JCAMP_SUBMIT = 'GWFORMSJCAMPSUBMIT';

export const CAMERAAPP_VIEW = 'GWFORMSCAMERAAPPVIEW';
export const CAMERAAPP_SUBMIT = 'GWFORMSCAMERAAPPSUBMIT';

export const JCEDITGOAL_VIEW = 'GWFORMSJCEDITGOALSVIEW';
export const JCEDITGOAL_EDIT = 'GWFORMSJCEDITGOALSEDIT';
export const JCEDITGOAL_SUBMIT = 'GWFORMSJCEDITGOALSSUBMIT';
