import { createSlice, current } from '@reduxjs/toolkit';

export const dialogSlice = createSlice({
  name: 'dialog',
  initialState: {
    dialogs: [],
  },
  reducers: {
    openDialog: (state: any, action: any): void => {
      console.log({ state: current(state), action });
      state.dialogs.push(action.payload);
      console.log({ dialogs: current(state.dialogs) });
      //state.dialogs = [...state.dialogs, action.payload];
    },
    closeDialog: (state, action): void => {
      const newDialogs = state.dialogs.filter((x: any) => x.id !== action.payload);

      state.dialogs = newDialogs;
    },
  },
});

// Action creators are generated for each case reducer function
export const { openDialog, closeDialog } = dialogSlice.actions;

export default dialogSlice.reducer;
