import { LazyExoticComponent, Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

import Layout from './layout';

import useAuthRouter from 'auth/authRoutes/useAuthRoutes';

const DialogExample = lazy(() => import('features/examples/DialogExample'));
const PageExample = lazy(() => import('features/examples/PageExample'));
const TableExample = lazy(() => import('features/examples/TableExample'));
const TextInputComponents = lazy(() => import('features/examples/TextInputComponents'));
const FormExample = lazy(() => import('features/examples/FormExample'));

const Employment = lazy(() => import('features/employment'));
const Application = lazy(() => import('features/application'));
const EmploymentInjuryNotice = lazy(() => import('features/employment/InjuryNotice'));
const SafetyAuditReport = lazy(() => import('features/safetyAuditReport/SafetyAuditReport'));
const UpsertApplication = lazy(() => import('features/application/UpsertApplication'));
const EmploymentActivityLog = lazy(() => import('features/employment/ActivityLog'));
const AccidentAnalysisDetails = lazy(
  () => import('features/accident-analysis/accident-analysis-details/accident-analysis-details')
);
const IncidentReportDetails = lazy(
  () => import('features/incident-report/incident-report-details/incident-report-details')
);
const IncidentReportCreate = lazy(() => import('features/incident-report/create'));
const IncidentReportEdit = lazy(() => import('features/incident-report//edit'));

const CashierVarianceCreate = lazy(() => import('features/cash-controls/cashier-variance/create'));
const CashierVarianceEdit = lazy(() => import('features/cash-controls/cashier-variance/edit'));
const CareDetails = lazy(() => import('features/care/care-details/care-details'));
const CashCountCreate = lazy(() => import('features/cash-counts/enter-cash-counts'));
const CashCountView = lazy(() => import('features/cash-counts/cash-count-view'));
const CashCountList = lazy(() => import('features/cash-counts/cash-count-list'));
const CashierVarianceList = lazy(() => import('features/cash-controls/cashier-variance'));
const ReserveFundLogList = lazy(
  () => import('features/cash-controls/reserve-fund-log/reserve-fund-log-grid/reserve-fund-log-grid')
);
const ReserveFundLogCreate = lazy(
  () => import('features/cash-controls/reserve-fund-log/reserve-fund-log-details/create')
);
const ReserveFundLogEdit = lazy(() => import('features/cash-controls/reserve-fund-log/reserve-fund-log-details/edit'));
const Deposit = lazy(() => import('features/deposits/index'));
const DepositList = lazy(() => import('features/deposits/deposit-list'));
const CommunicationLog = lazy(() => import('features/communication-log/add-communication-log'));
const StoreMessage = lazy(() => import('features/store-messages/list-store-message'));
const Donor = lazy(() => import('features/donors/index'));
const LossControlReviewCreate = lazy(() => import('features/lossControlReview/create'));
const LossControlReviewEdit = lazy(() => import('features/lossControlReview/edit'));
const LossControlAccidentAnalysisSearch = lazy(() => import('features/loss-control-accident-analysis-search'));
const SafetyAuditReportList = lazy(() => import('features/safety-audits/safety-audit-list'));
const DMAuditDetails = lazy(() => import('features/dm-audit/DMAudit'));
const KaizenDetails = lazy(
  () => import('features/kaizen-suggestion/kaizen-suggestion-details/kaizen-suggestion-details')
);
const DonationBudgetList = lazy(() => import('features/donation-budget/donation-budget-list'));
const DonationBudgetEntry = lazy(() => import('features/donation-budget/donation-budget-entry'));

const ReferralSource = lazy(() => import('features/referralSource/ReferralSource'));
const Instructors = lazy(() => import('features/instructors'));
const Sites = lazy(() => import('features/site/site-list'));
const LocationDetails = lazy(() => import('features/location/location-details/location-details'));
const LocationList = lazy(() => import('features/location/location-list/location-list'));
const LossControlReviewList = lazy(() => import('features/lossControlReview/index'));
const TrainingSearch = lazy(() => import('features/training-search/index'));
const TrainingEntry = lazy(() => import('features/training/training-details/TrainingDetails'));
const EceClass = lazy(() => import('features/eceClass/EceClass'));
const EceTeachers = lazy(() => import('features/ece-teachers'));

const AncillaryServices = lazy(() => import('features/ancillary-services'));
const AncillaryServicesCreate = lazy(() => import('features/ancillary-services/create'));
const AncillaryServicesEdit = lazy(() => import('features/ancillary-services/edit'));

const JobConnectionParticipants = lazy(() => import('features/job-connection-participants'));
const JobConnectionParticipantCreate = lazy(() => import('features/job-connection-participants/create'));
const JobConnectionParticipantEdit = lazy(() => import('features/job-connection-participants/edit'));

const EslClassType = lazy(() => import('features/eslClassType/EslClassType'));

const EslParticipantsList = lazy(() => import('features/esl-participants'));
const EslParticipantCreate = lazy(() => import('features/esl-participants/create'));
const EslParticipantEdit = lazy(() => import('features/esl-participants/edit'));

const EceCommunityParticipantsList = lazy(() => import('features/ece-community-participants'));
const EceCommunityParticipantCreate = lazy(() => import('features/ece-community-participants/create'));
const EceCommunityParticipantEdit = lazy(() => import('features/ece-community-participants/edit'));

const JobConnectionStaff = lazy(() => import('features/job-connection-staff'));
const JobConnectionStaffCreate = lazy(() => import('features/job-connection-staff/create'));
const JobConnectionStaffEdit = lazy(() => import('features/job-connection-staff/edit'));
const EquipmentInventoryDetails = lazy(
  () => import('features/equipment-inventory/equipment-inventory-details/equipment-inventory-details')
);
const EquipmentInventories = lazy(() => import('features/equipment-inventory'));
const AuditDetails = lazy(() => import('features/audit/audit-details/audit-details'));
const AuditList = lazy(() => import('features/audit/audit-list/audit-list'));

const EceEmployees = lazy(() => import('features/ece-employee'));
const EceEmployeeCreate = lazy(() => import('features/ece-employee/create'));
const EceEmployeeEdit = lazy(() => import('features/ece-employee/edit'));

const EcollectibleListingsQuickEntry = lazy(
  () => import('features/ecollectible-listings-quick-entry/ecollectible-listings-quick-entry')
);
const LaborHourBudgets = lazy(() => import('features/labor-hour-budget/labor-hour-budget'));
const TargetInvoices = lazy(() => import('features/target-invoice/'));
const TargetReceipts = lazy(() => import('features/target-received/'));
const TargetRotations = lazy(() => import('features/target-rotation/'));

const ChristmasBudgets = lazy(() => import('features/christmas-budgets/christmas-bugdet-entry'));
const ProductionBudgets = lazy(() => import('features/production-budget'));
const ProductionBudgetEntry = lazy(() => import('features/production-budget/production-budget-entry'));

const EslTraining = lazy(() => import('features/esl-training'));
const EslTrainingCreate = lazy(() => import('features/esl-training/create'));
const EslTrainingEdit = lazy(() => import('features/esl-training/edit'));

const EslCitizenship = lazy(() => import('features/esl-citizenship'));
const EslCitizenshipCreate = lazy(() => import('features/esl-citizenship/create'));
const EslCitizenshipEdit = lazy(() => import('features/esl-citizenship/edit'));

const JobConnectionAmpList = lazy(() => import('features/job-connection-amp'));
const JobConnectionAmpCreate = lazy(() => import('features/job-connection-amp/create'));
const JobConnectionAmpEdit = lazy(() => import('features/job-connection-amp/edit'));

const JobConnectionLocationList = lazy(() => import('features/job-connection-location'));
const JobConnectionLocationCreate = lazy(() => import('features/job-connection-location/create'));
const JobConnectionLocationEdit = lazy(() => import('features/job-connection-location/edit'));

const FeedbackLog = lazy(() => import('features/feedback-Log'));
const FeedbackLogCreate = lazy(() => import('features/feedback-Log/create'));
const FeedbackLogEdit = lazy(() => import('features/feedback-Log/edit'));

const HalloweenBudget = lazy(() => import('features/halloween-budgets/halloween-budget-entry'));

const EnterDailyActivityLogCreate = lazy(() => import('features/tasks-enter-daily-activity-log/create'));
const EnterDailyActivityLogEdit = lazy(() => import('features/tasks-enter-daily-activity-log/edit'));
const EcommerceMediaContainerAuditList = lazy(() => import('features/e-commerce-media-container-audit'));
const EcommerceMediaContainerAuditCreate = lazy(() => import('features/e-commerce-media-container-audit/create'));
const EcommerceMediaContainerAuditEdit = lazy(() => import('features/e-commerce-media-container-audit/edit'));
const DonorVehicleSearch = lazy(() => import('features/car-donations-donor-vehicle-search'));
const DonorVehicleSearchCreate = lazy(() => import('features/car-donations-donor-vehicle-search/create'));
const DonorVehicleSearchEdit = lazy(() => import('features/car-donations-donor-vehicle-search/edit'));
const DonationInErrorSearch = lazy(() => import('features/donation-in-error'));
const DonationInErrorCreate = lazy(() => import('features/donation-in-error/create'));
const DonationInErrorEdit = lazy(() => import('features/donation-in-error/edit'));
const FixedAssetEntry = lazy(() => import('features/fixed-assets/fixed-asset-entry'));

const EcommerceContainerList = lazy(() => import('features/e-commerce-container'));
const EcommerceContainerCreate = lazy(() => import('features/e-commerce-container/create'));
const EcommerceContainerEdit = lazy(() => import('features/e-commerce-container/edit'));

const costCenter = lazy(() => import('features/cost-center'));
const FixedAssetsSearch = lazy(() => import('features/fixed-assets-search'));
const FixedAssetsAssetType = lazy(() => import('features/fixed-assets-asset-type'));
const GeneralInventoryItems = lazy(() => import('features/general-inventory-items/general-inventory-items'));
const GeneralInventorySummaryCreate = lazy(() => import('features/general-inventory-summaries/create'));
const GeneralInventorySummaryEdit = lazy(() => import('features/general-inventory-summaries/edit'));
const ChristmasBudgetList = lazy(() => import('features/christmas-budgets/christmas-budget-list'));
const HalloweenBudgetList = lazy(() => import('features/halloween-budgets/halloween-budget-list'));

const HistoricalPreservationList = lazy(() => import('features/historical-preservation'));
const HistoricalPreservationCreate = lazy(() => import('features/historical-preservation/create'));
const HistoricalPreservationEdit = lazy(() => import('features/historical-preservation/edit'));
const HistoricalPreservationView = lazy(() => import('features/historical-preservation/view'));
const ShoplifterSummarySearch = lazy(() => import('features/shoplifter-summary-search'));
const ShoplifterDetails = lazy(() => import('features/shoplifter-details'));
const StaffGoal = lazy(() => import('features/staff-goal'));

const renderPage = (PageComponent: LazyExoticComponent<(props?: any) => JSX.Element>, props?: any) => (
  <Suspense fallback={<></>}>
    <PageComponent {...props} />
  </Suspense>
);

const NoAccessMessage = 'You are not authorized to perform this action';

const AppRouters = () => {
  const {
    isViewable_ProductionBudget,
    isViewEditable_ProductionBudget,
    isSubmittable_ProductionBudget,
    isViewable_DonationBudget,
    isViewEditable_DonationBudget,
    isSubmittable_DonationBudget,
    isViewable_TargetReceipts,
    isViewable_TargetInvoices,
    isViewable_TargetShipment,
    isViewable_StoreMessage,
    isSubmittable_HalloweenBudget,
    isViewEditable_HalloweenBudget,
    isViewable_HalloweenBudget,
    isSubmittable_ChristmasBudget,
    isViewEditable_ChristmasBudget,
    isViewable_ChristmasBudget,
    isViewable_LaborHourBudget,
    isSubmittable_GoodwillCare,
    isViewEditable_GoodwillCare,
    isViewable_GoodwillCare,
    isViewable_ECommerceContainer,
    isViewEditable_ECommerceContainer,
    isSubmittable_ECommerceContainer,
    isViewable_Site,
    isSubmitable_FeedbackLog,
    isViewEditable_FeedbackLog,
    isViewable_FeedbackLog,
    isViewable_FixedAsset,
    isViewEditable_FixedAsset,
    isSubmittable_FixedAsset,
    isViewable_AssetTypes,
    isViewable_BookContainer,
    isViewEditable_BookContainer,
    isSubmittable_BookContainer,
    isViewable_MediaContainer,
    isViewEditable_MediaContainer,
    isSubmittable_MediaContainer,
    isViewEditable_ECollectable,
    isSubmitable_ECollectable,
    isViewable_ECollectable,
    isViewable_CarDonation,
    isSubmitable_CarDonation,
    isViewEditable_CarDonation,

    isViewable_DonationInError,
    isViewEditable_DonationInError,
    isSubmittable_DonationInError,
    isViewable_GeneralInventory,
    isViewEditable_GeneralInventory,
    isSubmittable_GeneralInventory,
    isViewable_GeneralInventoryItemsList,
    isViewable_ECETraining,
    isViewEditable_ECETraining,
    isSubmittable_ECETraining,
    isViewable_ECECommunityParticipant,
    isViewEditable_ECECommunityParticipant,
    isSubmittable_ECECommunityParticipant,
    isViewable_ECEEmployee,
    isViewEditable_ECEEmployee,
    isSubmittable_ECEEmployee,
    isViewEditable_ECEAncillaryServices,
    isViewable_ECEAncillaryServices,
    isSubmittable_ECEAncillaryServices,
    isViewable_ECEClasses,
    isViewEditable_ECEClasses,
    isSubmittable_ECEClasses,
    isViewable_ECELocations,
    isViewEditable_ECELocations,
    isSubmittable_ECELocations,
    isViewable_ECETeachers,
    isViewable_ESLParticipants,
    isViewEditable_ESLParticipants,
    isSubmittable_ESLParticipants,
    isViewable_ESLTraining,
    isViewEditable_ESLTraining,
    isSubmittable_ESLTraining,
    isViewable_ESLCitizenship,
    isViewEditable_ESLCitizenship,
    isSubmittable_ESLCitizenship,
    isViewable_ESLClasses,
    isViewable_ESLInstructors,
    isViewable_ESLLocations,
    isSubmittable_ESLLocations,
    isViewEditable_ESLLocations,
    isViewable_ESLReferralSources,
    isViewable_DonorEntry,
    isViewable_ApplicationEntry,
    isViewable_KaizenScoreEntry,
    isViewable_EquipmentInventory,
    isSubmittable_EquipmentInventory,
    isViewEditable_EquipmentInventory,
    isSubmittable_EmployeeInjuryNotice,
    isViewable_KaizenSuggestion,
    isViewable_DistrictManagerAudit,
    isViewable_CashCount,
    isSubmittable_CashCount,
    isViewEditable_CashCount,
    isViewable_ReserveFund,
    isSubmittable_ReserveFund,
    isViewEditable_ReserveFund,
    isViewable_DepositEntry,
    isSubmittable_DepositEntry,
    isViewEditable_DepositEntry,
    isViewable_CashierVariance,
    isSubmittable_CashierVariance,
    isViewEditable_CashierVariance,
    isViewable_IncidentReport,
    isViewable_HistoricalPreservation,
    isSubmittable_HistoricalPreservation,
    isViewEditable_HistoricalPreservation,
    isSubmittable_DailyActivityLog,
    isViewEditable_DailyActivityLog,
    isSubmitable_EEOData,
    isViewable_LossControlReview,
    isViewEditable_LossControlReview,
    isSubmittable_LossControlReview,
    isViewable_AccidentAnalysis,
    isSubmittable_AccidentAnalysis,
    isViewable_Shoplifter,
    isSubmitable_Shoplifter,
    isViewEditable_Shoplifter,
    isViewable_SafetyAuditReport,
    isSubmittable_SafetyAuditReport,
    isViewEditable_SafetyAuditReport,
    isViewable_JCParticipant,
    isSubmittable_JCParticipant,
    isViewEditable_JCParticipant,
    isViewEditableWithSSN_JCParticipant,
    isViewable_JCLocation,
    isSubmittable_JCLocation,
    isViewEditable_JCLocation,
    isSubmittable_JCStaff,
    isViewable_JCStaff,
    isViewEditable_JCStaff,
    isViewable_JCAmp,
    isSubmittable_JCAmp,
    isViewEditable_JCAmp,
    isViewable_JCEditGoal,
    isSubmittable_JCEditGoal,
    isViewEditable_JCEditGoal,
  } = useAuthRouter();
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<>Welcome to app</>} />
        <Route path="dialog-examples" element={renderPage(DialogExample)} />
        <Route path="text-input-components" element={renderPage(TextInputComponents)} />
        <Route path="table" element={renderPage(TableExample)} />
        <Route path="form-example" element={renderPage(FormExample)} />
        <Route path="page-example" element={renderPage(PageExample)} />
        <Route path="accident-analysis">
          <Route index element={renderPage(LossControlAccidentAnalysisSearch)} />
          <Route path=":id" element={renderPage(AccidentAnalysisDetails)} />
          <Route path="new" element={renderPage(AccidentAnalysisDetails)} />
        </Route>
        <Route path="employment" element={isSubmitable_EEOData ? renderPage(Employment) : NoAccessMessage} />
        <Route path="application" element={isViewable_ApplicationEntry ? renderPage(Application) : NoAccessMessage} />
        <Route path="application">
          <Route index element={isViewable_ApplicationEntry ? renderPage(Application) : NoAccessMessage} />
          <Route path="log" element={isViewable_ApplicationEntry ? renderPage(UpsertApplication) : NoAccessMessage} />
        </Route>
        <Route path="cash-counts">
          <Route path="new" element={isSubmittable_CashCount ? renderPage(CashCountCreate) : NoAccessMessage} />
          <Route path=":cashCountId" element={isViewEditable_CashCount ? renderPage(CashCountView) : NoAccessMessage} />
          <Route index element={isViewable_CashCount ? renderPage(CashCountList) : NoAccessMessage} />
        </Route>
        <Route path="communication-log" element={renderPage(CommunicationLog)} />
        <Route path="store-message" element={isViewable_StoreMessage ? renderPage(StoreMessage) : NoAccessMessage} />
        <Route path="employment-activity-log" element={renderPage(EmploymentActivityLog)} />
        <Route
          path="employment-injury-notice"
          element={isSubmittable_EmployeeInjuryNotice ? renderPage(EmploymentInjuryNotice) : NoAccessMessage}
        />
        <Route path="safety-audit-reports">
          <Route
            path="new"
            element={isSubmittable_SafetyAuditReport ? renderPage(SafetyAuditReport) : NoAccessMessage}
          />
          <Route
            path=":id"
            element={isViewEditable_SafetyAuditReport ? renderPage(SafetyAuditReport) : NoAccessMessage}
          />
          <Route index element={isViewable_SafetyAuditReport ? renderPage(SafetyAuditReportList) : NoAccessMessage} />
        </Route>
        <Route path="incident-report">
          <Route path="new" element={renderPage(IncidentReportCreate)} />
          <Route path=":id" element={isViewable_IncidentReport ? renderPage(IncidentReportEdit) : NoAccessMessage} />
          <Route index element={isViewable_IncidentReport ? renderPage(IncidentReportCreate) : NoAccessMessage} />
        </Route>

        <Route path="cashier-variances">
          <Route
            path="new"
            element={isSubmittable_CashierVariance ? renderPage(CashierVarianceCreate) : NoAccessMessage}
          />
          <Route
            path=":id"
            element={isViewEditable_CashierVariance ? renderPage(CashierVarianceEdit) : NoAccessMessage}
          />
          <Route index element={isViewable_CashierVariance ? renderPage(CashierVarianceList) : NoAccessMessage} />
        </Route>
        <Route path="care">
          <Route path=":id" element={isViewEditable_GoodwillCare ? renderPage(CareDetails) : NoAccessMessage} />
          <Route index element={isViewable_GoodwillCare ? renderPage(CareDetails) : NoAccessMessage} />
        </Route>
        <Route path="reserve-fund-logs">
          <Route path="new" element={isSubmittable_ReserveFund ? renderPage(ReserveFundLogCreate) : NoAccessMessage} />
          <Route path=":id" element={isViewEditable_ReserveFund ? renderPage(ReserveFundLogEdit) : NoAccessMessage} />
          <Route index element={isViewable_ReserveFund ? renderPage(ReserveFundLogList) : NoAccessMessage} />
        </Route>
        <Route path="deposits">
          <Route path="new" element={isSubmittable_DepositEntry ? renderPage(Deposit) : NoAccessMessage} />
          <Route path=":depositId" element={isViewEditable_DepositEntry ? renderPage(Deposit) : NoAccessMessage} />
          <Route index element={isViewable_DepositEntry ? renderPage(DepositList) : NoAccessMessage} />
        </Route>
        <Route path="donor" element={isViewable_DonorEntry ? renderPage(Donor) : NoAccessMessage} />
        <Route path="loss-control-reviews">
          <Route
            path=":id"
            element={isViewEditable_LossControlReview ? renderPage(LossControlReviewEdit) : NoAccessMessage}
          />
          <Route
            path="new"
            element={isSubmittable_LossControlReview ? renderPage(LossControlReviewCreate) : NoAccessMessage}
          />
          <Route index element={isViewable_LossControlReview ? renderPage(LossControlReviewList) : NoAccessMessage} />
        </Route>
        <Route path="dm-audit">
          <Route path=":id" element={isViewable_DistrictManagerAudit ? renderPage(DMAuditDetails) : NoAccessMessage} />
          <Route index element={isViewable_DistrictManagerAudit ? renderPage(DMAuditDetails) : NoAccessMessage} />
        </Route>
        <Route path="kaizen-suggestion">
          <Route path=":id" element={isViewable_KaizenSuggestion ? renderPage(KaizenDetails) : NoAccessMessage} />
          <Route index element={isViewable_KaizenSuggestion ? renderPage(KaizenDetails) : NoAccessMessage} />
        </Route>
        <Route path="donation-budget-list">
          <Route
            path=":id"
            element={isViewEditable_DonationBudget ? renderPage(DonationBudgetEntry) : NoAccessMessage}
          />
          <Route index element={isViewable_DonationBudget ? renderPage(DonationBudgetList) : NoAccessMessage} />
        </Route>
        <Route
          path="donation-budget-entry"
          element={isSubmittable_DonationBudget ? renderPage(DonationBudgetEntry) : NoAccessMessage}
        />
        <Route path="sites">
          <Route index element={isViewable_Site ? renderPage(Sites) : NoAccessMessage} />
        </Route>
        <Route
          path="esl-referral-source"
          element={isViewable_ESLReferralSources ? renderPage(ReferralSource) : NoAccessMessage}
        />
        <Route path="esl-instructors" element={isViewable_ESLInstructors ? renderPage(Instructors) : NoAccessMessage} />
        <Route path="esl-location">
          <Route
            path="list"
            element={isViewable_ESLLocations ? renderPage(LocationList, { type: 'ESL' }) : NoAccessMessage}
          />
          <Route
            path=":id"
            element={isViewEditable_ESLLocations ? renderPage(LocationDetails, { type: 'ESL' }) : NoAccessMessage}
          />
          <Route
            index
            element={isSubmittable_ESLLocations ? renderPage(LocationDetails, { type: 'ESL' }) : NoAccessMessage}
          />
        </Route>
        <Route path="ece-location">
          <Route
            path="list"
            element={isViewable_ECELocations ? renderPage(LocationList, { type: 'ECE' }) : NoAccessMessage}
          />
          <Route
            path=":id"
            element={
              isSubmittable_ECELocations || isViewEditable_ECELocations
                ? renderPage(LocationDetails, { type: 'ECE' })
                : NoAccessMessage
            }
          />
          <Route
            index
            element={isViewable_ECELocations ? renderPage(LocationDetails, { type: 'ECE' }) : NoAccessMessage}
          />
        </Route>
        <Route path="esl-participants">
          <Route
            path="list"
            element={isViewable_ESLParticipants ? renderPage(EslParticipantsList, { type: 'ESL' }) : NoAccessMessage}
          />
          <Route
            path="new"
            element={
              isSubmittable_ESLParticipants ? renderPage(EslParticipantCreate, { type: 'ESL' }) : NoAccessMessage
            }
          />
          <Route
            path=":id"
            element={isViewEditable_ESLParticipants ? renderPage(EslParticipantEdit, { type: 'ESL' }) : NoAccessMessage}
          />
          <Route
            index
            element={isViewable_ESLParticipants ? renderPage(EslParticipantsList, { type: 'ESL' }) : NoAccessMessage}
          />
        </Route>
        <Route path="training-search">
          <Route path=":id" element={isViewEditable_ECETraining ? renderPage(TrainingEntry) : NoAccessMessage} />
          <Route index element={isViewable_ECETraining ? renderPage(TrainingSearch) : NoAccessMessage} />
        </Route>
        <Route
          path="training-entry"
          element={isSubmittable_ECETraining ? renderPage(TrainingEntry) : NoAccessMessage}
        />
        <Route path="ece-ancillary-services">
          <Route
            path="list"
            element={isViewable_ECEAncillaryServices ? renderPage(AncillaryServices, { type: 'ECE' }) : NoAccessMessage}
          />
          <Route
            path="new"
            element={
              isSubmittable_ECEAncillaryServices
                ? renderPage(AncillaryServicesCreate, { type: 'ECE' })
                : NoAccessMessage
            }
          />
          <Route
            path=":id"
            element={
              isViewEditable_ECEAncillaryServices ? renderPage(AncillaryServicesEdit, { type: 'ECE' }) : NoAccessMessage
            }
          />
          <Route
            index
            element={isViewable_ECEAncillaryServices ? renderPage(AncillaryServices, { type: 'ECE' }) : NoAccessMessage}
          />
        </Route>
        <Route path="ece-class" element={isViewable_ECEClasses ? renderPage(EceClass) : NoAccessMessage} />
        <Route path="esl-class-type" element={isViewable_ESLClasses ? renderPage(EslClassType) : NoAccessMessage} />
        <Route path="ece-teachers" element={isViewable_ECETeachers ? renderPage(EceTeachers) : NoAccessMessage} />
        <Route path="ece-community-participants">
          <Route
            path="list"
            element={
              isViewable_ECECommunityParticipant
                ? renderPage(EceCommunityParticipantsList, { type: 'ECE' })
                : NoAccessMessage
            }
          />
          <Route
            path="new"
            element={
              isSubmittable_ECECommunityParticipant
                ? renderPage(EceCommunityParticipantCreate, { type: 'ECE' })
                : NoAccessMessage
            }
          />
          <Route
            path=":id"
            element={
              isViewEditable_ECECommunityParticipant
                ? renderPage(EceCommunityParticipantEdit, { type: 'ECE' })
                : NoAccessMessage
            }
          />
          <Route
            index
            element={
              isViewEditable_ECECommunityParticipant
                ? renderPage(EceCommunityParticipantsList, { type: 'ECE' })
                : NoAccessMessage
            }
          />
        </Route>
        <Route path="ece-employees">
          <Route
            path="new"
            element={isSubmittable_ECEEmployee ? renderPage(EceEmployeeCreate, { type: 'ECE' }) : NoAccessMessage}
          />
          <Route
            path=":id"
            element={isViewEditable_ECEEmployee ? renderPage(EceEmployeeEdit, { type: 'ECE' }) : NoAccessMessage}
          />
          <Route index element={isViewable_ECEEmployee ? renderPage(EceEmployees, { type: 'ECE' }) : NoAccessMessage} />
        </Route>
        <Route path="job-connection-participants">
          <Route
            path="list"
            element={
              isViewable_JCParticipant
                ? renderPage(JobConnectionParticipants, { type: 'jobConnection' })
                : NoAccessMessage
            }
          />
          <Route
            path="new"
            element={
              isSubmittable_JCParticipant
                ? renderPage(JobConnectionParticipantCreate, { type: 'jobConnection' })
                : NoAccessMessage
            }
          />
          <Route
            path=":id"
            element={
              isViewEditable_JCParticipant && !isViewEditableWithSSN_JCParticipant
                ? renderPage(JobConnectionParticipantEdit, { type: 'jobConnection' })
                : NoAccessMessage
            }
          />
          <Route
            path="d/:id"
            element={
              isViewEditableWithSSN_JCParticipant
                ? renderPage(JobConnectionParticipantEdit, { type: 'jobConnection' })
                : NoAccessMessage
            }
          />
          <Route
            index
            element={
              isViewable_JCParticipant
                ? renderPage(JobConnectionParticipants, { type: 'jobConnection' })
                : NoAccessMessage
            }
          />
        </Route>
        <Route path="job-connection-staff">
          <Route path="list" element={isViewable_JCStaff ? renderPage(JobConnectionStaff) : NoAccessMessage} />
          <Route path="new" element={isSubmittable_JCStaff ? renderPage(JobConnectionStaffCreate) : NoAccessMessage} />
          <Route path=":id" element={isViewEditable_JCStaff ? renderPage(JobConnectionStaffEdit) : NoAccessMessage} />
          <Route index element={isViewable_JCStaff ? renderPage(JobConnectionStaff) : NoAccessMessage} />
        </Route>
        <Route
          path="equipment-inventory-list"
          element={isViewable_EquipmentInventory ? renderPage(EquipmentInventories) : NoAccessMessage}
        />
        <Route path="equipment-inventory">
          <Route
            path=":id"
            element={isViewEditable_EquipmentInventory ? renderPage(EquipmentInventoryDetails) : NoAccessMessage}
          />
          <Route
            index
            element={isSubmittable_EquipmentInventory ? renderPage(EquipmentInventoryDetails) : NoAccessMessage}
          />
        </Route>
        <Route path="esl-training">
          <Route path="new" element={isSubmittable_ESLTraining ? renderPage(EslTrainingCreate) : NoAccessMessage} />
          <Route path=":id" element={isViewEditable_ESLTraining ? renderPage(EslTrainingEdit) : NoAccessMessage} />
          <Route index element={isViewable_ESLTraining ? renderPage(EslTraining) : NoAccessMessage} />
        </Route>
        <Route path="kaizen-score">
          <Route path="list" element={isViewable_KaizenScoreEntry ? renderPage(AuditList) : NoAccessMessage} />
          <Route path=":id" element={isViewable_KaizenScoreEntry ? renderPage(AuditDetails) : NoAccessMessage} />
          <Route index element={isViewable_KaizenScoreEntry ? renderPage(AuditDetails) : NoAccessMessage} />
        </Route>
        <Route
          path="target-invoice"
          element={isViewable_TargetInvoices ? renderPage(TargetInvoices) : NoAccessMessage}
        />
        <Route
          path="target-received"
          element={isViewable_TargetReceipts ? renderPage(TargetReceipts) : NoAccessMessage}
        />
        <Route
          path="target-rotation"
          element={isViewable_TargetShipment ? renderPage(TargetRotations) : NoAccessMessage}
        />
        <Route path="christmas-budgets">
          <Route path=":id" element={isViewEditable_ChristmasBudget ? renderPage(ChristmasBudgets) : NoAccessMessage} />
          <Route index element={isSubmittable_ChristmasBudget ? renderPage(ChristmasBudgets) : NoAccessMessage} />
        </Route>
        <Route path="production-budgets">
          <Route
            path=":id"
            element={isViewEditable_ProductionBudget ? renderPage(ProductionBudgetEntry) : NoAccessMessage}
          />
          <Route
            path="new"
            element={isSubmittable_ProductionBudget ? renderPage(ProductionBudgetEntry) : NoAccessMessage}
          />
          <Route index element={isViewable_ProductionBudget ? renderPage(ProductionBudgets) : NoAccessMessage} />
        </Route>

        <Route path="esl-citizenship">
          <Route path="list" element={isViewable_ESLCitizenship ? renderPage(EslCitizenship) : NoAccessMessage} />
          <Route
            path="new"
            element={isSubmittable_ESLCitizenship ? renderPage(EslCitizenshipCreate, { type: 'ESL' }) : NoAccessMessage}
          />
          <Route
            path=":id"
            element={isViewEditable_ESLCitizenship ? renderPage(EslCitizenshipEdit, { type: 'ESL' }) : NoAccessMessage}
          />
          <Route index element={isViewable_ESLCitizenship ? renderPage(EslCitizenship) : NoAccessMessage} />
        </Route>
        <Route path="job-connection-amp">
          <Route path="list" element={isViewable_JCAmp ? renderPage(JobConnectionAmpList) : NoAccessMessage} />
          <Route
            path="new"
            element={isSubmittable_JCAmp ? renderPage(JobConnectionAmpCreate, { type: 'ESL' }) : NoAccessMessage}
          />
          <Route
            path=":id"
            element={isViewEditable_JCAmp ? renderPage(JobConnectionAmpEdit, { type: 'ESL' }) : NoAccessMessage}
          />
          <Route index element={renderPage(JobConnectionAmpList)} />
        </Route>

        <Route path="job-connection-location">
          <Route
            path="list"
            element={isViewable_JCLocation ? renderPage(JobConnectionLocationList) : NoAccessMessage}
          />
          <Route
            path="new"
            element={isSubmittable_JCLocation ? renderPage(JobConnectionLocationCreate) : NoAccessMessage}
          />
          <Route
            path=":id"
            element={isViewEditable_JCLocation ? renderPage(JobConnectionLocationEdit) : NoAccessMessage}
          />
          <Route index element={isViewable_JCLocation ? renderPage(JobConnectionLocationList) : NoAccessMessage} />
        </Route>
        <Route path="feedback-log">
          <Route path="list" element={isViewable_FeedbackLog ? renderPage(FeedbackLog) : NoAccessMessage} />
          <Route path=":id" element={isViewEditable_FeedbackLog ? renderPage(FeedbackLogEdit) : NoAccessMessage} />
          <Route path="new" element={isSubmitable_FeedbackLog ? renderPage(FeedbackLogCreate) : NoAccessMessage} />
          <Route index element={isSubmitable_FeedbackLog ? renderPage(FeedbackLogCreate) : NoAccessMessage} />
        </Route>
        <Route path="halloween-budget">
          <Route path=":id" element={isViewEditable_HalloweenBudget ? renderPage(HalloweenBudget) : NoAccessMessage} />
          <Route index element={isSubmittable_HalloweenBudget ? renderPage(HalloweenBudget) : NoAccessMessage} />
        </Route>
        <Route path="labor-hour-budgets">
          <Route path="list" element={isViewable_LaborHourBudget ? renderPage(LaborHourBudgets) : NoAccessMessage} />
        </Route>
        <Route path="e-collectable-listings-entry">
          <Route
            index
            element={isViewable_ECollectable ? renderPage(EcollectibleListingsQuickEntry) : NoAccessMessage}
          />
        </Route>
        <Route path="enter-daily-activity-log">
          <Route
            path=":id"
            element={isViewEditable_DailyActivityLog ? renderPage(EnterDailyActivityLogEdit) : NoAccessMessage}
          />
          <Route
            path="new"
            element={isSubmittable_DailyActivityLog ? renderPage(EnterDailyActivityLogCreate) : NoAccessMessage}
          />
          <Route
            index
            element={isSubmittable_DailyActivityLog ? renderPage(EnterDailyActivityLogCreate) : NoAccessMessage}
          />
        </Route>
        <Route path="e-commerce-media-container-audit">
          <Route
            path=":id"
            element={
              isViewable_MediaContainer
                ? renderPage(EcommerceMediaContainerAuditEdit, { containerType: 'Media' })
                : NoAccessMessage
            }
          />
          <Route
            path="new"
            element={
              isSubmittable_MediaContainer
                ? renderPage(EcommerceMediaContainerAuditCreate, { containerType: 'Media' })
                : NoAccessMessage
            }
          />
          <Route
            index
            element={
              isViewEditable_MediaContainer
                ? renderPage(EcommerceMediaContainerAuditList, { containerType: 'Media' })
                : NoAccessMessage
            }
          />
        </Route>
        <Route path="e-commerce-book-container-audit">
          <Route
            path=":id"
            element={
              isViewEditable_BookContainer
                ? renderPage(EcommerceMediaContainerAuditEdit, { containerType: 'Book' })
                : NoAccessMessage
            }
          />
          <Route
            path="new"
            element={
              isSubmittable_BookContainer
                ? renderPage(EcommerceMediaContainerAuditCreate, { containerType: 'Book' })
                : NoAccessMessage
            }
          />
          <Route
            index
            element={
              isViewable_BookContainer
                ? renderPage(EcommerceMediaContainerAuditList, { containerType: 'Book' })
                : NoAccessMessage
            }
          />
        </Route>
        <Route path="car-donations-donor-vehicle-search">
          <Route
            path=":id"
            element={isViewEditable_CarDonation ? renderPage(DonorVehicleSearchEdit) : NoAccessMessage}
          />
          <Route
            path="new"
            element={isSubmitable_CarDonation ? renderPage(DonorVehicleSearchCreate) : NoAccessMessage}
          />
          <Route index element={isViewable_CarDonation ? renderPage(DonorVehicleSearch) : NoAccessMessage} />
        </Route>
        <Route path="donation-in-error">
          <Route
            path=":id"
            element={isViewEditable_DonationInError ? renderPage(DonationInErrorEdit) : NoAccessMessage}
          />
          <Route
            path="new"
            element={isSubmittable_DonationInError ? renderPage(DonationInErrorCreate) : NoAccessMessage}
          />
          <Route index element={isViewable_DonationInError ? renderPage(DonationInErrorSearch) : NoAccessMessage} />
        </Route>
        <Route path="e-commerce-container">
          <Route
            path=":id"
            element={isViewEditable_ECommerceContainer ? renderPage(EcommerceContainerEdit) : NoAccessMessage}
          />
          <Route
            path="new"
            element={isSubmittable_ECommerceContainer ? renderPage(EcommerceContainerCreate) : NoAccessMessage}
          />
          <Route index element={isViewable_ECommerceContainer ? renderPage(EcommerceContainerList) : NoAccessMessage} />
        </Route>
        <Route path="cost-center">
          <Route index element={renderPage(costCenter)} />
        </Route>
        <Route path="fixed-assets-search">
          <Route index element={isViewable_FixedAsset ? renderPage(FixedAssetsSearch) : NoAccessMessage} />
        </Route>
        <Route path="fixed-assets">
          <Route path=":id" element={isViewEditable_FixedAsset ? renderPage(FixedAssetEntry) : NoAccessMessage} />
          <Route index element={isSubmittable_FixedAsset ? renderPage(FixedAssetEntry) : NoAccessMessage} />
        </Route>
        <Route
          path="fixed-assets-asset-type"
          element={isViewable_AssetTypes ? renderPage(FixedAssetsAssetType) : NoAccessMessage}
        />
        <Route
          path="general-inventory-items"
          element={isViewable_GeneralInventoryItemsList ? renderPage(GeneralInventoryItems) : NoAccessMessage}
        />
        <Route path="general-inventory-summaries">
          <Route
            path=":id"
            element={isViewEditable_GeneralInventory ? renderPage(GeneralInventorySummaryEdit) : NoAccessMessage}
          />
          <Route
            path="new"
            element={isSubmittable_GeneralInventory ? renderPage(GeneralInventorySummaryCreate) : NoAccessMessage}
          />
          <Route
            index
            element={isSubmittable_GeneralInventory ? renderPage(GeneralInventorySummaryCreate) : NoAccessMessage}
          />
        </Route>
        <Route
          path="christmas-budgets-list"
          element={isViewable_ChristmasBudget ? renderPage(ChristmasBudgetList) : NoAccessMessage}
        />
        <Route
          path="halloween-budget-list"
          element={isViewable_HalloweenBudget ? renderPage(HalloweenBudgetList) : NoAccessMessage}
        />

        <Route path="historical-preservation">
          <Route
            path="new"
            element={isSubmittable_HistoricalPreservation ? renderPage(HistoricalPreservationCreate) : NoAccessMessage}
          />
          <Route
            path="view/:id"
            element={isViewable_HistoricalPreservation ? renderPage(HistoricalPreservationView) : NoAccessMessage}
          />
          <Route
            path="edit/:id"
            element={isViewEditable_HistoricalPreservation ? renderPage(HistoricalPreservationEdit) : NoAccessMessage}
          />
          <Route
            index
            element={isViewable_HistoricalPreservation ? renderPage(HistoricalPreservationList) : NoAccessMessage}
          />
        </Route>
        <Route path="staff-goal">
          <Route index element={isViewEditable_JCEditGoal ? renderPage(StaffGoal) : NoAccessMessage} />
        </Route>

        <Route
          path="shoplifter-summary"
          element={isViewable_Shoplifter ? renderPage(ShoplifterSummarySearch) : NoAccessMessage}
        />
        <Route path="shoplifter">
          <Route path=":id" element={isViewEditable_Shoplifter ? renderPage(ShoplifterDetails) : NoAccessMessage} />
          <Route index element={isSubmitable_Shoplifter ? renderPage(ShoplifterDetails) : NoAccessMessage} />
        </Route>
      </Route>
    </Routes>
  );
};

export default AppRouters;
