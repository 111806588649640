import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const SubmitDialog = ({ children, title, show, onCancel, onDialogClose, fullWidth = false, ...ortherProps }: any) => {
  const handleClose = () => {
    if (onCancel) {
      onCancel();
    } else {
      onDialogClose({
        success: false,
      });
    }
  };

  return (
    <Dialog open={show} onClose={handleClose} fullWidth={fullWidth} {...ortherProps}>
      <DialogTitle sx={{ fontSize: '1.325rem' }}>{title}</DialogTitle>
      <DialogContent>{children ?? <DialogContentText>{children}</DialogContentText>}</DialogContent>
    </Dialog>
  );
};

export default SubmitDialog;
