import { createContext, useContext } from 'react';
import { EmployeeViewModel, StoreViewModel } from 'shared/api/generatedApi';

export type GlobalContent = {
  employeeList: EmployeeViewModel[];
  setEmployeeList: React.Dispatch<React.SetStateAction<EmployeeViewModel[]>>;
  stores: StoreViewModel[];
  setStores: React.Dispatch<React.SetStateAction<StoreViewModel[]>>;
  salvageStores: StoreViewModel[];
  setSalvageStores: React.Dispatch<React.SetStateAction<StoreViewModel[]>>;
  greStores: StoreViewModel[];
  setGreStores: React.Dispatch<React.SetStateAction<StoreViewModel[]>>;
  fixedAssetsStores: StoreViewModel[];
  setFixedAssetsStores: React.Dispatch<React.SetStateAction<StoreViewModel[]>>;
  lossControlStores: StoreViewModel[];
  setLossControlStores: React.Dispatch<React.SetStateAction<StoreViewModel[]>>;
  kaizenStores: StoreViewModel[];
  setKaizenStores: React.Dispatch<React.SetStateAction<StoreViewModel[]>>;
  onBack: (backPath?: string) => void;
  userName: string;
  userDisplayName: string;
  firstName: string;
  lastName: string;
  rest: any;
  errors: string[];
  setErrors: React.Dispatch<React.SetStateAction<string[]>>;
};

export const AppGlobalContext = createContext<GlobalContent>({
  onBack: (backPath = '') => {},
  employeeList: [],
  setEmployeeList: () => {},
  stores: [],
  setStores: () => {},
  salvageStores: [],
  setSalvageStores: () => {},
  greStores: [],
  setGreStores: () => {},
  fixedAssetsStores: [],
  setFixedAssetsStores: () => {},
  lossControlStores: [],
  setLossControlStores: () => {},
  kaizenStores: [],
  setKaizenStores: () => {},
  userName: '',
  userDisplayName: '',
  firstName: '',
  lastName: '',
  rest: {},
  errors: [],
  setErrors: () => {},
});

export const useGlobalContext = () => useContext(AppGlobalContext);
