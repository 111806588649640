import { useRef, useState, useCallback } from 'react';
import { Formik, FormikHelpers } from 'formik';
import Webcam from 'react-webcam';
import { Alert, AlertColor, Box, Button, CircularProgress, Grid, Snackbar } from '@mui/material';

import { photoClient } from 'shared/api';
import SubmitDialog from 'shared/components/dialogs/SubmitDialog';
import { isMobile } from 'shared/helpers/common';
import { format } from 'date-fns';

const FACING_MODE_USER = 'user';
const FACING_MODE_ENVIRONMENT = 'environment';
const strBase64Img = 'base64,';

interface IAlert {
  type: AlertColor;
  message: string;
}

const CameraStAloneModal = ({ title, onDialogClose, ...rest }: any) => {
  const webcamRef = useRef<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [facingMode, setFacingMode] = useState<string>(FACING_MODE_ENVIRONMENT);
  const [openAlert, setOpenAlert] = useState(false);
  const [alert, setAlert] = useState<IAlert>({
    type: 'success',
    message: 'Image uploaded successfully!',
  });
  const isMobileDevice = isMobile.any();

  const handleClose = useCallback((event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  }, []);

  const handleSubmit = useCallback(async (values: any, formikHelpers: FormikHelpers<any>) => {
    setIsSubmitting(true);

    try {
      await photoClient.uploadSharePoint(
        `${format(new Date(), 'yyyyMMddHHmmss')}.jpg`,
        values.imgSrc.split(strBase64Img)[1]
      );
      formikHelpers.resetForm();
      setIsSubmitting(false);
      setOpenAlert(true);
      setAlert({
        type: 'success',
        message: 'Image uploaded successfully!',
      });
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
    }

    formikHelpers.setSubmitting(false);
  }, []);

  const handleUserMedia = () => {
    setIsLoading(false);
  };

  const handleUserMediaError = () => {
    setOpenAlert(true);
    setAlert({
      type: 'error',
      message: 'Your camera is not working. Please try switching to another camera.',
    });
  };

  return (
    <SubmitDialog show title={title} onDialogClose={onDialogClose} fullWidth={true} maxWidth="xl">
      <Formik initialValues={{ imgSrc: undefined }} onSubmit={handleSubmit}>
        {({ values, handleSubmit, setFieldValue }) => (
          <form data-testid="camera-modal" onSubmit={handleSubmit}>
            <Grid container direction="column" spacing={2} alignItems="center" justifyContent="center">
              {isMobileDevice ? (
                <>
                  <Grid item>
                    <Button
                      type="button"
                      onClick={() =>
                        setFacingMode((prevState) =>
                          prevState === FACING_MODE_USER ? FACING_MODE_ENVIRONMENT : FACING_MODE_USER
                        )
                      }
                    >
                      Switch camera
                    </Button>
                  </Grid>
                  <Grid item>
                    <Webcam
                      audio={false}
                      ref={webcamRef}
                      screenshotFormat="image/jpeg"
                      videoConstraints={{ facingMode: facingMode, height: 1920, width: 1440 }}
                      onUserMedia={handleUserMedia}
                      onUserMediaError={handleUserMediaError}
                      forceScreenshotSourceSize
                      width="100%"
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item>
                    <Webcam
                      audio={false}
                      ref={webcamRef}
                      screenshotFormat="image/jpeg"
                      videoConstraints={{ height: 1920, width: 1440 }}
                      onUserMedia={handleUserMedia}
                      forceScreenshotSourceSize
                      height="480"
                      width="640"
                    />
                  </Grid>
                </>
              )}
              {(isLoading || isSubmitting) && (
                <Box sx={{ display: 'flex', justifyContent: 'center' }} mt={4} mb={4}>
                  <CircularProgress />
                </Box>
              )}
              <Grid item container direction="row" spacing={2} alignItems="center" justifyContent="center">
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    type="button"
                    onClick={() => {
                      setFieldValue('imgSrc', webcamRef.current.getScreenshot());
                    }}
                    disabled={isLoading || isSubmitting}
                  >
                    Capture photo
                  </Button>
                </Grid>
              </Grid>
              <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={openAlert}
                autoHideDuration={6000}
                onClose={handleClose}
                key={'bottom-center'}
              >
                <Alert onClose={handleClose} severity={alert.type} sx={{ width: '100%' }}>
                  {alert.message}
                </Alert>
              </Snackbar>
              <Grid item>{values.imgSrc && <img src={values.imgSrc} alt="cameraImg" width="100%" />}</Grid>
            </Grid>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '12px',
              }}
              mt={4}
              mb={4}
            >
              <Button type="submit" disabled={isLoading || isSubmitting}>
                Upload
              </Button>
              <Button onClick={onDialogClose}>Cancel</Button>
            </Box>
          </form>
        )}
      </Formik>
    </SubmitDialog>
  );
};

export default CameraStAloneModal;
