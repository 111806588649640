import * as React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import AppHeader from './AppHeader';
import SideBar from './sidebar';
import { sidebarWidth, toolbarHeight } from 'shared/constants/appSettings';
import MenuItemList from './MenuItemList';
import { menuGroupItems } from 'app/menuItems';
import { AppGlobalContext } from 'shared/hooks/useGlobalContext';
import { EmployeeViewModel, StoreViewModel } from 'shared/api/generatedApi';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from 'auth/authConfig';
import { callMsGraph } from 'auth/graph';
import { useLocation } from 'react-router-dom';
import { Snackbar, Alert } from '@mui/material';
import { initAxios } from 'shared/api/http';
import jwt_decode from 'jwt-decode';

export const AppContainer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const [isSidebarOpen, setSidebarOpen] = React.useState(true);
  const [menuGroup, setMenuGroup] = React.useState<any>('');
  const [menuItem, setMenuItem] = React.useState<any>('');
  const [employeeList, setEmployeeList] = React.useState<EmployeeViewModel[]>([]);
  const [stores, setStores] = React.useState<StoreViewModel[]>([]);
  const [salvageStores, setSalvageStores] = React.useState<StoreViewModel[]>([]);
  const [greStores, setGreStores] = React.useState<StoreViewModel[]>([]);
  const [fixedAssetsStores, setFixedAssetsStores] = React.useState<StoreViewModel[]>([]);
  const [lossControlStores, setLossControlStores] = React.useState<StoreViewModel[]>([]);
  const [kaizenStores, setKaizenStores] = React.useState<StoreViewModel[]>([]);
  const { instance, accounts } = useMsal();
  const [graphData, setGraphData] = React.useState({} as any);
  const [errors, setErrors] = React.useState<string[]>([]);
  const [account, setAccount] = React.useState<IAccountInfo>();

  const handleClose = (error: string) => {
    setErrors(errors.filter((x) => x !== error));
  };

  React.useEffect(() => {
    initAxios(setErrors);
  }, []);

  React.useEffect(() => {
    menuGroupItems.forEach((group) => {
      if (`#${group.id}` === location.hash) {
        setMenuItem('');
        setMenuGroup(group);
      } else {
        const item = group.items.find((item) => window.location.pathname.startsWith(item.path));
        if (item) {
          setMenuGroup(group);

          setTimeout(() => {
            setMenuItem(item);
          });
        }
      }
    });

    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        callMsGraph(response.accessToken).then((response) => setGraphData(response));
        serializeAccount(response.accessToken);
      });
  }, [accounts, instance, location]);

  const onBack = (backPath = '') => {
    if (backPath) {
      navigate(backPath);
      return;
    }

    const group = menuGroupItems.find((group) => {
      const foundItem = group.items.find((item) => window.location.pathname.startsWith(item.path));
      return foundItem;
    });
    const menuGroupId = group?.id || menuGroupItems[0].id;
    navigate(`/#${menuGroupId}`);
  };

  const serializeAccount = (accessToken: string) => {
    const payload = jwt_decode(accessToken) as any;
    const account = {
      firstName: payload.given_name,
      lastName: payload.family_name,
    } as IAccountInfo;
    setAccount(account);
  };

  return (
    <>
      <AppGlobalContext.Provider
        value={{
          onBack,
          employeeList,
          setEmployeeList,
          stores,
          setStores,
          salvageStores,
          setSalvageStores,
          greStores,
          setGreStores,
          fixedAssetsStores,
          setFixedAssetsStores,
          lossControlStores,
          setLossControlStores,
          kaizenStores,
          setKaizenStores,
          userName: graphData ? graphData.mail : '',
          userDisplayName: graphData ? graphData.displayName : '',
          firstName: account?.firstName || '',
          lastName: account?.lastName || '',
          rest: graphData,
          errors,
          setErrors,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            maxWidth: '100%',
            paddingTop: `${toolbarHeight}px`,
            [theme.breakpoints.up('lg')]: {
              paddingLeft: `${sidebarWidth + 7}px`,
            },
            bgcolor: 'grey.100',
            minHeight: '100vh',
          }}
        >
          <Box
            component={Paper}
            sx={{
              display: 'flex',
              flex: '1 1 auto',
              flexDirection: 'column',
              width: '100%',
              padding: '17px 17px 17px 18px',
              margin: '18px 14px',
            }}
          >
            {menuGroup && !menuItem && <MenuItemList menuGroup={menuGroup} onClick={setMenuItem} />}
            {menuItem && <Outlet />}
          </Box>
        </Box>
        <AppHeader onSidebarOpen={() => setSidebarOpen(true)} />
        <SideBar
          onClose={() => setSidebarOpen(false)}
          open={isSidebarOpen}
          menuGroup={menuGroup}
          onSelectMenuGroup={(group: any) => {
            navigate(`/#${group.id}`);
          }}
        />
        {errors.map((error) => (
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={Boolean(error)}
            autoHideDuration={3000}
            onClose={() => handleClose(error)}
          >
            <Alert onClose={() => handleClose(error)} severity="error" sx={{ width: '100%' }}>
              {error}
            </Alert>
          </Snackbar>
        ))}
      </AppGlobalContext.Provider>
    </>
  );
};

interface IAccountInfo {
  firstName: string;
  lastName: string;
}
