import SearchIcon from '@mui/icons-material/Search';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'assets/styles/menu.scss';
import { Helmet } from 'react-helmet';
import useAuthControl from 'auth/authControl/useAuthControl';

const defaultUnauthorizeItem = {
  path: '#',
  title: 'You do not have access to view any forms in this category',
};

const MenuItemList = ({ menuGroup, onClick }: any) => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [menuItems, setMenuItems] = useState<any[]>([]);
  const { isValidRole } = useAuthControl();

  useEffect(() => {
    const authorizedMenuItems = (menuGroup.items as any[]).filter((item) =>
      isValidRole(item.allowedRoles || ([] as any[]))
    );

    let items = authorizedMenuItems;
    const authorizeItemsLength = authorizedMenuItems.length;
    const isEmptyForm = authorizeItemsLength === 0;
    if (isEmptyForm) {
      items = [defaultUnauthorizeItem];
    } else {
      items = items.filter((x) => x.title.toLowerCase().includes(searchText.toLowerCase()));
    }

    setMenuItems(items);
  }, [menuGroup, searchText, isValidRole]);

  return (
    <Card elevation={0} className="Menu">
      <CardHeader
        className="titleContainer"
        title={
          <Typography variant="h1" sx={{ paddingTop: '3px' }}>
            Forms: {menuGroup.title}
          </Typography>
        }
        action={
          <TextField
            onChange={(e) => setSearchText(e.target.value)}
            variant="outlined"
            placeholder="Search for document..."
            size="small"
            sx={{ width: 305 }}
            InputProps={{
              className: 'searchInput',
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        }
      />

      <Divider />

      <CardContent className="cardContent">
        <TableContainer>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow className="tbRowMenu">
                <TableCell className="tbHeadCellMenu">Form Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {menuItems
                .sort((a, b) => a.title.localeCompare(b.title))
                .map((row: any) => (
                  <TableRow
                    className="tbRowMenu"
                    hover
                    onClick={() => {
                      navigate(row.path);
                      onClick(row);
                    }}
                    key={row.path}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      '&:hover': { cursor: 'pointer' },
                    }}
                  >
                    <TableCell className="tbCellMenu" component="th" scope="row">
                      {row.title}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>

      <Helmet>
        <title>{menuGroup.title}</title>
      </Helmet>
    </Card>
  );
};

export default MenuItemList;
