import { BrowserRouter } from 'react-router-dom';
import AppRouters from 'app/AppRouters';
import { DialogsRenderer } from 'features/dialog';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import React from 'react';
import { appConfigs } from 'shared/constants/configs';
import { apiInfoClient, cancelToken } from 'shared/api';
import themes from 'themes';
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import { BrowserAuthError, InteractionRequiredAuthError, InteractionType } from '@azure/msal-browser';
import { loginRequest, tokenRequest } from 'auth/authConfig';
import 'assets/styles/common.scss';

function ErrorComponent({ error }: any) {
  return <p>An Error Occurred: {error}</p>;
}

function LoadingComponent() {
  return <p>Authentication in progress...</p>;
}

function AuthenticationComponent() {
  const authRequest = {
    ...loginRequest,
  };

  return (
    // authenticationRequest, errorComponent and loadingComponent props are optional
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={LoadingComponent}
    >
      <BrowserRouter>
        <LocalizationProvider dateAdapter={AdapterDateFns as any}>
          <AppRouters />
          <DialogsRenderer />
        </LocalizationProvider>
      </BrowserRouter>
    </MsalAuthenticationTemplate>
  );
}

const App = () => {
  const { instance, accounts, inProgress } = useMsal();
  React.useEffect(() => {
    console.log({ appConfigs });
    async function loadData() {
      try {
        const data = await apiInfoClient.version(cancelToken.source().token);
        console.log({ data });
      } catch (err) {
        console.log(err);
      }
    }
    loadData();
  }, []);

  React.useEffect(() => {
    //accquire access token to calls api
    instance
      .acquireTokenSilent({
        ...tokenRequest,
        account: accounts[0],
      })
      .then((response) => {
        console.log('Token acquired !');
      })
      .catch((err) => {
        if (err instanceof BrowserAuthError) {
          console.log('Not signed in, keep waiting for signed in user ...');
        } else if (err instanceof InteractionRequiredAuthError) {
          console.log('Not signed in, attempting to sign in');
          instance.loginRedirect({ ...loginRequest });
        } else {
          console.error(err);
        }
      });
  }, [instance, accounts, inProgress]);

  const theme = themes();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AuthenticationComponent />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
