import SaveCancelDialog from './SaveCancelDialog';
import FormGenerator from 'shared/components/forms/FormGenerator';
import { useFormik } from 'formik';

const GenericFormDialog = ({ title, onDialogClose, item, fieldGroups, validationSchema, ...otherProps }: any) => {
  const formik = useFormik({
    initialValues: { ...item },
    onSubmit: (values: any) => {
      const resultData = {
        ...item,
        ...values,
      };

      onDialogClose({
        success: true,
        values: resultData,
      });
    },
    validationSchema,
  });

  const handleSave = () => {
    formik.handleSubmit();
  };

  return (
    <SaveCancelDialog show title={title} onSave={handleSave} onDialogClose={onDialogClose} {...otherProps}>
      <FormGenerator fieldGroups={fieldGroups} formik={formik} />
    </SaveCancelDialog>
  );
};

export default GenericFormDialog;
