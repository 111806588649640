import { TypographyOptions } from '@mui/material/styles/createTypography';

export default function themeTypography(): TypographyOptions {
  return {
    fontSize: 18,
    h6: {
      fontWeight: 500,
      fontSize: '0.875rem',
    },
    h5: {
      fontSize: '18px',
      fontWeight: 500,
      lineHeight: '23px',
      letterSpacing: '0.25px',
    },
    h4: {
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: '26px',
      letterSpacing: '0.25px',
    },
    h3: {
      fontSize: '20px',
      fontWeight: 500,
      letterSpacing: '0.1px',
    },
    h2: {
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: '23.44px',
    },
    h1: {
      fontSize: '30px',
      fontWeight: 500,
      lineHeight: '30px',
      letterSpacing: '0.1px',
    },
    subtitle1: {
      fontSize: '18px',
      fontWeight: 500,
      letterSpacing: '0.25px',
      lineHeight: '21.09px',
    },
    subtitle2: {
      fontSize: '0.875rem',
      fontWeight: 400,
    },
    caption: {
      fontSize: '14px',
      fontWeight: 400,
    },
    body1: {
      fontSize: '18px',
      fontWeight: 400,
      letterSpacing: '0.25px',
      lineHeight: '21.09px',
    },
    body2: {
      letterSpacing: '0em',
      fontWeight: 400,
      lineHeight: '1.625em',
    },
    button: {
      textTransform: 'none',
      fontSize: '18px',
      fontWeight: 400,
      letterSpacing: '0.25px',
    },
  };
}
