import { openDialog, closeDialog } from "features/dialog";
import { useAppDispatch } from "app/hooks";
import { ConfirmDialog } from "shared/components/dialogs";

const useDialog = () => {
  const dispatch = useAppDispatch();

  const show = (DialogComponent: any, props: any) => {
    return new Promise((resolve) => {
      const id = new Date().getTime();
      const newProps = { ...(props || {}) };

      newProps.onDialogClose = (modalResult: any) => {
        dispatch(closeDialog(id));
        resolve(modalResult);
      };

      dispatch(
        openDialog({
          id,
          component: DialogComponent,
          props: newProps,
        })
      );
    });
  };

  const message = async (props: any) => {
    const dialog = await show(ConfirmDialog, props);
    return dialog;
  };

  return {
    show,
    message,
  };
};

export default useDialog;
