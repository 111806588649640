export const sidebarWidth = 256;

export const toolbarHeight = 72;

export const defaultPageSizeOptions = [10, 20, 50];

export const defaultLengthTextArea = 4000;

export const defaultMinDate = new Date('1900-01-01 00:00:00');
export const defaultMaxDate = new Date('2200-12-31 00:00:00');

export const defaultMinYear = 1900;
export const defaultMaxYear = 2200;

// 922,337,203,685,477
export const defaultMaxMoney = 922337203685477;

// 2,147,483,647
export const defaultMaxInt = 2147483647;

export const currencyDenominations = {
  ones: {
    name: 'ones',
    label: 'Ones',
    prefix: '$1 x ',
    value: 1,
  },
  twos: {
    name: 'twos',
    label: 'Twos',
    prefix: '$2 x ',
    value: 2,
  },
  fives: {
    name: 'fives',
    label: 'Fives',
    prefix: '$5 x ',
    value: 5,
  },
  tens: {
    name: 'tens',
    label: 'Tens',
    prefix: '$10 x ',
    value: 10,
  },
  twenties: {
    name: 'twenties',
    label: 'Twenties',
    prefix: '$20 x ',
    value: 20,
  },
  fifties: {
    name: 'fifties',
    label: 'Fifties',
    prefix: '$50 x ',
    value: 50,
  },
  hundreds: {
    name: 'hundreds',
    label: 'Hundreds',
    prefix: '$100 x ',
    value: 100,
  },
  penny: {
    name: 'penny',
    label: 'Pennies',
    prefix: '$0.01 x ',
    value: 0.01,
  },
  nickel: {
    name: 'nickel',
    label: 'Nickels',
    prefix: '$0.05 x ',
    value: 0.05,
  },
  dime: {
    name: 'dime',
    label: 'Dimes',
    prefix: '$0.10 x ',
    value: 0.1,
  },
  quarter: {
    name: 'quarter',
    label: 'Quarters',
    prefix: '$0.25 x ',
    value: 0.25,
  },
  half: {
    name: 'half',
    label: 'Half Dollars',
    prefix: '$0.5 x ',
    value: 0.5,
  },
  coin: {
    name: 'coin',
    label: 'Dollar Coins',
    prefix: '$1 x ',
    value: 1,
  },
  pennies: {
    name: 'pennies',
    label: 'Pennies',
    prefix: '$0.50 x ',
    value: 0.01,
    roll: 50,
  },
  nickels: {
    name: 'nickels',
    label: 'Nickels',
    prefix: '$2 x ',
    value: 0.05,
    roll: 40,
  },
  dimes: {
    name: 'dimes',
    label: 'Dimes',
    prefix: '$5 x ',
    value: 0.1,
    roll: 50,
  },
  quarters: {
    name: 'quarters',
    label: 'Quarters',
    prefix: '$10 x ',
    value: 0.25,
    roll: 40,
  },
  halfs: {
    name: 'halfs',
    label: 'Half Dollars',
    prefix: '$20 x ',
    value: 0.5,
    roll: 20,
  },
  coins: {
    name: 'coins',
    label: 'Dollar Coins',
    prefix: '$25 x ',
    value: 1,
    roll: 25,
  },
};

export const programs = [
  { code: 'ECE', name: 'ECE' },
  { code: 'Career Center', name: 'Career Center' },
  { code: 'Other', name: 'Other' },
];

export const classTypes = [
  { code: 'Class', name: 'Class' },
  { code: 'Certificate', name: 'Certificate' },
  { code: 'Service', name: 'Service' },
];

export const participantTypeList = [
  { code: 'Community', name: 'Community' },
  { code: 'Employee', name: 'Employee' },
  { code: 'Observer', name: 'Observer' },
];

export const genderList = [
  { code: 'M', name: 'Male' },
  { code: 'F', name: 'Female' },
  { code: 'N', name: 'Non-binary' },
  { code: 'O', name: 'Other' },
];

export const ethnicityList = [
  { code: 'W', name: 'White' },
  { code: 'B', name: 'Black' },
  { code: 'H', name: 'Hispanic' },
  { code: 'N', name: 'Native American' },
  { code: 'A', name: 'Asian' },
  { code: 'O', name: 'Other' },
];

export const veteranStatusList = [
  { code: 'Family Member of Veteran', name: 'Family Member of Veteran' },
  { code: 'Veteran', name: 'Veteran' },
  { code: 'Veteran with Documented Status', name: 'Veteran with Documented Status' },
  { code: 'Veteran with Service Disability', name: 'Veteran with Service Disability' },
  { code: 'None of the Above/Other', name: 'None of the Above/Other' },
];

export const barrierList = [
  { code: 'Unemployed', name: 'Unemployed' },
  { code: 'Disability', name: 'Disability' },
  { code: 'Background Issues', name: 'Background Issues' },
  { code: 'Lack of High School Equivalency/GED', name: 'Lack of High School Equivalency/GED' },
  { code: 'Non English Speaking/ESL', name: 'Non English Speaking/ESL' },
  { code: 'Underemployed/Incumbent Worker', name: 'Underemployed/Incumbent Worker' },
  { code: 'Dislocated Workers', name: 'Dislocated Workers' },
  { code: 'Low Income', name: 'Low Income' },
  { code: 'Lack of Market-Valued Degree', name: 'Lack of Market-Valued Degree' },
  { code: 'Lack Literacy', name: 'Lack Literacy' },
  { code: 'Spec Learning Disabilities/ADD', name: 'Spec Learning Disabilities/ADD' },
  { code: 'TANF Recipients', name: 'TANF Recipients' },
  { code: 'At-Risk Youth', name: 'At-Risk Youth' },
  { code: 'Teenage Parents', name: 'Teenage Parents' },
  { code: 'Homeless', name: 'Homeless' },
  { code: 'Displaced Homemakers', name: 'Displaced Homemakers' },
  { code: 'Advanced Age (>=55)', name: 'Advanced Age (>=55)' },
  { code: 'Other Governmental Assistance', name: 'Other Governmental Assistance' },
  { code: 'Chemical Dependency', name: 'Chemical Dependency' },
  { code: 'Speech', name: 'Speech' },
  { code: 'Autism', name: 'Autism' },
  { code: 'Intellectual Disability/MR/DD', name: 'Intellectual Disability/MR/DD' },
  { code: 'Cerebral Palsy', name: 'Cerebral Palsy' },
  { code: 'Psychiatric / Psychological', name: 'Psychiatric / Psychological' },
  { code: 'Neurological', name: 'Neurological' },
  { code: 'Traumatic Brain Injury', name: 'Traumatic Brain Injury' },
  { code: 'Seizure Disorder/Epilepsy', name: 'Seizure Disorder/Epilepsy' },
  { code: 'Arthritis / Rheumatic Diseases', name: 'Arthritis / Rheumatic Diseases' },
  { code: 'Orthopedic / Mobility', name: 'Orthopedic / Mobility' },
  { code: 'Blindness / Visual Impairments', name: 'Blindness / Visual Impairments' },
  { code: 'Deafness / Hard of Hearing', name: 'Deafness / Hard of Hearing' },
  { code: 'Cardiac / Pulmonary Disorders', name: 'Cardiac / Pulmonary Disorders' },
  { code: 'Other Disabling Medical Cond.', name: 'Other Disabling Medical Cond.' },
];

export const elsClassTypes = [
  { code: 'Class', name: 'Class' },
  { code: 'Service', name: 'Service' },
  { code: 'Test', name: 'Test' },
];

export const intakeStatus = [
  { code: 'Active', name: 'Active' },
  { code: 'Inactive', name: 'Inactive' },
  { code: 'Placed', name: 'Placed' },
  { code: 'TempPlaced', name: 'TempPlaced' },
  { code: 'LostCon', name: 'LostCon' },
];

export const phoneCarriers = [
  { code: 'AT&T', name: 'AT&T', domain: '@txt.att.net' },
  { code: 'Alltell (Allied Wireless)', name: 'Alltell (Allied Wireless)', domain: '@mms.alltelwireless.com' },
  { code: 'Assurance Wireless', name: 'Assurance Wireless', domain: '@vmobl.com' },
  { code: 'Boost Mobile', name: 'Boost Mobile', domain: '@sms.myboostmobile.com' },
  { code: 'Celluar One', name: 'Celluar One', domain: '@vtext.com' },
  { code: 'Cingular', name: 'Cingular', domain: '@txt.att.net' },
  { code: 'Clearnet (Telus Mobility)', name: 'Clearnet (Telus Mobility)', domain: '@mms.telusmobility.com' },
  { code: 'Comcast / Xfinity', name: 'Comcast / Xfinity', domain: '@vtext.com' },
  { code: 'Consumer Cellular', name: 'Consumer Cellular', domain: '@txt.att.net' },
  { code: 'Cricket Wireless', name: 'Cricket Wireless', domain: '@mms.mycricket.com' },
  { code: 'Metro PCS', name: 'Metro PCS', domain: '@mymetropcs.com' },
  { code: 'Mint', name: 'Mint', domain: '@tmomail.net' },
  { code: 'PureTalk', name: 'PureTalk', domain: '@txt.att.net' },
  { code: 'Republic Wireless', name: 'Republic Wireless', domain: '@tmomail.net' },
  { code: 'Sprint', name: 'Sprint', domain: '@messaging.sprintpcs.com' },
  { code: 'Straight Talk', name: 'Straight Talk', domain: '@vtext.com' },
  { code: 'Tello', name: 'Tello', domain: '@tmomail.net' },
  { code: 'T-Mobile', name: 'T-Mobile', domain: '@tmomail.net' },
  { code: 'Trac Phone (TracFone?)', name: 'Trac Phone (TracFone?)', domain: '@mmst5.tracfone.com' },
  { code: 'U.S. Cellular', name: 'U.S. Cellular', domain: '@mms.uscc.net' },
  { code: 'US West', name: 'US West', domain: '' },
  { code: 'Verizon', name: 'Verizon', domain: '@vtext.com' },
  { code: 'Virgin Mobile', name: 'Virgin Mobile', domain: '@vmobl.com' },
  { code: 'Visible', name: 'Visible', domain: '@vzwpix.com' },
];

export const jobConnectionAcceptableShifts = [
  { code: 'Any', name: 'Any' },
  { code: 'Day', name: 'Day' },
  { code: 'Night', name: 'Night' },
  { code: 'Swing', name: 'Swing' },
  { code: 'Day, Night', name: 'Day, Night' },
  { code: 'Day, Swing', name: 'Day, Swing' },
  { code: 'Night, Swing', name: 'Night, Swing' },
];

export const jobConnectionPerferDaytoWork = [
  { code: 'Any', name: 'Any' },
  { code: 'Mon-Fri', name: 'Mon-Fri' },
  { code: 'Mon-Sat', name: 'Mon-Sat' },
  { code: 'Sun-Fri', name: 'Sun-Fri' },
  { code: 'Sat, Sun', name: 'Sat, Sun' },
  { code: 'Other', name: 'Other' },
];

export const emptyOption = { code: '', name: '' };

export const learnedOfPlacementList = [
  { code: 'Participant e-mailed in', name: 'Participant e-mailed in' },
  { code: 'Participant phoned in', name: 'Participant phoned in' },
  { code: 'Participant told in person', name: 'Participant told in person' },
  { code: 'EC called caseload', name: 'EC called caseload' },
  { code: 'ES called caseload', name: 'ES called caseload' },
  { code: 'rptjobs@gicw.org', name: 'rptjobs@gicw.org' },
  { code: '1-877-676-5872', name: '1-877-676-5872' },
  { code: 'www.goodwilljobconnection.org', name: 'www.goodwilljobconnection.org' },
  { code: 'Paper Reporting Form', name: 'Paper Reporting Form' },
  { code: 'Text Message', name: 'Text Message' },
  { code: 'Employer List', name: 'Employer List' },
  { code: 'TWN', name: 'TWN' },
];

export const placementTypeList = [
  { code: 'Competitive', name: 'Competitive' },
  { code: 'Temporary', name: 'Temporary' },
];

export const placementSubtypeList = [
  { code: 'Customer Service/Sales', name: 'Customer Service/Sales' },
  { code: 'Construction/General Laborer', name: 'Construction/General Laborer' },
  { code: 'Warehouse/Production', name: 'Warehouse/Production' },
  { code: 'Food Service', name: 'Food Service' },
  { code: 'Clerical/Office', name: 'Clerical/Office' },
  { code: 'Transportation', name: 'Transportation' },
  { code: 'Healthcare', name: 'Healthcare' },
  { code: 'Janitor/Housekeeper', name: 'Janitor/Housekeeper' },
  { code: 'Professional', name: 'Professional' },
  { code: 'Other: Specific Vocational Goal', name: 'Other: Specific Vocational Goal' },
];

export const placementVerificationStatusList = [
  { code: 'Employer Verified', name: 'Employer Verified' },
  { code: 'Participant Verified', name: 'Participant Verified' },
  { code: 'Unverifiable', name: 'Unverifiable' },
  { code: 'Touched', name: 'Food Service' },
  { code: 'On-Hold', name: 'On-Hold' },
  { code: 'Submit', name: 'Submit' },
  { code: 'Void', name: 'Void' },
];

export const placementWeeksWorkedList = [
  { code: 'Less Than Two Weeks', name: 'Less Than Two Weeks' },
  { code: 'Two Weeks or More', name: 'Two Weeks or More' },
];

export const jobConnectionAmpType = [
  { code: 'Clerical/Office', name: 'Clerical/Office' },
  { code: 'Construction/General Laborer', name: 'Construction/General Laborer' },
  { code: 'Customer Service/Sales', name: 'Customer Service/Sales' },
  { code: 'Food Service', name: 'Food Service' },
  { code: 'Healthcare', name: 'Healthcare' },
  { code: 'Janitor/Housekeeper', name: 'Janitor/Housekeeper' },
  { code: 'Professional', name: 'Professional' },
  { code: 'Transportation', name: 'Transportation' },
  { code: 'Warehouse/Production', name: 'Warehouse/Production' },
  { code: 'Staffing Agency', name: 'Staffing Agency' },
  { code: 'Government', name: 'Government' },
  { code: 'Veterans Group', name: 'Veterans Group' },
  { code: 'Non-Profit', name: 'Non-Profit' },
];

export const rotations = [
  { code: '1st Rotation', name: '1st Rotation' },
  { code: '2nd Rotation', name: '2nd Rotation' },
  { code: '3rd Rotation', name: '3rd Rotation' },
  { code: '4th Rotation', name: '4th Rotation' },
  { code: '5th Rotation', name: '5th Rotation' },
  { code: '6th Rotation', name: '6th Rotation' },
  { code: '7th Rotation', name: '7th Rotation' },
  { code: '8th Rotation', name: '8th Rotation' },
  { code: '9th Rotation', name: '9th Rotation' },
];

export const containerStatuses = [
  { code: 'Unknown', name: 'Unknown' },
  { code: 'Received', name: 'Received' },
  { code: 'Missing', name: 'Missing' },
  { code: 'Shipped', name: 'Shipped' },
];

export const productTypes = [
  { code: 'Jewelry & Currency', name: 'Jewelry & Currency' },
  { code: 'Arts', name: 'Arts' },
  { code: 'Camera & Camcorders', name: 'Camera & Camcorders' },
  { code: 'Cell Phones', name: 'Cell Phones' },
  { code: 'Clothing', name: 'Clothing' },
  { code: 'Computers', name: 'Computers' },
  { code: 'Crafts', name: 'Crafts' },
  { code: 'Electronics', name: 'Electronics' },
  { code: 'Glass', name: 'Glass' },
  { code: 'Home Decor', name: 'Home Decor' },
  { code: 'Linens/Textiles', name: 'Linens/Textiles' },
  { code: 'Musical instruments', name: 'Musical instruments' },
  { code: 'Office', name: 'Office' },
  { code: 'Religious & Spiritual', name: 'Religious & Spiritual' },
  { code: 'Shoe & Accessories', name: 'Shoe & Accessories' },
  { code: 'Sports & Recreational', name: 'Sports & Recreational' },
  { code: 'Stamps', name: 'Stamps' },
  { code: 'Sterling', name: 'Sterling' },
  { code: 'Table & Kitchenware', name: 'Table & Kitchenware' },
  { code: 'Tools', name: 'Tools' },
  { code: 'Toys/Dolls?Games', name: 'Toys/Dolls?Games' },
  { code: 'Video Games', name: 'Video Games' },
  { code: 'Book Dept. - Books', name: 'Book Dept. - Books' },
  { code: 'Book Dept. - Meida', name: 'Book Dept. - Meida' },
  { code: 'Book Dept. -Research', name: 'Book Dept. -Research' },
  { code: 'Misc.', name: 'Misc.' },
];

export const productStatuses = [
  { code: 'Online', name: 'Online' },
  { code: 'Boutique', name: 'Boutique' },
  { code: 'Rejected', name: 'Rejected' },
  { code: 'Missing', name: 'Missing' },
];
export const jobConnectionLocationTypeList = [
  { code: 'JSS', name: 'JSS' },
  { code: 'JSS CF', name: 'JSS CF' },
  { code: 'Resource', name: 'Resource' },
  { code: 'Career Center', name: 'Career Center' },
];

export const jobConnectionAMPLocationTypeList = [
  { code: 'Headquarters', name: 'Headquarters' },
  { code: 'Regional Office', name: 'Regional Office' },
  { code: 'Branch Location', name: 'Branch Location' },
];

export const jobConnectionAMPEventTypeList = [
  { code: 'Job Fair', name: 'Job Fair' },
  { code: 'Meet The Employer', name: 'Meet The Employer' },
  { code: 'Hiring Event', name: 'Hiring Event' },
  { code: 'Career Festival', name: 'Career Festival' },
];

export const jobConnectionEducationDegreeList = [
  { code: 'No Highschool Diploma/GED', name: 'No Highschool Diploma/GED' },
  { code: 'Highschool Diploma/GED', name: 'Highschool Diploma/GED' },
  { code: 'Associates Degree', name: 'Associates Degree' },
  { code: 'Bachelors Degree', name: 'Bachelors Degree' },
  { code: 'Masters Degree', name: 'Masters Degree' },
  { code: 'Doctorate Degree', name: 'Doctorate Degree' },
];

export const dbContext = {
  Salvage: 'Salvage',
  Gre: 'Gre',
  ECommerce: 'ECommerce',
  FixedAssets: 'FixedAssets',
  LossControl: 'LossControl',
  Kaizen: 'Kaizen',
  HR: 'HR',
  Orthos: 'Orthos',
  PDXSQLGre: 'PDXSQLGre',
};

export const storeIdValues = {
  CAFE: '00',
  SALMON_CREEK: '68',
};

export enum GoodwillCarePersonType {
  Children = 1,
  Teen = 2,
}

// types of entry reserve fund log
export const CASH_PICKUPS = 'cashPickups';
export const BANK_CHANGE_ORDERS = 'bankChangeOrders';
export const CASHIER_CHANGE = 'cashierChange';
export const DEPOSIT_ARMORED_PICKUP = 'depositArmoredPickup';
export const VERIFY_SAFE_FUND = 'verifySafeFund';

export const reserveFundLogTypes = [
  CASH_PICKUPS,
  BANK_CHANGE_ORDERS,
  CASHIER_CHANGE,
  DEPOSIT_ARMORED_PICKUP,
  VERIFY_SAFE_FUND,
];

export const reasonForTresspassTypes = [
  { code: 'Theft', name: 'Theft' },
  { code: 'Recovery', name: 'Recovery' },
  { code: 'Price Switch', name: 'Price Switch' },
  { code: 'Criminal Trespass', name: 'Criminal Trespass' },
  { code: 'Interfering with GICW Operations', name: 'Interfering with GICW Operations' },
  { code: 'Harassment', name: 'Harassment' },
  { code: 'Assault', name: 'Assault' },
  { code: 'N/A', name: 'N/A' },
];

export const genders = [
  { code: 'Male', name: 'Male' },
  { code: 'Female', name: 'Female' },
  { code: 'Unknown', name: 'Unknown' },
];

export const adultJunveniles = [
  { code: 'Adult', name: 'Adult' },
  { code: 'Juvenile', name: 'Juvenile' },
];

export const races = [
  { code: 'White', name: 'White' },
  { code: 'Hispanic', name: 'Hispanic' },
  { code: 'Black', name: 'Black' },
  { code: 'Asian', name: 'Asian' },
  { code: 'American Indian', name: 'American Indian' },
  { code: 'Other', name: 'Other' },
  { code: 'Unknown', name: 'Unknown' },
];

export const scores = [
  { code: '1', name: 'Pass' },
  { code: '2', name: 'Fail' },
];

export const jobConnectionCertificateDescription = [
  { code: 'CDL', name: 'CDL' },
  { code: 'CMA', name: 'CMA' },
  { code: 'CNA', name: 'CNA' },
  { code: 'CNC Machinist', name: 'CNC Machinist' },
  { code: 'DPSST Security Certified', name: 'DPSST Security Certified' },
  { code: 'First Aid/CPR', name: 'First Aid/CPR' },
  { code: 'Flagger Certified', name: 'Flagger Certified' },
  { code: 'Forklift Certified', name: 'Forklift Certified' },
  { code: 'HAZMAT', name: 'HAZMAT' },
  { code: 'HVAC Certified', name: 'HVAC Certified' },
  { code: 'OLCC Server Permit', name: 'OLCC Server Permit' },
  { code: 'OR/WA Food Handlers Card', name: 'OR/WA Food Handlers Card' },
  { code: 'WA State MAST Certification', name: 'WA State MAST Certification' },
];
