import { useMsal } from '@azure/msal-react';
import { useCallback, useEffect, useState } from 'react';
import jwt_decode from 'jwt-decode';
import { tokenRequest, loginRequest } from 'auth/authConfig';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

export interface IUseAccessControl {
  isValidRole: (allowedRoles: string[]) => boolean;
  isPermitted: boolean;
}

export interface IUseAccessControlOptions {
  defaultAllowedRoles?: string[];
}

const extractUserRoles = (accessToken: string): Array<string> => {
  const decoded = jwt_decode<any>(accessToken);
  const roles = (decoded.roles || '') as Array<string>;
  return roles;
};

export const useAuthControl = ({ defaultAllowedRoles = [] }: IUseAccessControlOptions = {}): IUseAccessControl => {
  const { instance, accounts } = useMsal();
  const [userRoles, setUserRoles] = useState<String[]>([]);

  useEffect(() => {
    const getUserRoles = async () => {
      try {
        const authResult = await instance.acquireTokenSilent({ ...tokenRequest, account: accounts[0] });
        const accessToken = authResult.accessToken;
        const roles = extractUserRoles(accessToken);
        setUserRoles([...roles]);
      } catch (err) {
        if (err instanceof InteractionRequiredAuthError) {
          instance.loginRedirect({ ...loginRequest });
        }
      }
    };
    getUserRoles();
  }, [instance, accounts]);

  const isValidRole = useCallback(
    (allowedRoles: string[]) => {
      if (allowedRoles.length === 0) {
        return true;
      }
      return allowedRoles.some((allowedRole: string) => userRoles.includes(allowedRole));
    },
    [userRoles]
  );

  return {
    isValidRole,
    isPermitted: isValidRole(defaultAllowedRoles),
  };
};

export default useAuthControl;
