import useAuthControl from 'auth/authControl/useAuthControl';
import * as AppRoles from 'shared/constants/appRoles';

const VIEW_PRODUCTION_BUDGET_ROLES = [
  AppRoles.PRODUCTION_BUDGET_EDIT,
  AppRoles.PRODUCTION_BUDGET_SUBMIT,
  AppRoles.PRODUCTION_BUDGET_VIEW,
];

const VIEWEDIT_PRODUCTIONDUGET_ROLES = [AppRoles.PRODUCTION_BUDGET_EDIT, AppRoles.PRODUCTION_BUDGET_VIEW];

const SUBMIT_PRODUCTION_BUDGET_ROLES = [AppRoles.PRODUCTION_BUDGET_SUBMIT];

const VIEW_DONATION_BUDGET_ROLES = [
  AppRoles.DONATION_BUDGET_EDIT,
  AppRoles.DONATION_BUDGET_SUBMIT,
  AppRoles.DONATION_BUDGET_VIEW,
];

const VIEWEDIT_DONATION_BUDGET_ROLES = [AppRoles.DONATION_BUDGET_EDIT, AppRoles.DONATION_BUDGET_VIEW];

const SUBMIT_DONATION_BUDGET_ROLES = [AppRoles.DONATION_BUDGET_SUBMIT];

const VIEW_HALLOWEEN_BUDGET_ROLES = [
  AppRoles.HALLOWEEN_BUDGET_EDIT,
  AppRoles.HALLOWEEN_BUDGET_SUBMIT,
  AppRoles.HALLOWEEN_BUDGET_VIEW,
];

const VIEWEDIT_HALLOWEEN_BUDGET_ROLES = [AppRoles.HALLOWEEN_BUDGET_EDIT, AppRoles.HALLOWEEN_BUDGET_VIEW];

const SUBMIT_HALLOWEEN_BUDGET_ROLES = [AppRoles.HALLOWEEN_BUDGET_SUBMIT];

const VIEW_CHRISTMAS_BUDGET_ROLES = [
  AppRoles.CHRISTMAS_BUDGET_EDIT,
  AppRoles.CHRISTMAS_BUDGET_SUBMIT,
  AppRoles.CHRISTMAS_BUDGET_VIEW,
];

const VIEWEDIT_CHRISTMAS_BUDGET_ROLES = [AppRoles.CHRISTMAS_BUDGET_EDIT, AppRoles.CHRISTMAS_BUDGET_VIEW];

const SUBMIT_CHRISTMAS_BUDGET_ROLES = [AppRoles.CHRISTMAS_BUDGET_SUBMIT];

const VIEW_LABORHOUR_BUDGET_ROLES = [
  AppRoles.LABOR_HOUR_BUDGET_EDIT,
  AppRoles.LABOR_HOUR_BUDGET_SUBMIT,
  AppRoles.LABOR_HOUR_BUDGET_VIEW,
];

const VIEWEDIT_LABORHOUR_BUDGET_ROLES = [AppRoles.LABOR_HOUR_BUDGET_EDIT, AppRoles.LABOR_HOUR_BUDGET_VIEW];

const SUBMIT_LABORHOUR_BUDGET_ROLES = [AppRoles.LABOR_HOUR_BUDGET_SUBMIT];

const VIEW_CARE_BUDGET_ROLES = [
  AppRoles.GOODWILL_CARES_EDIT,
  AppRoles.GOODWILL_CARES_SUBMIT,
  AppRoles.GOODWILL_CARES_VIEW,
];

const VIEWEDIT_CARE_BUDGET_ROLES = [AppRoles.GOODWILL_CARES_EDIT, AppRoles.GOODWILL_CARES_VIEW];

const SUBMIT_CARE_BUDGET_ROLES = [AppRoles.GOODWILL_CARES_SUBMIT];

const VIEW_TARGETRECEIPTS_ROLES = [
  AppRoles.TARGET_RECEIPTS_VIEW,
  AppRoles.TARGET_RECEIPTS_EDIT,
  AppRoles.TARGET_RECEIPTS_SUBMIT,
];

const VIEW_TARGET_INVOICES_ROLES = [
  AppRoles.TARGET_INVOICES_VIEW,
  AppRoles.TARGET_INVOICES_EDIT,
  AppRoles.TARGET_INVOICES_SUBMIT,
];

const VIEW_TARGET_SHIPMENTS_ROLES = [
  AppRoles.TARGET_SHIPMENT_VIEW,
  AppRoles.TARGET_SHIPMENT_EDIT,
  AppRoles.TARGET_SHIPMENT_SUBMIT,
];

const VIEW_STORE_MESSAGE_ROLES = [
  AppRoles.STORE_MESSAGES_VIEW,
  AppRoles.STORE_MESSAGES_EDIT,
  AppRoles.STORE_MESSAGES_SUBMIT,
];

const VIEW_ECOMMERCE_CONTAINER_ROLES = [
  AppRoles.ECOMMERCE_CONTAINER_VIEW,
  AppRoles.ECOMMERCE_CONTAINER_EDIT,
  AppRoles.ECOMMERCE_CONTAINER_SUBMIT,
];
const VIEWEDIT_ECOMMERCE_CONTAINER_ROLES = [AppRoles.ECOMMERCE_CONTAINER_VIEW, AppRoles.ECOMMERCE_CONTAINER_EDIT];
const SUBMIT_ECOMMERCE_CONTAINER_ROLES = [AppRoles.ECOMMERCE_CONTAINER_SUBMIT];

const VIEW_SITE_ROLES = [AppRoles.SITE_VIEW, AppRoles.SITE_EDIT, AppRoles.SITE_SUBMIT];
const VIEWEDIT_SITE_ROLES = [AppRoles.SITE_VIEW, AppRoles.SITE_EDIT];
const SUBMIT_SITE_ROLES = [AppRoles.SITE_SUBMIT];

const VIEW_FEEDBACK_LOGS_ROLES = [AppRoles.FEEDBACK_LOG_EDIT, AppRoles.FEEDBACK_LOG_SUBMIT, AppRoles.FEEDBACK_LOG_VIEW];

const VIEWEDIT_FEEDBACK_LOGS_ROLES = [AppRoles.FEEDBACK_LOG_EDIT, AppRoles.FEEDBACK_LOG_VIEW];

const SUBMIT_FEEDBACK_LOGS_ROLES = [AppRoles.FEEDBACK_LOG_SUBMIT];

const VIEW_FIXED_ASSETS_ROLES = [AppRoles.FIXED_ASSETS_VIEW, AppRoles.FIXED_ASSETS_EDIT, AppRoles.FIXED_ASSETS_SUBMIT];
const VIEWEDIT_FIXED_ASSETS_ROLES = [AppRoles.FIXED_ASSETS_VIEW, AppRoles.FIXED_ASSETS_EDIT];
const SUBMIT_FIXED_ASSETS_ROLES = [AppRoles.FIXED_ASSETS_SUBMIT];

const VIEW_ASSET_TYPES_ROLES = [AppRoles.ASSET_TYPES_VIEW, AppRoles.ASSET_TYPES_EDIT, AppRoles.ASSET_TYPES_SUBMIT];
const VIEWEDIT_ASSET_TYPES_ROLES = [AppRoles.ASSET_TYPES_VIEW, AppRoles.ASSET_TYPES_EDIT];
const SUBMIT_ASSET_TYPES_ROLES = [AppRoles.ASSET_TYPES_SUBMIT];

const VIEW_BOOK_CONTAINER_ROLES = [
  AppRoles.BOOK_CONTAINER_AUDIT_VIEW,
  AppRoles.BOOK_CONTAINER_AUDIT_EDIT,
  AppRoles.BOOK_CONTAINER_AUDIT_SUBMIT,
];
const VIEWEDIT_BOOK_CONTAINER_ROLES = [AppRoles.BOOK_CONTAINER_AUDIT_VIEW, AppRoles.BOOK_CONTAINER_AUDIT_EDIT];
const SUBMIT_BOOK_CONTAINER_ROLES = [AppRoles.BOOK_CONTAINER_AUDIT_SUBMIT];

const VIEW_COST_CENTER_ROLES = [AppRoles.COST_CENTERS_EDIT, AppRoles.COST_CENTERS_SUBMIT, AppRoles.COST_CENTERS_VIEW];

const VIEWEDIT_COST_CENTER_ROLES = [AppRoles.COST_CENTERS_EDIT, AppRoles.COST_CENTERS_VIEW];

const SUBMIT_COST_CENTER_ROLES = [AppRoles.COST_CENTERS_SUBMIT];

const VIEW_MEDIA_CONTAINER_ROLES = [
  AppRoles.MEDIA_CONTAINER_AUDIT_VIEW,
  AppRoles.MEDIA_CONTAINER_AUDIT_EDIT,
  AppRoles.MEDIA_CONTAINER_AUDIT_SUBMIT,
];
const VIEWEDIT_MEDIA_CONTAINER_ROLES = [AppRoles.MEDIA_CONTAINER_AUDIT_VIEW, AppRoles.MEDIA_CONTAINER_AUDIT_EDIT];
const SUBMIT_MEDIA_CONTAINER_ROLES = [AppRoles.MEDIA_CONTAINER_AUDIT_SUBMIT];

const VIEW_ECOLLECTABLE_ROLES = [
  AppRoles.ECOLLECTABLE_LISTINGS_EDIT,
  AppRoles.ECOLLECTABLE_LISTINGS_SUBMIT,
  AppRoles.ECOLLECTABLE_LISTINGS_VIEW,
];
const VIEWEDIT_ECOLLECTABLE_ROLES = [AppRoles.ECOLLECTABLE_LISTINGS_VIEW, AppRoles.ECOLLECTABLE_LISTINGS_EDIT];
const SUBMIT_ECOLLECTABLE_ROLES = [AppRoles.ECOLLECTABLE_LISTINGS_SUBMIT];

const VIEW_CAR_DONATION_ROLES = [
  AppRoles.CAR_DONATIONS_EDIT,
  AppRoles.CAR_DONATIONS_SUBMIT,
  AppRoles.CAR_DONATIONS_VIEW,
];
const VIEWEDIT_CAR_DONATION_ROLES = [AppRoles.CAR_DONATIONS_VIEW, AppRoles.CAR_DONATIONS_EDIT];
const SUBMIT_CAR_DONATION_ROLES = [AppRoles.CAR_DONATIONS_SUBMIT];

const VIEW_SHOPLIFTER_ROLES = [AppRoles.SHOPLIFTER_VIEW, AppRoles.SHOPLIFTER_EDIT, AppRoles.SHOPLIFTER_SUBMIT];
const VIEWEDIT_SHOPLIFTER_ROLES = [AppRoles.SHOPLIFTER_EDIT, AppRoles.SHOPLIFTER_VIEW];
const SUBMIT_SHOPLIFTER_ROLES = [AppRoles.SHOPLIFTER_SUBMIT];

const VIEW_DONATION_IN_ERROR_ROLES = [
  AppRoles.DONATION_IN_ERROR_VIEW,
  AppRoles.DONATION_IN_ERROR_EDIT,
  AppRoles.DONATION_IN_ERROR_SUBMIT,
];
const VIEWEDIT_DONATION_IN_ERROR_ROLES = [AppRoles.DONATION_IN_ERROR_VIEW, AppRoles.DONATION_IN_ERROR_EDIT];
const SUBMIT_DONATION_IN_ERROR_ROLES = [AppRoles.DONATION_IN_ERROR_SUBMIT];

const VIEW_GENERAL_INVENTORY_ROLES = [
  AppRoles.GENERAL_INVENTORY_VIEW,
  AppRoles.GENERAL_INVENTORY_EDIT,
  AppRoles.GENERAL_INVENTORY_SUBMIT,
];
const VIEWEDIT_GENERAL_INVENTORY_ROLES = [AppRoles.GENERAL_INVENTORY_VIEW, AppRoles.GENERAL_INVENTORY_EDIT];
const SUBMIT_GENERAL_INVENTORY_ROLES = [AppRoles.GENERAL_INVENTORY_SUBMIT];

const VIEW_GENERAL_INVENTORY_ITEMS_LIST_ROLES = [
  AppRoles.GENERAL_INVENTORY_ITEMS_LIST_VIEW,
  AppRoles.GENERAL_INVENTORY_ITEMS_LIST_EDIT,
  AppRoles.GENERAL_INVENTORY_ITEMS_LIST_SUBMIT,
];
const VIEWEDIT_GENERAL_INVENTORY_ITEMS_LIST_ROLES = [
  AppRoles.GENERAL_INVENTORY_ITEMS_LIST_VIEW,
  AppRoles.GENERAL_INVENTORY_ITEMS_LIST_EDIT,
];
const SUBMIT_GENERAL_INVENTORY_ITEMS_LIST_ROLES = [AppRoles.GENERAL_INVENTORY_ITEMS_LIST_SUBMIT];

const VIEW_ECETRAINING_ROLES = [AppRoles.ECETRAINING_VIEW, AppRoles.ECETRAINING_EDIT, AppRoles.ECETRAINING_SUBMIT];
const VIEWEDIT_ECETRAINING_ROLES = [AppRoles.ECETRAINING_VIEW, AppRoles.ECETRAINING_EDIT];
const SUBMIT_ECETRAINING_ROLES = [AppRoles.ECETRAINING_SUBMIT];

const VIEW_ECECOMMUNITY_PARTICIPANT_ROLES = [
  AppRoles.ECECOMMUNITY_PARTICIPANT_VIEW,
  AppRoles.ECECOMMUNITY_PARTICIPANT_EDIT,
  AppRoles.ECECOMMUNITY_PARTICIPANT_SUBMIT,
];
const VIEWEDIT_ECECOMMUNITY_PARTICIPANT_ROLES = [
  AppRoles.ECECOMMUNITY_PARTICIPANT_VIEW,
  AppRoles.ECECOMMUNITY_PARTICIPANT_EDIT,
];
const SUBMIT_ECECOMMUNITY_PARTICIPANT_ROLES = [AppRoles.ECECOMMUNITY_PARTICIPANT_SUBMIT];

const VIEW_ECEEMPLOYEE_ROLES = [AppRoles.ECEEMPLOYEE_VIEW, AppRoles.ECEEMPLOYEE_EDIT, AppRoles.ECEEMPLOYEE_SUBMIT];
const VIEWEDIT_ECEEMPLOYEE_ROLES = [AppRoles.ECEEMPLOYEE_VIEW, AppRoles.ECEEMPLOYEE_EDIT];
const SUBMIT_ECEEMPLOYEE_ROLES = [AppRoles.ECEEMPLOYEE_SUBMIT];

const VIEW_ECEANCILLARY_SERVICES_ROLES = [
  AppRoles.ECEANCILLARY_SERVICES_VIEW,
  AppRoles.ECEANCILLARY_SERVICES_EDIT,
  AppRoles.ECEANCILLARY_SERVICES_SUBMIT,
];
const VIEWEDIT_ECEANCILLARY_SERVICES_ROLES = [AppRoles.ECEANCILLARY_SERVICES_VIEW, AppRoles.ECEANCILLARY_SERVICES_EDIT];
const SUBMIT_ECEANCILLARY_SERVICES_ROLES = [AppRoles.ECEANCILLARY_SERVICES_SUBMIT];

const VIEW_ECECLASSES_ROLES = [AppRoles.ECECLASSES_VIEW, AppRoles.ECECLASSES_EDIT, AppRoles.ECECLASSES_SUBMIT];
const VIEWEDIT_ECECLASSES_ROLES = [AppRoles.ECECLASSES_VIEW, AppRoles.ECECLASSES_EDIT];
const SUBMIT_ECECLASSES_ROLES = [AppRoles.ECECLASSES_SUBMIT];

const VIEW_ECELOCATIONS_ROLES = [AppRoles.ECELOCATIONS_VIEW, AppRoles.ECELOCATIONS_EDIT, AppRoles.ECECLASSES_SUBMIT];
const VIEWEDIT_ECELOCATIONS_ROLES = [AppRoles.ECELOCATIONS_VIEW, AppRoles.ECELOCATIONS_EDIT];
const SUBMIT_ECELOCATIONS_ROLES = [AppRoles.ECELOCATIONS_SUBMIT];

const VIEW_ECETEACHERS_ROLES = [AppRoles.ECETEACHERS_VIEW, AppRoles.ECETEACHERS_EDIT, AppRoles.ECETEACHERS_SUBMIT];
const VIEWEDIT_ECETEACHERS_ROLES = [AppRoles.ECETEACHERS_VIEW, AppRoles.ECETEACHERS_EDIT];
const SUBMIT_ECETEACHERS_ROLES = [AppRoles.ECETEACHERS_SUBMIT];

const VIEW_ESLPARTICIPANTS_ROLES = [
  AppRoles.ESLPARTICIPANTS_VIEW,
  AppRoles.ESLPARTICIPANTS_EDIT,
  AppRoles.ESLPARTICIPANTS_SUBMIT,
];
const VIEWEDIT_ESLPARTICIPANTS_ROLES = [AppRoles.ESLPARTICIPANTS_VIEW, AppRoles.ESLPARTICIPANTS_EDIT];
const SUBMIT_ESLPARTICIPANTS_ROLES = [AppRoles.ESLPARTICIPANTS_SUBMIT];

const VIEW_ESLTRAINING_ROLES = [AppRoles.ESLTRAINING_VIEW, AppRoles.ESLTRAINING_EDIT, AppRoles.ESLTRAINING_SUBMIT];
const VIEWEDIT_ESLTRAINING_ROLES = [AppRoles.ESLTRAINING_VIEW, AppRoles.ESLTRAINING_EDIT];
const SUBMIT_ESLTRAINING_ROLES = [AppRoles.ESLTRAINING_SUBMIT];

const VIEW_ESLCITIZENSHIP_ROLES = [
  AppRoles.ESLCITIZENSHIP_VIEW,
  AppRoles.ESLCITIZENSHIP_EDIT,
  AppRoles.ESLCITIZENSHIP_SUBMIT,
];
const VIEWEDIT_ESLCITIZENSHIP_ROLES = [AppRoles.ESLCITIZENSHIP_VIEW, AppRoles.ESLCITIZENSHIP_EDIT];
const SUBMIT_ESLCITIZENSHIP_ROLES = [AppRoles.ESLCITIZENSHIP_SUBMIT];

const VIEW_ESLCLASSES_ROLES = [AppRoles.ESLCLASSES_VIEW, AppRoles.ESLCLASSES_EDIT, AppRoles.ESLCLASSES_SUBMIT];

const VIEW_ESLINSTRUCTORS_ROLES = [
  AppRoles.ESLINSTRUCTORS_EDIT,
  AppRoles.ESLINSTRUCTORS_VIEW,
  AppRoles.ESLINSTRUCTORS_SUBMIT,
];

const VIEW_ESLLOCATIONS_ROLES = [AppRoles.ESLLOCATIONS_EDIT, AppRoles.ESLLOCATIONS_VIEW, AppRoles.ESLLOCATIONS_SUBMIT];
const VIEWEDIT_ESLLOCATIONS_ROLES = [AppRoles.ESLLOCATIONS_VIEW, AppRoles.ESLLOCATIONS_EDIT];
const SUBMIT_ESLLOCATIONS_ROLES = [AppRoles.ESLLOCATIONS_SUBMIT];

const VIEW_ESLREFERRAL_SOURCES_ROLES = [
  AppRoles.ESLREFERRAL_SOURCES_VIEW,
  AppRoles.ESLREFERRAL_SOURCES_EDIT,
  AppRoles.ESLREFERRAL_SOURCES_SUBMIT,
];

const VIEW_DONORENTRY_ROLES = [AppRoles.DONORENTRY_VIEW, AppRoles.DONORENTRY_EDIT, AppRoles.DONORENTRY_SUBMIT];
const VIEWEDIT_DONORENTRY_ROLES = [AppRoles.DONORENTRY_VIEW, AppRoles.DONORENTRY_EDIT];
const SUBMIT_DONORENTRY_ROLES = [AppRoles.DONORENTRY_SUBMIT];

const VIEW_APPLICATIONENTRY_ROLES = [
  AppRoles.APPLICATIONENTRY_VIEW,
  AppRoles.APPLICATIONENTRY_EDIT,
  AppRoles.APPLICATIONENTRY_SUBMIT,
];
const VIEWEDIT_APPLICATIONENTRY_ROLES = [AppRoles.APPLICATIONENTRY_VIEW, AppRoles.APPLICATIONENTRY_EDIT];
const SUBMIT_APPLICATIONENTRY_ROLES = [AppRoles.APPLICATIONENTRY_SUBMIT];

const VIEW_KAIZENSCORE_ROLES = [
  AppRoles.KAIZEN_SCOREENTRY_VIEW,
  AppRoles.KAIZEN_SCOREENTRY_EDIT,
  AppRoles.KAIZEN_SCOREENTRY_SUBMIT,
  AppRoles.KAIZEN_SCOREENTRY_SUBMIT_WITH_EDIT,
];
const VIEWEDIT_KAIZENSCORE_ROLES = [
  AppRoles.KAIZEN_SCOREENTRY_VIEW,
  AppRoles.KAIZEN_SCOREENTRY_EDIT,
  AppRoles.KAIZEN_SCOREENTRY_SUBMIT_WITH_EDIT,
];
const SUBMIT_KAIZENSCORE_ROLES = [AppRoles.KAIZEN_SCOREENTRY_SUBMIT, AppRoles.KAIZEN_SCOREENTRY_SUBMIT_WITH_EDIT];

const VIEW_KAIZENSUGGESTION_ROLES = [
  AppRoles.KAIZEN_SUGGESTION_EDIT,
  AppRoles.KAIZEN_SUGGESTION_VIEW,
  AppRoles.KAIZEN_SUGGESTION_SUBMIT,
];
const VIEWEDIT_KAIZENSUGGESTION_ROLES = [AppRoles.KAIZEN_SUGGESTION_VIEW, AppRoles.KAIZEN_SUGGESTION_EDIT];
const SUBMIT_KAIZENSUGGESTION_ROLES = [AppRoles.KAIZEN_SUGGESTION_SUBMIT];

const VIEW_EQUIPMENTINVENTORY_ROLES = [
  AppRoles.EQUIPMENTINVENTORY_VIEW,
  AppRoles.EQUIPMENTINVENTORY_EDIT,
  AppRoles.EQUIPMENTINVENTORY_SUBMIT,
];
const VIEWEDIT_EQUIPMENTINVENTORY_ROLES = [AppRoles.EQUIPMENTINVENTORY_VIEW, AppRoles.EQUIPMENTINVENTORY_EDIT];
const SUBMIT_EQUIPMENTINVENTORY_ROLES = [AppRoles.EQUIPMENTINVENTORY_SUBMIT];

const VIEW_EMPLOYEEINJURYNOTICE_ROLES = [
  AppRoles.EMPLOYEEINJURYNOTICE_VIEW,
  AppRoles.EMPLOYEEINJURYNOTICE_EDIT,
  AppRoles.EMPLOYEEINJURYNOTICE_SUBMIT,
];
const VIEWEDIT_EMPLOYEEINJURYNOTICE_ROLES = [AppRoles.EMPLOYEEINJURYNOTICE_VIEW, AppRoles.EMPLOYEEINJURYNOTICE_EDIT];
const SUBMIT_EMPLOYEEINJURYNOTICE_ROLES = [AppRoles.EMPLOYEEINJURYNOTICE_SUBMIT];

const VIEW_DMAUDIT_ROLES = [
  AppRoles.DISTRICTMANAGER_VIEW,
  AppRoles.DISTRICTMANAGER_EDIT,
  AppRoles.DISTRICTMANAGER_SUBMIT,
];

const VIEW_CASHCOUNT_ROLES = [AppRoles.CASHCOUNT_EDIT, AppRoles.CASHCOUNT_VIEW, AppRoles.CASHCOUNT_SUBMIT];
const VIEWEDIT_CASHCOUNT_ROLES = [AppRoles.CASHCOUNT_VIEW, AppRoles.CASHCOUNT_EDIT];
const SUBMIT_CASHCOUNT_ROLES = [AppRoles.CASHCOUNT_SUBMIT];

const VIEW_RESERVEFUND_ROLES = [AppRoles.RESERVEFUND_EDIT, AppRoles.RESERVEFUND_VIEW, AppRoles.RESERVEFUND_SUBMIT];
const VIEWEDIT_RESERVEFUND_ROLES = [AppRoles.RESERVEFUND_VIEW, AppRoles.RESERVEFUND_EDIT];
const SUBMIT_RESERVEFUND_ROLES = [AppRoles.RESERVEFUND_SUBMIT];

const VIEW_DEPOSITENTRY_ROLES = [AppRoles.DEPOSITENTRY_EDIT, AppRoles.DEPOSITENTRY_VIEW, AppRoles.DEPOSITENTRY_SUBMIT];
const VIEWEDIT_DEPOSITENTRY_ROLES = [AppRoles.DEPOSITENTRY_VIEW, AppRoles.DEPOSITENTRY_EDIT];
const SUBMIT_DEPOSITENTRY_ROLES = [AppRoles.DEPOSITENTRY_SUBMIT];
const OVERRIDE_DEPOSIT_ROLES = [AppRoles.DEPOSITENTRY_OVERRIDE];

const VIEW_CASHIERVARIANCE_ROLES = [
  AppRoles.CASHIERVARIANCE_EDIT,
  AppRoles.CASHIERVARIANCE_VIEW,
  AppRoles.CASHIERVARIANCE_SUBMIT,
];
const VIEWEDIT_CASHIERVARIANCE_ROLES = [AppRoles.CASHIERVARIANCE_VIEW, AppRoles.CASHIERVARIANCE_EDIT];
const SUBMIT_CASHIERVARIANCE_ROLES = [AppRoles.CASHIERVARIANCE_SUBMIT];

const VIEW_INCIDENTREPORT_ROLES = [
  AppRoles.INCIDENTREPORT_EDIT,
  AppRoles.INCIDENTREPORT_VIEW,
  AppRoles.INCIDENTREPORT_SUBMIT,
];

const VIEW_HISTORICALPRESERVATION_ROLES = [
  AppRoles.HISTORICALPRESERVATION_EDIT,
  AppRoles.HISTORICALPRESERVATION_VIEW,
  AppRoles.HISTORICALPRESERVATION_SUBMIT,
];
const VIEWEDIT_HISTORICALPRESERVATION_ROLES = [
  AppRoles.HISTORICALPRESERVATION_VIEW,
  AppRoles.HISTORICALPRESERVATION_EDIT,
];
const SUBMIT_HISTORICALPRESERVATION_ROLES = [AppRoles.HISTORICALPRESERVATION_SUBMIT];

const VIEW_LOSSCONTROLDAILY_ACTIVITYLOG_ROLES = [
  AppRoles.LOSSCONTROLDAILY_ACTIVITYLOG_EDIT,
  AppRoles.LOSSCONTROLDAILY_ACTIVITYLOG_VIEW,
  AppRoles.LOSSCONTROLDAILY_ACTIVITYLOG_SUBMIT,
];
const VIEWEDIT_LOSSCONTROLDAILY_ACTIVITYLOG_ROLES = [
  AppRoles.LOSSCONTROLDAILY_ACTIVITYLOG_VIEW,
  AppRoles.LOSSCONTROLDAILY_ACTIVITYLOG_EDIT,
];
const SUBMIT_LOSSCONTROLDAILY_ACTIVITYLOG_ROLES = [AppRoles.LOSSCONTROLDAILY_ACTIVITYLOG_SUBMIT];

const SUBMIT_EEODATA_ROLES = [AppRoles.EEODATA_SUBMIT];

const VIEW_LOSSCONTROLREVIEW_ROLES = [
  AppRoles.LOSSCONTROLREVIEW_EDIT,
  AppRoles.LOSSCONTROLREVIEW_VIEW,
  AppRoles.LOSSCONTROLREVIEW_SUBMIT,
  AppRoles.LOSSCONTROLREVIEW_APPROVER,
];
const VIEWEDIT_LOSSCONTROLREVIEW_ROLES = [
  AppRoles.LOSSCONTROLREVIEW_VIEW,
  AppRoles.LOSSCONTROLREVIEW_EDIT,
  AppRoles.LOSSCONTROLREVIEW_APPROVER,
];
const SUBMIT_LOSSCONTROLREVIEW_ROLES = [AppRoles.LOSSCONTROLREVIEW_SUBMIT];

const VIEW_ACCIDENT_ANALYSIS_ROLES = [
  AppRoles.ACCIDENTANALYSIS_EDIT,
  AppRoles.ACCIDENTANALYSIS_VIEW,
  AppRoles.ACCIDENTANALYSIS_SUBMIT,
];
const VIEWEDIT_ACCIDENT_ANALYSIS_ROLES = [AppRoles.ACCIDENTANALYSIS_VIEW, AppRoles.ACCIDENTANALYSIS_EDIT];
const SUBMIT_ACCIDENT_ANALYSIS_ROLES = [AppRoles.ACCIDENTANALYSIS_SUBMIT];

const VIEW_SAFETYAUDITREPORT_ROLES = [
  AppRoles.SAFETY_AUDITREPORT_VIEW,
  AppRoles.SAFETY_AUDITREPORT_EDIT,
  AppRoles.SAFETY_AUDITREPORT_SUBMIT,
];
const VIEWEDIT_SAFETYAUDITREPORT_ROLES = [AppRoles.SAFETY_AUDITREPORT_VIEW, AppRoles.SAFETY_AUDITREPORT_EDIT];
const SUBMIT_SAFETYAUDITREPORT_ROLES = [AppRoles.SAFETY_AUDITREPORT_SUBMIT];

const VIEW_JCPARTICIPANT_ROLES = [
  AppRoles.JCPARTICIPANTSEARCH_EDIT,
  AppRoles.JCPARTICIPANTSEARCH_VIEW,
  AppRoles.JCPARTICIPANTSEARCH_SUBMIT,
  AppRoles.JCPARTICIPANTSEARCH_EDITWITHSSN,
];
const VIEWEDIT_JCPARTICIPANT_ROLES = [
  AppRoles.JCPARTICIPANTSEARCH_VIEW,
  AppRoles.JCPARTICIPANTSEARCH_EDIT,
  AppRoles.JCPARTICIPANTSEARCH_EDITWITHSSN,
];
const VIEWEDIT_SSN_JCPARTICIPANT_ROLES = [AppRoles.JCPARTICIPANTSEARCH_EDITWITHSSN];
const SUBMIT_JCPARTICIPANT_ROLES = [AppRoles.JCPARTICIPANTSEARCH_SUBMIT, AppRoles.JCPARTICIPANTSEARCH_EDITWITHSSN];

const VIEW_JCLOCATION_ROLES = [AppRoles.JCLOCATIONS_EDIT, AppRoles.JCLOCATIONS_VIEW, AppRoles.JCLOCATIONS_SUBMIT];
const VIEWEDIT_JCLOCATION_ROLES = [AppRoles.JCLOCATIONS_VIEW, AppRoles.JCLOCATIONS_EDIT];
const SUBMIT_JCLOCATION_ROLES = [AppRoles.JCLOCATIONS_SUBMIT];

const VIEW_JCSTAFF_ROLES = [AppRoles.JCSTAFF_EDIT, AppRoles.JCSTAFF_VIEW, AppRoles.JCSTAFF_SUBMIT];
const VIEWEDIT_JCSTAFF_ROLES = [AppRoles.JCSTAFF_VIEW, AppRoles.JCSTAFF_EDIT];
const SUBMIT_JCSTAFF_ROLES = [AppRoles.JCSTAFF_SUBMIT];

const VIEW_JCAMP_ROLES = [AppRoles.JCAMP_EDIT, AppRoles.JCAMP_VIEW, AppRoles.JCAMP_SUBMIT];
const VIEWEDIT_JCAMP_ROLES = [AppRoles.JCAMP_VIEW, AppRoles.JCAMP_EDIT];
const SUBMIT_JCAMP_ROLES = [AppRoles.JCAMP_SUBMIT];

const VIEW_JCEDITGOALS_ROLES = [AppRoles.JCEDITGOAL_VIEW, AppRoles.JCEDITGOAL_SUBMIT, AppRoles.JCEDITGOAL_EDIT];
const VIEWEDIT_JCEDITGOALS_ROLES = [AppRoles.JCEDITGOAL_VIEW, AppRoles.JCEDITGOAL_EDIT];
const SUBMIT_JCEDITGOALS_ROLES = [AppRoles.JCEDITGOAL_SUBMIT];

export const useAuthRouter = () => {
  const { isPermitted: isViewable_ProductionBudget, isValidRole } = useAuthControl({
    defaultAllowedRoles: VIEW_PRODUCTION_BUDGET_ROLES,
  });
  return {
    // #region group 1-2-3
    isViewable_ProductionBudget: isViewable_ProductionBudget,
    isViewEditable_ProductionBudget: isValidRole(VIEWEDIT_PRODUCTIONDUGET_ROLES),
    isSubmittable_ProductionBudget: isValidRole(SUBMIT_PRODUCTION_BUDGET_ROLES),

    isViewable_DonationBudget: isValidRole(VIEW_DONATION_BUDGET_ROLES),
    isViewEditable_DonationBudget: isValidRole(VIEWEDIT_DONATION_BUDGET_ROLES),
    isSubmittable_DonationBudget: isValidRole(SUBMIT_DONATION_BUDGET_ROLES),

    isViewable_HalloweenBudget: isValidRole(VIEW_HALLOWEEN_BUDGET_ROLES),
    isViewEditable_HalloweenBudget: isValidRole(VIEWEDIT_HALLOWEEN_BUDGET_ROLES),
    isSubmittable_HalloweenBudget: isValidRole(SUBMIT_HALLOWEEN_BUDGET_ROLES),

    isViewable_ChristmasBudget: isValidRole(VIEW_CHRISTMAS_BUDGET_ROLES),
    isViewEditable_ChristmasBudget: isValidRole(VIEWEDIT_CHRISTMAS_BUDGET_ROLES),
    isSubmittable_ChristmasBudget: isValidRole(SUBMIT_CHRISTMAS_BUDGET_ROLES),

    isViewable_LaborHourBudget: isValidRole(VIEW_LABORHOUR_BUDGET_ROLES),
    isViewEditable_LaborHourBudget: isValidRole(VIEWEDIT_LABORHOUR_BUDGET_ROLES),
    isSubmittable_LaborHourBudget: isValidRole(SUBMIT_LABORHOUR_BUDGET_ROLES),

    isViewable_GoodwillCare: isValidRole(VIEW_CARE_BUDGET_ROLES),
    isViewEditable_GoodwillCare: isValidRole(VIEWEDIT_CARE_BUDGET_ROLES),
    isSubmittable_GoodwillCare: isValidRole(SUBMIT_CARE_BUDGET_ROLES),

    isViewable_TargetReceipts: isValidRole(VIEW_TARGETRECEIPTS_ROLES),
    isViewable_TargetInvoices: isValidRole(VIEW_TARGET_INVOICES_ROLES),
    isViewable_TargetShipment: isValidRole(VIEW_TARGET_SHIPMENTS_ROLES),

    isViewable_StoreMessage: isValidRole(VIEW_STORE_MESSAGE_ROLES),

    isViewable_ECommerceContainer: isValidRole(VIEW_ECOMMERCE_CONTAINER_ROLES),
    isViewEditable_ECommerceContainer: isValidRole(VIEWEDIT_ECOMMERCE_CONTAINER_ROLES),
    isSubmittable_ECommerceContainer: isValidRole(SUBMIT_ECOMMERCE_CONTAINER_ROLES),

    isViewable_Site: isValidRole(VIEW_SITE_ROLES),
    isViewEditable_Site: isValidRole(VIEWEDIT_SITE_ROLES),
    isSubmittable_Site: isValidRole(SUBMIT_SITE_ROLES),

    isViewable_FeedbackLog: isValidRole(VIEW_FEEDBACK_LOGS_ROLES),
    isViewEditable_FeedbackLog: isValidRole(VIEWEDIT_FEEDBACK_LOGS_ROLES),
    isSubmitable_FeedbackLog: isValidRole(SUBMIT_FEEDBACK_LOGS_ROLES),

    isViewable_FixedAsset: isValidRole(VIEW_FIXED_ASSETS_ROLES),
    isViewEditable_FixedAsset: isValidRole(VIEWEDIT_FIXED_ASSETS_ROLES),
    isSubmittable_FixedAsset: isValidRole(SUBMIT_FIXED_ASSETS_ROLES),

    isViewable_AssetTypes: isValidRole(VIEW_ASSET_TYPES_ROLES),
    isViewEditable_AssetTypes: isValidRole(VIEWEDIT_ASSET_TYPES_ROLES),
    isSubmittable_AssetTypes: isValidRole(SUBMIT_ASSET_TYPES_ROLES),

    isViewable_BookContainer: isValidRole(VIEW_BOOK_CONTAINER_ROLES),
    isViewEditable_BookContainer: isValidRole(VIEWEDIT_BOOK_CONTAINER_ROLES),
    isSubmittable_BookContainer: isValidRole(SUBMIT_BOOK_CONTAINER_ROLES),

    isViewable_CostCenter: isValidRole(VIEW_COST_CENTER_ROLES),
    isViewEditable_CostCenter: isValidRole(VIEWEDIT_COST_CENTER_ROLES),
    isSubmitable_CostCenter: isValidRole(SUBMIT_COST_CENTER_ROLES),

    isViewable_MediaContainer: isValidRole(VIEW_MEDIA_CONTAINER_ROLES),
    isViewEditable_MediaContainer: isValidRole(VIEWEDIT_MEDIA_CONTAINER_ROLES),
    isSubmittable_MediaContainer: isValidRole(SUBMIT_MEDIA_CONTAINER_ROLES),

    isViewable_ECollectable: isValidRole(VIEW_ECOLLECTABLE_ROLES),
    isViewEditable_ECollectable: isValidRole(VIEWEDIT_ECOLLECTABLE_ROLES),
    isSubmitable_ECollectable: isValidRole(SUBMIT_ECOLLECTABLE_ROLES),

    isViewable_CarDonation: isValidRole(VIEW_CAR_DONATION_ROLES),
    isViewEditable_CarDonation: isValidRole(VIEWEDIT_CAR_DONATION_ROLES),
    isSubmitable_CarDonation: isValidRole(SUBMIT_CAR_DONATION_ROLES),

    isViewable_Shoplifter: isValidRole(VIEW_SHOPLIFTER_ROLES),
    isViewEditable_Shoplifter: isValidRole(VIEWEDIT_SHOPLIFTER_ROLES),
    isSubmitable_Shoplifter: isValidRole(SUBMIT_SHOPLIFTER_ROLES),

    isViewable_DonationInError: isValidRole(VIEW_DONATION_IN_ERROR_ROLES),
    isViewEditable_DonationInError: isValidRole(VIEWEDIT_DONATION_IN_ERROR_ROLES),
    isSubmittable_DonationInError: isValidRole(SUBMIT_DONATION_IN_ERROR_ROLES),

    isViewable_GeneralInventory: isValidRole(VIEW_GENERAL_INVENTORY_ROLES),
    isViewEditable_GeneralInventory: isValidRole(VIEWEDIT_GENERAL_INVENTORY_ROLES),
    isSubmittable_GeneralInventory: isValidRole(SUBMIT_GENERAL_INVENTORY_ROLES),

    isViewable_GeneralInventoryItemsList: isValidRole(VIEW_GENERAL_INVENTORY_ITEMS_LIST_ROLES),
    isViewEditable_GeneralInventoryItemsList: isValidRole(VIEWEDIT_GENERAL_INVENTORY_ITEMS_LIST_ROLES),
    isSubmittable_GeneralInventoryItemsList: isValidRole(SUBMIT_GENERAL_INVENTORY_ITEMS_LIST_ROLES),

    isViewable_ECETraining: isValidRole(VIEW_ECETRAINING_ROLES),
    isViewEditable_ECETraining: isValidRole(VIEWEDIT_ECETRAINING_ROLES),
    isSubmittable_ECETraining: isValidRole(SUBMIT_ECETRAINING_ROLES),

    isViewable_ECECommunityParticipant: isValidRole(VIEW_ECECOMMUNITY_PARTICIPANT_ROLES),
    isViewEditable_ECECommunityParticipant: isValidRole(VIEWEDIT_ECECOMMUNITY_PARTICIPANT_ROLES),
    isSubmittable_ECECommunityParticipant: isValidRole(SUBMIT_ECECOMMUNITY_PARTICIPANT_ROLES),

    isViewable_ECEEmployee: isValidRole(VIEW_ECEEMPLOYEE_ROLES),
    isViewEditable_ECEEmployee: isValidRole(VIEWEDIT_ECEEMPLOYEE_ROLES),
    isSubmittable_ECEEmployee: isValidRole(SUBMIT_ECEEMPLOYEE_ROLES),

    isViewable_ECEAncillaryServices: isValidRole(VIEW_ECEANCILLARY_SERVICES_ROLES),
    isViewEditable_ECEAncillaryServices: isValidRole(VIEWEDIT_ECEANCILLARY_SERVICES_ROLES),
    isSubmittable_ECEAncillaryServices: isValidRole(SUBMIT_ECEANCILLARY_SERVICES_ROLES),

    isViewable_ECEClasses: isValidRole(VIEW_ECECLASSES_ROLES),
    isViewEditable_ECEClasses: isValidRole(VIEWEDIT_ECECLASSES_ROLES),
    isSubmittable_ECEClasses: isValidRole(SUBMIT_ECECLASSES_ROLES),

    isViewable_ECELocations: isValidRole(VIEW_ECELOCATIONS_ROLES),
    isViewEditable_ECELocations: isValidRole(VIEWEDIT_ECELOCATIONS_ROLES),
    isSubmittable_ECELocations: isValidRole(SUBMIT_ECELOCATIONS_ROLES),

    isViewable_ECETeachers: isValidRole(VIEW_ECETEACHERS_ROLES),
    isViewEditable_ECETeachers: isValidRole(VIEWEDIT_ECETEACHERS_ROLES),
    isSubmittable_ECETeachers: isValidRole(SUBMIT_ECETEACHERS_ROLES),

    isViewable_ESLParticipants: isValidRole(VIEW_ESLPARTICIPANTS_ROLES),
    isViewEditable_ESLParticipants: isValidRole(VIEWEDIT_ESLPARTICIPANTS_ROLES),
    isSubmittable_ESLParticipants: isValidRole(SUBMIT_ESLPARTICIPANTS_ROLES),

    isViewable_ESLTraining: isValidRole(VIEW_ESLTRAINING_ROLES),
    isViewEditable_ESLTraining: isValidRole(VIEWEDIT_ESLTRAINING_ROLES),
    isSubmittable_ESLTraining: isValidRole(SUBMIT_ESLTRAINING_ROLES),

    isViewable_ESLCitizenship: isValidRole(VIEW_ESLCITIZENSHIP_ROLES),
    isViewEditable_ESLCitizenship: isValidRole(VIEWEDIT_ESLCITIZENSHIP_ROLES),
    isSubmittable_ESLCitizenship: isValidRole(SUBMIT_ESLCITIZENSHIP_ROLES),

    isViewable_ESLClasses: isValidRole(VIEW_ESLCLASSES_ROLES),

    isViewable_ESLInstructors: isValidRole(VIEW_ESLINSTRUCTORS_ROLES),

    isViewable_ESLLocations: isValidRole(VIEW_ESLLOCATIONS_ROLES),
    isViewEditable_ESLLocations: isValidRole(VIEWEDIT_ESLLOCATIONS_ROLES),
    isSubmittable_ESLLocations: isValidRole(SUBMIT_ESLLOCATIONS_ROLES),

    isViewable_ESLReferralSources: isValidRole(VIEW_ESLREFERRAL_SOURCES_ROLES),
    // #endregion

    isViewable_DonorEntry: isValidRole(VIEW_DONORENTRY_ROLES),
    isViewEditable_DonorEntry: isValidRole(VIEWEDIT_DONORENTRY_ROLES),
    isSubmittable_DonorEntry: isValidRole(SUBMIT_DONORENTRY_ROLES),

    isViewable_ApplicationEntry: isValidRole(VIEW_APPLICATIONENTRY_ROLES),
    isViewEditable_ApplicationEntry: isValidRole(VIEWEDIT_APPLICATIONENTRY_ROLES),
    isSubmittable_ApplicationEntry: isValidRole(SUBMIT_APPLICATIONENTRY_ROLES),

    isViewable_KaizenScoreEntry: isValidRole(VIEW_KAIZENSCORE_ROLES),
    isViewEditable_KaizenScoreEntry: isValidRole(VIEWEDIT_KAIZENSCORE_ROLES),
    isSubmittable_KaizenScoreEntry: isValidRole(SUBMIT_KAIZENSCORE_ROLES),

    isViewable_EquipmentInventory: isValidRole(VIEW_EQUIPMENTINVENTORY_ROLES),
    isViewEditable_EquipmentInventory: isValidRole(VIEWEDIT_EQUIPMENTINVENTORY_ROLES),
    isSubmittable_EquipmentInventory: isValidRole(SUBMIT_EQUIPMENTINVENTORY_ROLES),

    isViewable_EmployeeInjuryNotice: isValidRole(VIEW_EMPLOYEEINJURYNOTICE_ROLES),
    isViewEditable_EmployeeInjuryNotice: isValidRole(VIEWEDIT_EMPLOYEEINJURYNOTICE_ROLES),
    isSubmittable_EmployeeInjuryNotice: isValidRole(SUBMIT_EMPLOYEEINJURYNOTICE_ROLES),

    isViewable_KaizenSuggestion: isValidRole(VIEW_KAIZENSUGGESTION_ROLES),
    isViewEditable_KaizenSuggestion: isValidRole(VIEWEDIT_KAIZENSUGGESTION_ROLES),
    isSubmittable_KaizenSuggestion: isValidRole(SUBMIT_KAIZENSUGGESTION_ROLES),

    isViewable_DistrictManagerAudit: isValidRole(VIEW_DMAUDIT_ROLES),

    isViewable_CashCount: isValidRole(VIEW_CASHCOUNT_ROLES),
    isViewEditable_CashCount: isValidRole(VIEWEDIT_CASHCOUNT_ROLES),
    isSubmittable_CashCount: isValidRole(SUBMIT_CASHCOUNT_ROLES),

    isViewable_ReserveFund: isValidRole(VIEW_RESERVEFUND_ROLES),
    isViewEditable_ReserveFund: isValidRole(VIEWEDIT_RESERVEFUND_ROLES),
    isSubmittable_ReserveFund: isValidRole(SUBMIT_RESERVEFUND_ROLES),

    isViewable_DepositEntry: isValidRole(VIEW_DEPOSITENTRY_ROLES),
    isViewEditable_DepositEntry: isValidRole(VIEWEDIT_DEPOSITENTRY_ROLES),
    isSubmittable_DepositEntry: isValidRole(SUBMIT_DEPOSITENTRY_ROLES),

    isViewable_CashierVariance: isValidRole(VIEW_CASHIERVARIANCE_ROLES),
    isViewEditable_CashierVariance: isValidRole(VIEWEDIT_CASHIERVARIANCE_ROLES),
    isSubmittable_CashierVariance: isValidRole(SUBMIT_CASHIERVARIANCE_ROLES),

    isViewable_IncidentReport: isValidRole(VIEW_INCIDENTREPORT_ROLES),

    isViewable_HistoricalPreservation: isValidRole(VIEW_HISTORICALPRESERVATION_ROLES),
    isViewEditable_HistoricalPreservation: isValidRole(VIEWEDIT_HISTORICALPRESERVATION_ROLES),
    isSubmittable_HistoricalPreservation: isValidRole(SUBMIT_HISTORICALPRESERVATION_ROLES),

    isViewable_DailyActivityLog: isValidRole(VIEW_LOSSCONTROLDAILY_ACTIVITYLOG_ROLES),
    isViewEditable_DailyActivityLog: isValidRole(VIEWEDIT_LOSSCONTROLDAILY_ACTIVITYLOG_ROLES),
    isSubmittable_DailyActivityLog: isValidRole(SUBMIT_LOSSCONTROLDAILY_ACTIVITYLOG_ROLES),

    isSubmitable_EEOData: isValidRole(SUBMIT_EEODATA_ROLES),

    isViewable_LossControlReview: isValidRole(VIEW_LOSSCONTROLREVIEW_ROLES),
    isViewEditable_LossControlReview: isValidRole(VIEWEDIT_LOSSCONTROLREVIEW_ROLES),
    isSubmittable_LossControlReview: isValidRole(SUBMIT_LOSSCONTROLREVIEW_ROLES),

    isViewable_AccidentAnalysis: isValidRole(VIEW_ACCIDENT_ANALYSIS_ROLES),
    isViewEditable_AccidentAnalysis: isValidRole(VIEWEDIT_ACCIDENT_ANALYSIS_ROLES),
    isSubmittable_AccidentAnalysis: isValidRole(SUBMIT_ACCIDENT_ANALYSIS_ROLES),

    isViewable_SafetyAuditReport: isValidRole(VIEW_SAFETYAUDITREPORT_ROLES),
    isViewEditable_SafetyAuditReport: isValidRole(VIEWEDIT_SAFETYAUDITREPORT_ROLES),
    isSubmittable_SafetyAuditReport: isValidRole(SUBMIT_SAFETYAUDITREPORT_ROLES),

    isViewable_JCParticipant: isValidRole(VIEW_JCPARTICIPANT_ROLES),
    isViewEditable_JCParticipant: isValidRole(VIEWEDIT_JCPARTICIPANT_ROLES),
    isViewEditableWithSSN_JCParticipant: isValidRole(VIEWEDIT_SSN_JCPARTICIPANT_ROLES),
    isSubmittable_JCParticipant: isValidRole(SUBMIT_JCPARTICIPANT_ROLES),

    isViewable_JCLocation: isValidRole(VIEW_JCLOCATION_ROLES),
    isViewEditable_JCLocation: isValidRole(VIEWEDIT_JCLOCATION_ROLES),
    isSubmittable_JCLocation: isValidRole(SUBMIT_JCLOCATION_ROLES),

    isViewable_JCStaff: isValidRole(VIEW_JCSTAFF_ROLES),
    isViewEditable_JCStaff: isValidRole(VIEWEDIT_JCSTAFF_ROLES),
    isSubmittable_JCStaff: isValidRole(SUBMIT_JCSTAFF_ROLES),

    isViewable_JCAmp: isValidRole(VIEW_JCAMP_ROLES),
    isViewEditable_JCAmp: isValidRole(VIEWEDIT_JCAMP_ROLES),
    isSubmittable_JCAmp: isValidRole(SUBMIT_JCAMP_ROLES),

    isViewable_JCEditGoal: isValidRole(VIEW_JCEDITGOALS_ROLES),
    isViewEditable_JCEditGoal: isValidRole(VIEWEDIT_JCEDITGOALS_ROLES),
    isSubmittable_JCEditGoal: isValidRole(SUBMIT_JCEDITGOALS_ROLES),
  };
};

export default useAuthRouter;
