import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { Fragment } from 'react';
import FormInputSelector from './FormInputSelector';

const FormGenerator = (props: any) => {
  const { fieldGroups, formik } = props;

  if (!fieldGroups || fieldGroups.length === 0) {
    return null;
  }

  const renderValidate = (field: any) => {
    const { id, name } = field;
    return !!formik?.touched[name] && !!formik?.errors[name] ? (
      <FormHelperText id={id}>{formik.errors[name]}</FormHelperText>
    ) : (
      <div></div>
    );
  };

  return (
    <>
      {fieldGroups.map((group: any, index: number) => {
        const { fields } = group;
        return (
          <Fragment key={index}>
            {fields.map((field: any) => {
              const { id, name } = field;
              const error = !!formik?.touched[name] && !!formik?.errors[name];
              const trainError = error ? error : undefined;
              return (
                <FormControl key={id || name} variant="standard" margin="normal" error={error} fullWidth>
                  <FormInputSelector
                    field={field}
                    error={trainError}
                    formikFieldProps={{ ...formik.getFieldProps(name) }}
                    setFieldValue={formik.setFieldValue}
                  />
                  {renderValidate(field)}
                </FormControl>
              );
            })}
          </Fragment>
        );
      })}
    </>
  );
};

export default FormGenerator;
