import Analytics from '@mui/icons-material/Analytics';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import BusinessIcon from '@mui/icons-material/Business';
import ChatIcon from '@mui/icons-material/Chat';
import GppGoodIcon from '@mui/icons-material/GppGood';
import NoteIcon from '@mui/icons-material/Note';
import PaidIcon from '@mui/icons-material/Paid';
import People from '@mui/icons-material/People';
import ReportIcon from '@mui/icons-material/Report';
import SettingsIcon from '@mui/icons-material/Settings';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import FlagIcon from '@mui/icons-material/Flag';
import * as AppRoles from 'shared/constants/appRoles';

export const menuGroupIds = {
  businessOffice: 'businessOffice',
  humanResource: 'humanResource',
  operations: 'operations',
  marketing: 'marketing',
  vocationalServices: 'vocationalServices',
  eCommerceServices: 'eCommerceServices',
};

export const menuGroupItems = [
  {
    id: menuGroupIds.businessOffice,
    title: 'Business Office',
    type: 'group',
    icon: <BusinessIcon fontSize="small" />,
    items: [
      {
        path: '/cash-counts',
        icon: <Analytics />,
        title: 'Cash Count',
        allowedRoles: [AppRoles.CASHCOUNT_EDIT, AppRoles.CASHCOUNT_VIEW, AppRoles.CASHCOUNT_SUBMIT],
      },
      {
        path: '/cashier-variances',
        icon: <Analytics />,
        title: 'Cashier Variances',
        allowedRoles: [AppRoles.CASHIERVARIANCE_EDIT, AppRoles.CASHIERVARIANCE_VIEW, AppRoles.CASHIERVARIANCE_SUBMIT],
      },
      {
        path: '/reserve-fund-logs',
        icon: <PaidIcon />,
        title: 'Reserve Fund',
        allowedRoles: [AppRoles.RESERVEFUND_EDIT, AppRoles.RESERVEFUND_VIEW, AppRoles.RESERVEFUND_SUBMIT],
      },
      {
        path: '/deposits',
        icon: <Analytics />,
        title: 'Deposit',
        allowedRoles: [AppRoles.DEPOSITENTRY_EDIT, AppRoles.DEPOSITENTRY_VIEW, AppRoles.DEPOSITENTRY_SUBMIT],
      },
      {
        path: '/cost-center',
        icon: <Analytics />,
        title: 'Fixed Assets - Cost Centers',
        allowedRoles: [AppRoles.COST_CENTERS_VIEW],
      },
      {
        path: '/fixed-assets-search',
        icon: <Analytics />,
        title: 'Fixed Assets Search',
        allowedRoles: [AppRoles.FIXED_ASSETS_VIEW, AppRoles.FIXED_ASSETS_EDIT, AppRoles.FIXED_ASSETS_SUBMIT],
      },
      {
        path: '/fixed-assets-asset-type',
        icon: <Analytics />,
        title: 'Fixed Assets Asset Types',
        allowedRoles: [AppRoles.ASSET_TYPES_EDIT, AppRoles.ASSET_TYPES_SUBMIT, AppRoles.ASSET_TYPES_VIEW],
      },
    ],
  },
  {
    id: menuGroupIds.humanResource,
    title: 'Human Resources',
    type: 'group',
    icon: <People fontSize="small" />,
    items: [
      {
        path: '/employment-activity-log',
        icon: <People fontSize="small" />,
        title: 'Activity Log',
        allowedRoles: [AppRoles.ACTIVITY_LOG_VIEW, AppRoles.ACTIVITY_LOG_SUBMIT],
      },
      {
        path: '/employment-injury-notice',
        icon: <People fontSize="small" />,
        title: 'Employee Injury Notice',
      },
      {
        path: '/incident-report',
        icon: <ReportIcon />,
        title: 'Incident Report',
      },
      {
        path: '/application',
        icon: <People fontSize="small" />,
        title: 'Application Log',
      },
      {
        path: '/employment',
        icon: <People fontSize="small" />,
        title: 'EEO Data',
        allowedRoles: [AppRoles.EEODATA_EDIT, AppRoles.EEODATA_VIEW, AppRoles.EEODATA_SUBMIT],
      },
      {
        path: '/safety-audit-reports',
        icon: <People fontSize="small" />,
        title: 'Safety Audit',
        allowedRoles: [
          AppRoles.SAFETY_AUDITREPORT_EDIT,
          AppRoles.SAFETY_AUDITREPORT_VIEW,
          AppRoles.SAFETY_AUDITREPORT_SUBMIT,
        ],
      },
      {
        path: '/loss-control-reviews',
        icon: <GppGoodIcon />,
        title: 'Loss Control Review',
        allowedRoles: [
          AppRoles.LOSSCONTROLREVIEW_VIEW,
          AppRoles.LOSSCONTROLREVIEW_EDIT,
          AppRoles.LOSSCONTROLREVIEW_SUBMIT,
          AppRoles.LOSSCONTROLREVIEW_APPROVER,
        ],
      },
      {
        path: '/accident-analysis',
        icon: <GppGoodIcon />,
        title: 'Accident Analysis',
        allowedRoles: [
          AppRoles.ACCIDENTANALYSIS_EDIT,
          AppRoles.ACCIDENTANALYSIS_VIEW,
          AppRoles.ACCIDENTANALYSIS_SUBMIT,
        ],
      },
      {
        path: '/enter-daily-activity-log',
        icon: <GppGoodIcon />,
        title: 'Loss Control Daily Activity Log',
        allowedRoles: [
          AppRoles.LOSSCONTROLDAILY_ACTIVITYLOG_VIEW,
          AppRoles.LOSSCONTROLDAILY_ACTIVITYLOG_EDIT,
          AppRoles.LOSSCONTROLDAILY_ACTIVITYLOG_SUBMIT,
        ],
      },
      {
        path: '/shoplifter-summary',
        icon: <GppGoodIcon />,
        title: 'Shoplifter Summaries',
        allowedRoles: [AppRoles.SHOPLIFTER_VIEW, AppRoles.SHOPLIFTER_EDIT, AppRoles.SHOPLIFTER_SUBMIT],
      },
      {
        path: '/shoplifter',
        icon: <GppGoodIcon />,
        title: 'Shoplifter Entry',
        allowedRoles: [AppRoles.SHOPLIFTER_VIEW, AppRoles.SHOPLIFTER_EDIT, AppRoles.SHOPLIFTER_SUBMIT],
      },
    ],
  },
  {
    id: menuGroupIds.operations,
    title: 'Operations',
    type: 'group',
    icon: <SettingsIcon fontSize="small" />,
    items: [
      {
        path: '/communication-log',
        icon: <ChatIcon fontSize="small" />,
        title: 'Communication Entry',
        allowedRoles: [AppRoles.COMMUNICATION_ENTRY_SUBMIT, AppRoles.COMMUNICATION_ENTRY_VIEW],
      },
      {
        path: '/store-message',
        icon: <NoteIcon fontSize="small" />,
        title: 'Store Messages',
        allowedRoles: [AppRoles.STORE_MESSAGES_VIEW, AppRoles.STORE_MESSAGES_EDIT, AppRoles.STORE_MESSAGES_SUBMIT],
      },
      {
        path: '/donor',
        icon: <Analytics />,
        title: 'Donor Entry',
        allowedRoles: [AppRoles.DONORENTRY_VIEW, AppRoles.DONORENTRY_EDIT, AppRoles.DONORENTRY_SUBMIT],
      },
      {
        path: '/donation-budget-list',
        icon: <TrendingUpIcon />,
        title: 'Donation Budget',
        allowedRoles: [AppRoles.DONATION_BUDGET_VIEW, AppRoles.DONATION_BUDGET_EDIT, AppRoles.DONATION_BUDGET_SUBMIT],
      },
      {
        path: '/sites',
        icon: <TrendingUpIcon />,
        title: 'Sites',
        allowedRoles: [AppRoles.SITE_VIEW, AppRoles.SITE_EDIT, AppRoles.SITE_SUBMIT],
      },
      {
        path: '/kaizen-suggestion',
        icon: <TrendingUpIcon />,
        title: 'Kaizen Suggestion',
        allowedRoles: [
          AppRoles.KAIZEN_SUGGESTION_VIEW,
          AppRoles.KAIZEN_SUGGESTION_EDIT,
          AppRoles.KAIZEN_SUGGESTION_SUBMIT,
        ],
      },
      {
        path: '/dm-audit',
        icon: <GppGoodIcon />,
        title: 'District Manager Audit',
        allowedRoles: [AppRoles.DISTRICTMANAGER_VIEW, AppRoles.DISTRICTMANAGER_EDIT, AppRoles.DISTRICTMANAGER_SUBMIT],
      },
      {
        path: '/target-invoice',
        icon: <TrendingUpIcon />,
        title: 'Target Invoices',
        allowedRoles: [AppRoles.TARGET_INVOICES_VIEW, AppRoles.TARGET_INVOICES_SUBMIT, AppRoles.TARGET_INVOICES_SUBMIT],
      },
      {
        path: '/target-received',
        icon: <TrendingUpIcon />,
        title: 'Target Receipts',
        allowedRoles: [AppRoles.TARGET_RECEIPTS_VIEW, AppRoles.TARGET_RECEIPTS_SUBMIT, AppRoles.TARGET_RECEIPTS_EDIT],
      },
      {
        path: '/target-rotation',
        icon: <TrendingUpIcon />,
        title: 'Target Shipments',
        allowedRoles: [AppRoles.TARGET_SHIPMENT_VIEW, AppRoles.TARGET_SHIPMENT_EDIT, AppRoles.TARGET_SHIPMENT_SUBMIT],
      },
      {
        path: '/christmas-budgets-list',
        icon: <TrendingUpIcon />,
        title: 'Christmas Budget',
        allowedRoles: [
          AppRoles.CHRISTMAS_BUDGET_VIEW,
          AppRoles.CHRISTMAS_BUDGET_EDIT,
          AppRoles.CHRISTMAS_BUDGET_SUBMIT,
        ],
      },
      {
        path: '/production-budgets',
        icon: <TrendingUpIcon />,
        title: 'Production Budget',
        allowedRoles: [
          AppRoles.PRODUCTION_BUDGET_VIEW,
          AppRoles.PRODUCTION_BUDGET_EDIT,
          AppRoles.PRODUCTION_BUDGET_SUBMIT,
        ],
      },
      {
        path: '/halloween-budget-list',
        icon: <TrendingUpIcon />,
        title: 'Halloween Budget',
        allowedRoles: [
          AppRoles.HALLOWEEN_BUDGET_VIEW,
          AppRoles.HALLOWEEN_BUDGET_EDIT,
          AppRoles.HALLOWEEN_BUDGET_SUBMIT,
        ],
      },
      {
        path: '/labor-hour-budgets/list',
        icon: <TrendingUpIcon />,
        title: 'Labor Hour Budget',
        allowedRoles: [
          AppRoles.LABOR_HOUR_BUDGET_VIEW,
          AppRoles.LABOR_HOUR_BUDGET_EDIT,
          AppRoles.LABOR_HOUR_BUDGET_SUBMIT,
        ],
      },
      {
        path: '/general-inventory-items',
        icon: <TrendingUpIcon />,
        title: 'General Inventory Items',
        allowedRoles: [
          AppRoles.GENERAL_INVENTORY_ITEMS_LIST_VIEW,
          AppRoles.GENERAL_INVENTORY_ITEMS_LIST_EDIT,
          AppRoles.GENERAL_INVENTORY_ITEMS_LIST_SUBMIT,
        ],
      },
      {
        path: '/general-inventory-summaries',
        icon: <TrendingUpIcon />,
        title: 'General Inventory',
        allowedRoles: [AppRoles.GENERAL_INVENTORY_SUBMIT],
      },
      {
        path: '/equipment-inventory',
        icon: <TrendingUpIcon />,
        title: 'Equipment Inventory',
        allowedRoles: [
          AppRoles.EQUIPMENTINVENTORY_EDIT,
          AppRoles.EQUIPMENTINVENTORY_VIEW,
          AppRoles.EQUIPMENTINVENTORY_SUBMIT,
        ],
      },
      {
        path: '/equipment-inventory-list',
        icon: <TrendingUpIcon />,
        title: 'Equipment Inventory List',
        allowedRoles: [
          AppRoles.EQUIPMENTINVENTORY_VIEW,
          AppRoles.EQUIPMENTINVENTORY_EDIT,
          AppRoles.EQUIPMENTINVENTORY_SUBMIT,
        ],
      },
      {
        path: '/car-donations-donor-vehicle-search',
        icon: <GppGoodIcon />,
        title: 'Car Donations - Donor/Vehicle Search',
        allowedRoles: [AppRoles.CAR_DONATIONS_EDIT, AppRoles.CAR_DONATIONS_SUBMIT, AppRoles.CAR_DONATIONS_VIEW],
      },
      {
        path: '/donation-in-error',
        icon: <GppGoodIcon />,
        title: 'Donation In Error',
        allowedRoles: [
          AppRoles.DONATION_IN_ERROR_VIEW,
          AppRoles.DONATION_IN_ERROR_EDIT,
          AppRoles.DONATION_IN_ERROR_SUBMIT,
        ],
      },
      {
        path: '/kaizen-score/list',
        icon: <TrendingUpIcon />,
        title: 'Kaizen Score',
        allowedRoles: [
          AppRoles.KAIZEN_SCOREENTRY_EDIT,
          AppRoles.KAIZEN_SCOREENTRY_VIEW,
          AppRoles.KAIZEN_SCOREENTRY_SUBMIT,
          AppRoles.KAIZEN_SCOREENTRY_SUBMIT_WITH_EDIT,
        ],
      },
    ],
  },
  {
    id: menuGroupIds.marketing,
    title: 'Marketing',
    type: 'group',
    icon: <Analytics fontSize="small" />,
    items: [
      {
        path: '/care',
        icon: <GppGoodIcon />,
        title: 'Goodwill Cares',
        allowedRoles: [AppRoles.GOODWILL_CARES_VIEW, AppRoles.GOODWILL_CARES_EDIT, AppRoles.GOODWILL_CARES_SUBMIT],
      },
      {
        path: '/historical-preservation',
        icon: <TrendingUpIcon />,
        title: 'Historical Preservation',
        allowedRoles: [
          AppRoles.HISTORICALPRESERVATION_EDIT,
          AppRoles.HISTORICALPRESERVATION_VIEW,
          AppRoles.HISTORICALPRESERVATION_SUBMIT,
        ],
      },
    ],
  },
  {
    id: menuGroupIds.vocationalServices,
    title: 'Vocational Services',
    type: 'group',
    icon: <SupervisedUserCircleIcon fontSize="small" />,
    items: [
      {
        path: '/esl-referral-source',
        icon: <GppGoodIcon />,
        title: 'ESL Referral Sources',
        allowedRoles: [
          AppRoles.ESLREFERRAL_SOURCES_VIEW,
          AppRoles.ESLREFERRAL_SOURCES_EDIT,
          AppRoles.ESLREFERRAL_SOURCES_SUBMIT,
        ],
      },
      {
        path: '/esl-instructors',
        icon: <GppGoodIcon />,
        title: 'ESL Instructors',
        allowedRoles: [AppRoles.ESLINSTRUCTORS_EDIT, AppRoles.ESLINSTRUCTORS_EDIT, AppRoles.ESLINSTRUCTORS_SUBMIT],
      },
      {
        path: '/training-search',
        icon: <GppGoodIcon />,
        title: 'ECE Trainings',
        allowedRoles: [AppRoles.ECETRAINING_VIEW, AppRoles.ECETRAINING_EDIT, AppRoles.ECETRAINING_SUBMIT],
      },
      {
        path: '/ece-ancillary-services',
        icon: <GppGoodIcon />,
        title: 'ECE Ancillary Services',
        allowedRoles: [
          AppRoles.ECEANCILLARY_SERVICES_VIEW,
          AppRoles.ECEANCILLARY_SERVICES_EDIT,
          AppRoles.ECEANCILLARY_SERVICES_SUBMIT,
        ],
      },
      {
        path: '/ece-class',
        icon: <GppGoodIcon />,
        title: 'ECE Classes',
        allowedRoles: [AppRoles.ECECLASSES_VIEW, AppRoles.ECECLASSES_EDIT, AppRoles.ECECLASSES_SUBMIT],
      },
      {
        path: '/ece-location/list',
        icon: <TrendingUpIcon />,
        title: 'ECE Locations',
        allowedRoles: [AppRoles.ECELOCATIONS_VIEW, AppRoles.ECELOCATIONS_EDIT, AppRoles.ECELOCATIONS_SUBMIT],
      },
      {
        path: '/esl-class-type',
        icon: <GppGoodIcon />,
        title: 'ESL Classes',
        allowedRoles: [AppRoles.ESLCLASSES_EDIT, AppRoles.ESLCLASSES_VIEW, AppRoles.ESLCLASSES_SUBMIT],
      },
      {
        path: '/esl-participants',
        icon: <TrendingUpIcon />,
        title: 'ESL Participants',
        allowedRoles: [AppRoles.ESLPARTICIPANTS_VIEW, AppRoles.ESLPARTICIPANTS_EDIT, AppRoles.ESLPARTICIPANTS_SUBMIT],
      },
      {
        path: '/esl-location/list',
        icon: <TrendingUpIcon />,
        title: 'ESL Locations',
        allowedRoles: [AppRoles.ESLLOCATIONS_EDIT, AppRoles.ESLLOCATIONS_VIEW, AppRoles.ESLLOCATIONS_SUBMIT],
      },
      {
        path: '/esl-training',
        icon: <TrendingUpIcon />,
        title: 'ESL Training',
        allowedRoles: [AppRoles.ESLTRAINING_VIEW, AppRoles.ESLTRAINING_EDIT, AppRoles.ESLTRAINING_SUBMIT],
      },
      {
        path: '/esl-citizenship',
        icon: <TrendingUpIcon />,
        title: 'ESL Citizenship',
        allowedRoles: [AppRoles.ESLCITIZENSHIP_EDIT, AppRoles.ESLCITIZENSHIP_VIEW, AppRoles.ESLCITIZENSHIP_SUBMIT],
      },
      {
        path: '/ece-teachers',
        icon: <TrendingUpIcon />,
        title: 'ECE Teachers',
        allowedRoles: [AppRoles.ECETEACHERS_VIEW, AppRoles.ECETEACHERS_EDIT, AppRoles.ECETEACHERS_SUBMIT],
      },
      {
        path: '/ece-community-participants',
        icon: <TrendingUpIcon />,
        title: 'ECE Community Participants',
        allowedRoles: [
          AppRoles.ECECOMMUNITY_PARTICIPANT_VIEW,
          AppRoles.ECECOMMUNITY_PARTICIPANT_EDIT,
          AppRoles.ECECOMMUNITY_PARTICIPANT_SUBMIT,
        ],
      },
      {
        path: '/ece-employees',
        icon: <TrendingUpIcon />,
        title: 'ECE Employees',
        allowedRoles: [AppRoles.ECEEMPLOYEE_VIEW, AppRoles.ECEEMPLOYEE_EDIT, AppRoles.ECEEMPLOYEE_SUBMIT],
      },
      {
        path: '/job-connection-staff',
        icon: <TrendingUpIcon />,
        title: 'Job Connection Staff',
        allowedRoles: [AppRoles.JCSTAFF_EDIT, AppRoles.JCSTAFF_VIEW, AppRoles.JCSTAFF_SUBMIT],
      },
      {
        path: '/job-connection-participants',
        icon: <TrendingUpIcon />,
        title: 'Job Connection Participants',
        allowedRoles: [
          AppRoles.JCPARTICIPANTSEARCH_EDIT,
          AppRoles.JCPARTICIPANTSEARCH_VIEW,
          AppRoles.JCPARTICIPANTSEARCH_SUBMIT,
        ],
      },
      {
        path: '/job-connection-amp',
        icon: <TrendingUpIcon />,
        title: 'Job Connection AMP',
        allowedRoles: [AppRoles.JCAMP_EDIT, AppRoles.JCAMP_VIEW, AppRoles.JCAMP_SUBMIT],
      },
      {
        path: '/job-connection-location',
        icon: <TrendingUpIcon />,
        title: 'Job Connection Locations',
        allowedRoles: [AppRoles.JCLOCATIONS_EDIT, AppRoles.JCLOCATIONS_VIEW, AppRoles.JCLOCATIONS_SUBMIT],
      },
      {
        path: '/staff-goal',
        icon: <FlagIcon />,
        title: 'Edit Goals',
        allowedRoles: [AppRoles.JCEDITGOAL_VIEW, AppRoles.JCEDITGOAL_EDIT, AppRoles.JCEDITGOAL_SUBMIT],
      },
    ],
  },
  {
    id: menuGroupIds.eCommerceServices,
    title: 'E-Commerce',
    type: 'group',
    icon: <BusinessCenterIcon fontSize="small" />,
    items: [
      {
        path: '/feedback-log',
        icon: <GppGoodIcon />,
        title: 'Feedback Log',
        allowedRoles: [AppRoles.FEEDBACK_LOG_SUBMIT],
      },
      {
        path: '/e-commerce-media-container-audit',
        icon: <GppGoodIcon />,
        title: 'Media Container Audit',
        allowedRoles: [
          AppRoles.MEDIA_CONTAINER_AUDIT_VIEW,
          AppRoles.MEDIA_CONTAINER_AUDIT_EDIT,
          AppRoles.MEDIA_CONTAINER_AUDIT_SUBMIT,
        ],
      },
      {
        path: '/e-commerce-book-container-audit',
        icon: <GppGoodIcon />,
        title: 'Book Container Audit ',
        allowedRoles: [
          AppRoles.BOOK_CONTAINER_AUDIT_VIEW,
          AppRoles.BOOK_CONTAINER_AUDIT_EDIT,
          AppRoles.BOOK_CONTAINER_AUDIT_SUBMIT,
        ],
      },
      {
        path: '/e-collectable-listings-entry',
        icon: <TrendingUpIcon />,
        title: 'ECollectible Listings',
        allowedRoles: [
          AppRoles.ECOLLECTABLE_LISTINGS_VIEW,
          AppRoles.ECOLLECTABLE_LISTINGS_EDIT,
          AppRoles.ECOLLECTABLE_LISTINGS_SUBMIT,
        ],
      },
      {
        path: '/e-commerce-container',
        icon: <GppGoodIcon />,
        title: 'E-Commerce Container Search',
        allowedRoles: [
          AppRoles.ECOMMERCE_CONTAINER_VIEW,
          AppRoles.ECOMMERCE_CONTAINER_EDIT,
          AppRoles.ECOMMERCE_CONTAINER_SUBMIT,
        ],
      },
    ],
  },
];

export interface MenuItem {
  path: string;
  icon: JSX.Element;
  title: string;
  allowedRoles?: string[];
}
