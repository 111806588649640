import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import store from 'app/store';
import { Provider } from 'react-redux';
import { MsalProvider } from '@azure/msal-react';
import authIntance from 'auth/authInstance';

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={authIntance}>
      <Provider store={store}>
        <App />
      </Provider>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
