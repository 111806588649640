import { PaletteOptions } from '@mui/material';

declare module '@mui/material/styles' {
  interface TypeBackground {
    sidebar?: string;
  }
  // allow configuration using `createTheme`
  interface TypeAction {
    menuItemHover?: string;
    menuItemSelected?: string;
  }
}

export default function themePalette(): PaletteOptions {
  return {
    background: {
      sidebar: '#1b417f',
    },
    action: {
      menuItemHover: 'rgba(39, 42, 47, 0.2)',
      menuItemSelected: 'rgba(0, 0, 0, 0.21)',
    },
  };
}
