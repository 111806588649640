import { AppBar, Avatar, Box, IconButton, Stack, Toolbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useGlobalContext } from 'shared/hooks/useGlobalContext';
import React, { useEffect } from 'react';

export const AppHeader = (props: any) => {
  const { onSidebarOpen, ...other } = props;
  const { userName, userDisplayName, rest } = useGlobalContext();

  const [profileDisplayName, setProfileDisplayName] = React.useState<string>('loading ...');
  const [profileLetterAva, setProfileLetterAva] = React.useState<string>('');

  useEffect(() => {
    if (rest && rest.givenName) {
      const firstLetter = rest.givenName.substring(0, 1);
      let secondLetter = '';
      if (rest.surname) {
        secondLetter = rest.surname.substring(0, 1);
      } else {
        secondLetter = rest.givenName.substring(1, 1);
      }
      setProfileLetterAva(firstLetter.concat(secondLetter));
    } else {
      const val = userName || userDisplayName || 'NA';
      setProfileLetterAva(val.substring(0, 2));
    }

    if (userName) {
      setProfileDisplayName(userName);
    } else if (userDisplayName) {
      setProfileDisplayName(userDisplayName);
    } else {
      setProfileDisplayName('N/A');
    }
  }, [userName, userDisplayName]);

  return (
    <Box
      component={AppBar}
      sx={{
        left: {
          lg: '256px',
        },
        width: {
          lg: 'calc(100% - 256px)',
        },
        backgroundColor: 'background.paper',
        boxShadow: 1,
      }}
      {...other}
    >
      <Toolbar
        variant="dense"
        disableGutters
        sx={{
          left: 0,
          px: 2,
        }}
      >
        <IconButton
          onClick={onSidebarOpen}
          sx={{
            display: {
              xs: 'inline-flex',
              lg: 'none',
            },
          }}
        >
          <MenuIcon fontSize="small" />
        </IconButton>
        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row" spacing={2}>
          {/* <Tooltip title="Search">
            <IconButton disableRipple sx={{ ml: 1 }}>
              <SearchIcon fontSize="small" />
            </IconButton>
          </Tooltip> */}
          <Typography sx={{ margin: 'auto' }} gutterBottom component="div" color="primary">
            {profileDisplayName}
          </Typography>
          <IconButton disableRipple>
            <Avatar sx={{ bgcolor: '#5BC0B5', fontSize: '20px' }}>{profileLetterAva}</Avatar>
          </IconButton>
        </Stack>
      </Toolbar>
    </Box>
  );
};

export default AppHeader;
