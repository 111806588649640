import { createTheme, Theme, ThemeOptions } from '@mui/material/styles';
import { toolbarHeight } from 'shared/constants/appSettings';
import themePalette from './palette';
import themeTypography from './typography';

export const theme = (): Theme => {
  const themeOptions: ThemeOptions = {
    direction: 'ltr',
    palette: themePalette(),
    typography: themeTypography(),
    components: {
      MuiToolbar: {
        styleOverrides: {
          dense: {
            height: toolbarHeight,
            minHeight: toolbarHeight,
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          shrink: {
            fontSize: '14px',
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontSize: '18px',
            fontWeight: 400,
            letterSpacing: '0.25px',
            lineHeight: '21.09px',
          },
        },
      },
      MuiFab: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
          },
        },
      },
    },
  };

  const themes = createTheme(themeOptions);

  return themes;
};

export default theme;
